import axios from "axios";
import { BACKEND } from "../variables";
const GET_DASHES_URL = BACKEND + "/dashes";
const others = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ["GET_DASHES"]: (state, payload) => {
      return axios({
        url: GET_DASHES_URL + `?page=${payload.data.page}`,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
  },
  modules: {},
};
export default others;
