<template>
  <div class="dashboard">
    <div class="dash-content">
      <Topbar pageTitle="Volunteers" />
      <div class="my-table">
        <table v-if="allData.length > 0">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ $txt("Date Sent")}}</th>
              <th>{{ $txt("Volunteer")}}</th>
              <th>{{ $txt("Activity")}}</th>
              <th>{{ $txt("Activity Date")}}</th>
              <th>{{ $txt("Type")}}</th>
              <th>{{ $txt("Status")}}</th>
              <th>{{ $txt("Action")}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in allData" :key="item.id">
              <td>
                {{ index + 1 }}
              </td>
              <td>
                {{ $date(item.created_at) }}
              </td>
              <td>
                <p class="ellipsis">{{ item.volunteer.name }}</p>
              </td>
              <td>
                <p class="ellipsis">{{ item.activity.description }}</p>
              </td>
              <td>
                <div class="table-list">
                  <div class="table-list-item">
                    <b>{{ $txt("Date:")}} </b>
                    <span>{{ $getlang()=="french" ? $frenchFormat(item.activity.execution_date) : item.activity.execution_date }}</span>
                  </div>
                  <div class="table-list-item">
                    <b>{{ $txt("Time:")}} </b>
                    <span>{{ item.activity.execution_time }}</span>
                  </div>
                </div>
              </td>
              <td>
                {{ $txt(item.activity_type) }}
              </td>

              <td>
                <span :class="`chip ${item.status}`">{{
                  item.status == "inProgress" ? "In Progress" : $txt(item.status)
                }}</span>
              </td>

              <td>
                <a-dropdown>
                  <template #overlay>
                    <a-menu @click="handleMenuClick">
                      <a-menu-item
                        key="1"
                        @click="
                          selectedItem = item;
                          startApprove = true;
                        "
                      >
                        {{ $txt("Approve")}}
                      </a-menu-item>
                      <a-menu-item
                        key="2"
                        @click="
                          selectedItem = item;
                          startReject = true;
                        "
                      >
                        {{ $txt("Reject")}}
                      </a-menu-item>
                      <a-menu-item
                        key="3"
                        @click="
                          selectedItem = item;
                          startDelete = true;
                        "
                      >
                        {{ $txt("Delete")}}
                      </a-menu-item>
                    </a-menu>
                  </template>
                  <a-button>
                    {{ $txt(" Actions")}}
                    <a-icon type="down" />
                  </a-button>
                </a-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button
            :class="{ disabled: currentPage === 1 }"
            @click="$previousPage()"
          >
            <label>{{ $txt("Previous")}}</label>
          </button>
          <p v-if="currentPage > 3">...</p>
          <button class="numbers" v-for="number in pagesNumber" :key="number">
            <label
              :class="{ active: currentPage === number }"
              for=""
              v-if="
                (number == 1 && currentPage <= 2) ||
                (currentPage + 2 >= pagesNumber && number >= pagesNumber - 2) ||
                (number >= currentPage - 2 && number <= currentPage + 2)
              "
              @click="$changePage(number)"
            >
              {{ number }}
            </label>
          </button>
          <p v-if="currentPage <= pagesNumber - 3">...</p>
          <button
            :class="{ disabled: currentPage === pagesNumber.length }"
            @click="$nextPage()"
          >
            <label>{{ $txt("Next")}}</label>
          </button>
        </div>
      </div>

      <Modal
          :title="$txt('Approve Request')"
        :size="'sm'"
        v-model:open="startApprove"
        centered
        :okText="$txt('Yes, Approve')"
          :cancelText="$txt('Cancel')"
        @ok="approveItem()"
        width="600px"
      >
        <p>{{$txt("Are you sure you want to Approve this request?")}}</p>
      </Modal>
      <Modal
          :title="$txt('Reject Request')"
        :size="'sm'"
        v-model:open="startReject"
        centered
        :okText="$txt('Yes, reject')"
          :cancelText="$txt('Cancel')"
        @ok="rejectItem()"
        width="600px"
      >
        <p>{{$txt("Are you sure you want to reject this request?")}}</p>
      </Modal>
      <Modal
          :title="$txt('Delete Request')"
        :size="'sm'"
        v-model:open="startDelete"
        centered
        :okText="$txt('Yes, delete')"
          :cancelText="$txt('Cancel')"
        @ok="deleteItem()"
        width="600px"
      >
        <p>{{ $txt("Are you sure you want to delete this request?")}}</p>
      </Modal>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Topbar from "@/views/admin/components/Topbar.vue";
import { Modal } from "ant-design-vue";
export default {
  components: {
    Topbar,
    Modal,
  },
  data() {
    return {
      allItemsCount: 1,
      itemsPerpage: 10,
      pagesNumber: 1,
      currentPage: 1,
      allData: [],
      searchQuery: {
        search: "",
      },
      startAdd: false,
      startEdit: false,
      startDelete: false,
      startReject: false,
      startApprove: false,
      selectedItem: {
        name: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        postal_code: "",
        status: "",
        skills: [],
        availability: [],
      },
      volunteers: [],
      merchants: [],
      viewItems: false,
      schedules: [],
      activeSchedule: "Today",
      scheduleDate: "",
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_REQUESTS_ACTIVITY", {
          token: this.$getAdminToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
            searchQuery: this.searchQuery,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.allData = res.data.data;
              this.allItemsCount = res.data.total;
              this.pagesNumber = Math.ceil(
                this.allItemsCount / this.itemsPerpage
              );
              this.$stopLoader();
              this.allData.forEach((item) => {
                if (!item.skills) {
                  item.skills = [];
                }
                if (!item.availability) {
                  item.availability = [];
                }
              });
            } else {
              toast.error(this.$txt(res.data.message));
              this.$stopLoader();
            }
          }
        })

        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_REQUEST", {
          data: {
            ...this.selectedItem,
          },
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(this.$txt(res.data.message));
              this.getItems();
              this.startDelete = false;
              this.$stopLoader();
            } else {
              toast.error(this.$txt(res.data.message));
              this.$stopLoader();
            }
          }
        })

        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    approveItem() {
      this.$startLoader();
      this.$store
        .dispatch("APPROVE_REQUEST", {
          data: {
            volunteer_id: this.selectedItem.volunteer.id,
            id: this.selectedItem.id,
            activity_id: this.selectedItem.activity_id,
            activity_type: this.selectedItem.activity_type,
            execution_date: this.selectedItem.date,
            execution_time: this.selectedItem.time,
          },
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(this.$txt(res.data.message));
              this.getItems();
              this.startApprove = false;
              this.$stopLoader();
            } else {
              toast.error(this.$txt(res.data.message));
              this.$stopLoader();
            }
          }
        })

        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    rejectItem() {
      this.$startLoader();
      this.$store
        .dispatch("REJECT_REQUEST", {
          data: {
            ...this.selectedItem,
          },
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(this.$txt(res.data.message));
              this.getItems();
              this.startReject = false;
              this.$stopLoader();
            } else {
              toast.error(this.$txt(res.data.message));
              this.$stopLoader();
            }
          }
        })

        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  background: #fafafa;
  overflow-y: hidden;
  .page-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .dash-content {
    // padding: 0 20px;
    height: fit-content;
    // overflow-y: auto;
    width: 100%;
  }
}
</style>