<template>
  <div class="dashboard">
    <div class="dash-content">
      <Topbar :pageTitle="$txt('Reports')" />
      <div class="my-table">
        <div class="table-header flex-btn">
          <form class="table-filters">
            <fieldset>
              <legend>{{$txt("Type")}}</legend>
              <div class="form-group">
                <select
                  id="type"
                  class="form-control"
                  v-model="searchQuery.type"
                >
                  <option value="all">{{$txt("All")}}</option>
                  <option value="in">{{$txt("Stock In")}}</option>
                  <option value="out">{{$txt("Stock Out")}}</option>
                </select>
              </div>
            </fieldset>
            <fieldset>
              <legend>{{$txt("Admin")}}</legend>
              <div class="form-group">
                <select
                  id="created_by"
                  class="form-control"
                  v-model="searchQuery.created_by"
                >
                  <option value="all">{{$txt("All")}}</option>
                  <option
                    v-for="admin in allAdmins"
                    :key="admin.id"
                    :value="admin.id"
                  >
                    {{ admin.name }}
                  </option>
                </select>
              </div>
            </fieldset>
            <fieldset>
              <legend>{{$txt("Date")}}</legend>
              <div class="form-group">
                <input
                  type="date"
                  id="created_at"
                  class="form-control"
                  v-model="searchQuery.created_at"
                />
              </div>
            </fieldset>
            <fieldset>
              <legend>{{$txt("Search")}}</legend>
              <div class="form-group">
                <input
                  type="text"
                  id="name"
                  class="form-control"
                  :placeholder="$txt('Search')"
                  v-model="searchQuery.search"
                />
              </div>
            </fieldset>
            <!-- Filter -->
            <div class="form-group">
              <button
                class="my-btn cw wd glow"
                type="submit"
                @click.prevent="getItems()"
              >
                {{$txt("Filter")}}
              </button>
            </div>
          </form>
        </div>
        <table v-if="allData.length > 0">
          <thead>
            <tr>
              <th>#</th>
              <th>{{$txt("Date")}}</th>
              <th>{{$txt("Type")}}</th>
              <th>{{$txt("Warehouse")}}</th>
              <th>{{$txt("Admin")}}</th>
              <th>{{$txt("Description")}}</th>
              <th>{{$txt("Action")}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in allData" :key="item.id">
              <td>
                {{ index + 1 }}
              </td>
              <td>{{ $date(item.created_at) }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.warehouse ? item.warehouse.name : "" }}</td>
              <td>{{ item.creator.name }}</td>
              <td>{{ item.description }}</td>
              <td>
                <a-dropdown>
                  <template #overlay>
                    <a-menu @click="handleMenuClick">
                      <a-menu-item
                        key="2"
                        @click="
                          selectedItem = item;
                          showReport = true;
                        "
                      >
                        {{$txt("View Report")}}
                      </a-menu-item>
                    </a-menu>
                  </template>
                  <a-button> {{$txt("Actions")}} </a-button>
                </a-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button
            :class="{ disabled: currentPage === 1 }"
            @click="$previousPage()"
          >
            <label>{{$txt("Previous")}}</label>
          </button>
          <p v-if="currentPage > 3">...</p>
          <button class="numbers" v-for="number in pagesNumber" :key="number">
            <label
              :class="{ active: currentPage === number }"
              for=""
              v-if="
                (number == 1 && currentPage <= 2) ||
                (currentPage + 2 >= pagesNumber && number >= pagesNumber - 2) ||
                (number >= currentPage - 2 && number <= currentPage + 2)
              "
              @click="$changePage(number)"
            >
              {{ number }}
            </label>
          </button>
          <p v-if="currentPage <= pagesNumber - 3">...</p>
          <button
            :class="{ disabled: currentPage === pagesNumber.length }"
            @click="$nextPage()"
          >
            <label>{{$txt("Next")}}</label>
          </button>
        </div>
      </div>

      <!-- report modal -->
      <Modal
          :title="$txt('Stock ') + $txt(selectedItem.type) + ' '  + $txt('Report')"
        :size="'lg'"
        v-model:open="showReport"
        centered
        :okText="$txt('Close')"
        :cancelText="$txt('Cancel')"
        @ok="showReport = false"
        width="800px"
      >
        <div id="report-section" style="width: 100%; overflow-x: auto">
          <p
            v-if="selectedItem && selectedItem.items.length > 0"
            style="font-size: 14px; text-align: center; width: 100%"
          >
            {{ $txt(`Stock ${selectedItem.type} Report by `) }}
            {{ selectedItem.creator.name }}
            <i>({{ $date(selectedItem.created_at) }})</i>
          </p>
          <table
            class="stockin-list"
            border="1"
            style="border-collapse: collapse; width: 100%"
          >
            <thead>
              <tr>
                <th style="padding: 5px 10px">{{$txt("Item Name")}}</th>
                <th style="padding: 5px 10px">{{$txt("Quantity")}}</th>
                <th style="padding: 5px 10px">{{$txt("New Quantity")}}</th>
                <th style="padding: 5px 10px">{{$txt("Unit")}}</th>
                <th style="padding: 5px 10px">{{$txt("Category")}}</th>
                <th style="padding: 5px 10px">{{$txt("Bar Code")}}</th>
                <th style="padding: 5px 10px">{{$txt("Warehouse")}}</th>
                <th style="padding: 5px 10px">{{$txt("Description")}}</th>
              </tr>
            </thead>
            <tr
              class="stockin-list-item"
              v-for="item in selectedItem.items"
              :key="item.id"
            >
              <td style="padding: 5px 10px">
                {{ $stockItem(item.stock_id).name }}
              </td>
              <td style="padding: 5px 10px">{{ item.quantity }}</td>
              <td style="padding: 5px 10px">
                {{ $stockItem(item.stock_id).quantity }}
              </td>
              <td style="padding: 5px 10px">
                {{ $stockItem(item.stock_id).unit }}
              </td>
              <td style="padding: 5px 10px">
                {{ $stockItem(item.stock_id).category }}
              </td>
              <td style="padding: 5px 10px">
                {{ $stockItem(item.stock_id).barcode }}
              </td>
              <td style="padding: 5px 10px">
                {{ selectedItem.warehouse ? selectedItem.warehouse.name : "" }}
              </td>
              <td style="padding: 5px 10px">
                {{ item.description }}
              </td>
            </tr>
          </table>
        </div>
        <button class="print-report" @click="printReport()">
          {{$txt("Print Report")}}
        </button>
      </Modal>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Topbar from "@/views/admin/components/Topbar.vue";
import { Modal } from "ant-design-vue";
export default {
  components: {
    Topbar,
    Modal,
  },
  data() {
    return {
      allItemsCount: 1,
      itemsPerpage: 10,
      pagesNumber: 1,
      currentPage: 1,
      allData: [],
      allAdmins: [],
      searchQuery: {
        search: "",
        type: "all",
        created_by: "all",
        created_at: null,
      },
      selectedItem: {},
      showReport: false,
      reportItems: [],
      allStock: [],
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ALL_STOCKS_REPORTS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
            warehouse_id: this.warehouse_id,
            searchQuery: this.searchQuery,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.allData = res.data.data;
              this.allItemsCount = res.data.total;
              this.pagesNumber = Math.ceil(
                this.allItemsCount / this.itemsPerpage
              );
              this.allData.forEach((item) => {
                item.items = JSON.parse(item.items);
              });
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    $stockItem(stock_id) {
      return this.allStock.find((item) => item.id === stock_id);
    },
    printReport() {
      const printContents = document.getElementById("report-section").innerHTML;
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },

    getStocks() {
      this.$startLoader();
      this.$store
        .dispatch("GET_STOCKS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 1000,
            page: this.currentPage,
            warehouse_id: this.warehouse_id,
            searchQuery: this.searchQuery,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.allStock = res.data.data;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    getAdmins() {
      this.$store
        .dispatch("GET_ADMINS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 1000,
            page: this.currentPage,
            warehouse_id: this.warehouse_id,
            searchQuery: this.searchQuery,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.allAdmins = res.data.data;
            } else {
              toast.error(res.data.message);
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
        });
    },
  },
  mounted() {
    this.getStocks();
    this.getAdmins();
    this.getItems();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  background: #fafafa;
  overflow-y: hidden;
  .page-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .dash-content {
    // padding: 0 20px;
    height: fit-content;
    // overflow-y: auto;
    width: 100%;
  }
}
.stockBtns {
  .in {
    background: rgba(0, 132, 255, 0.589);
  }
  .out {
    background: rgba(255, 136, 0, 0.589);
    color: black;
  }
}
.stock-in-content {
  input {
    border: 1px solid #e2e2e2;
    padding: 5px 5px;
    border-radius: 5px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    thead {
      background: #f5f5f5;
      th {
        padding: 10px;
        text-align: left;
      }
    }
    tr {
      td {
        padding: 10px;
        border-bottom: 1px solid #e2e2e2;
        input {
          width: 90%;
        }
        button {
          padding: 5px 10px !important;
          border-radius: 5px;
          background: #a30000;
          color: white;
          border: none;
          cursor: pointer;
          margin: 0 10px;
        }
      }
    }
  }
}
#report-section {
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    thead {
      background: #f5f5f5;
      th {
        padding: 10px;
        text-align: left;
      }
    }
    tr {
      td {
        padding: 10px;
        border-bottom: 1px solid #e2e2e2;
      }
    }
  }
}
.print-report {
  padding: 10px 20px;
  border-radius: 5px;
  background: #1d2088;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}
</style>