<template>
  <div class="dashboard">
    <div class="dash-content">
      <Topbar :pageTitle="$txt('Schedule')" />
      <div class="schedules-section">
        <Schedule
          :schedules="schedules"
          @TodayClicked="
            activeSchedule = 'Today';
            getSchedules();
          "
          @TomorrowClicked="
            activeSchedule = 'Tomorrow';
            getSchedules();
          "
          @ThisWeekClicked="
            activeSchedule = 'This Week';
            getSchedules();
          "
          @ThisMonthClicked="
            activeSchedule = 'This Month';
            getSchedules();
          "
          @customDateClicked="
            scheduleDate = $event;
            activeSchedule = 'Custom Date';
            getSchedules();
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Topbar from "@/views/admin/components/Topbar.vue";
export default {
  components: {
    Topbar,
  },
  data() {
    return {
      allData: [],
      searchQuery: {
        search: "",
      },
      volunteers: [],
      merchants: [],
      viewItems: false,
      schedules: [],
      activeSchedule: "Today",
      scheduleDate: "",
    };
  },
  methods: {
    getSchedules() {
      this.$startLoader();
      let action = "";
      switch (this.activeSchedule) {
        case "Today":
          action = "GET_TODAY_SCHEDULE";
          break;
        case "Tomorrow":
          action = "GET_TOMORROW_SCHEDULE";
          break;
        case "This Week":
          action = "GET_THIS_WEEK_SCHEDULE";
          break;
        case "This Month":
          action = "GET_THIS_MONTH_SCHEDULE";
          break;
        default:
          action = "GET_SCHEDULE_BY_DATE";
          break;
      }
      this.$store
        .dispatch("GET_ALL_SCHEDULE", {
          token: this.$getAdminToken(),
          data: {
            date: this.scheduleDate,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.schedules = res.data;
              this.startSchedule = true;
            } else {
              toast.error(res.data.message);
            }
          }
          this.$stopLoader();
        })

        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getSchedules();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  background: #fafafa;
  overflow-y: hidden;
  .page-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .dash-content {
    // padding: 0 20px;
    height: fit-content;
    // overflow-y: auto;
    width: 100%;
  }
  .schedules-section {
    width: 100%;
    padding: 10px 1rem;
  }
}
</style>