<template>
  <div class="Warehouses">
    <div class="dash-content">
      <Topbar pageTitle="Stock" />
      <div class="warehouses">
        <p>{{$txt("Select warehouse")}}</p>
        <div class="dflex row-gap-1 column-gap-1">
          <router-link
            :to="{
              name: 'AdminStockItems',
              params: {
                name: $urlCompatible(warehouse.name),
                id: warehouse.id,
              },
            }"
            class="warehouse-item"
            v-for="warehouse in warehouses"
            :key="warehouse.id"
          >
            <i class="fa-solid fa-warehouse"></i>
            <p class="title">{{ warehouse.name }}</p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Topbar from "@/views/admin/components/Topbar.vue";
export default {
  components: {
    Topbar,
  },
  data() {
    return {
      warehouses: [],
      selectedWarehouse: null,
    };
  },
  methods: {
    getWarehouses() {
      this.$startLoader();
      this.$store
        .dispatch("GET_WAREHOUSES", {
          token: this.$getAdminToken(),
          data: {
            paginate: 1000,
            page: 1,
            searchQuery: this.searchQuery,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.warehouses = res.data.data;
              this.selectedWarehouse = this.warehouses.id;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getWarehouses();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.Warehouses {
  background: #fafafa;
  overflow-y: hidden;
  .page-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .dash-content {
    // padding: 0 20px;
    height: fit-content;
    // overflow-y: auto;
    width: 100%;
    .warehouses {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      min-height: 70vh;
      width: 100%;
      gap: 20px;
      .warehouse-item {
        width: 200px;
        height: 150px;
        background: white;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.048);
        cursor: pointer;
        i {
          font-size: 2rem;
          color: #333;
        }
        .title {
          font-size: 1rem;
          font-weight: 600;
          margin-top: 20px;
          color: #333;
        }
        &:hover {
          background: rgba(179, 221, 255, 0.192);
          color: #333;
          i {
            color: #333;
          }
          .title {
            color: #333;
          }
        }
      }
    }
  }
}
</style>