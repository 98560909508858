<script>
export default {
  data() {
    return {
      primary_color: "#1d2088",
      secondary_color: "#1eb9ee",
      globalLanguages: [],
      globalTranslations: [],
    };
  },
  methods: {
    $urlCompatible(name, roll = false) {
      if (roll) {
        return name.toLowerCase().replace(/\s/g, "-");
      }
      return name.toLowerCase().replace(/\s/g, "-");
    },
    $img(name) {
      return `/images/${name}`;
    },
    $upload(name) {
      return this.$store.state.apiUploadUrl + `${name}`;
    },
    $backend() {
      return this.$store.state.backendUrl;
    },
    $startLoader() {
      document.body.style.overflow = "hidden";
      this.$store.state.startLoader = true;
    },
    $stopLoader() {
      document.body.style.overflow = "auto";
      this.$store.state.startLoader = false;
    },
    $urlContains(url) {
      return this.$route.path.includes(url);
    },
    $adminLogin(user, token) {
      localStorage.setItem("nmw_admin", JSON.stringify(user));
      localStorage.setItem("nmw_admin_token", token);
      this.$router.push({ name: "AdminDashboard" });
    },
    $getAdmin() {
      return JSON.parse(localStorage.getItem("nmw_admin"));
    },
    $getAdminToken() {
      // return localStorage.getItem("nmw_admin_token");
      return "d9e6620097f19e3929b3d07f4214a17a31c8ee441883c031d328b983b38be5b4da99968bb3372472c23c9cf880ff068837d136bdd22b62947d0b8d538952ce151725016192";
    },
    $adminIsAdmin() {
      const user = JSON.parse(localStorage.getItem("nmw_admin"));
      if (user.type == "admin") {
        return true;
      }
      return false;
    },
    $adminIsAgent() {
      const user = JSON.parse(localStorage.getItem("nmw_admin"));
      if (user.type == "agent") {
        return true;
      }
      return false;
    },
    $canAddHouse(houses) {
      const user = JSON.parse(localStorage.getItem("nmw_admin"));
      if (user.type == "admin" && user.organization.max_houses > houses) {
        return true;
      }
      return false;
    },
    $adminLogout() {
      localStorage.removeItem("nmw_admin");
      localStorage.removeItem("nmw_admin_token");
      this.$router.push({ name: "AdminLogin" });
    },
    $imageurlalt() {
      event.target.src = "https://via.placeholder.com/300";
    },
    $price(price) {
      if (isNaN(price)) {
        return price;
      } else {
        return "$" + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    $date(date, type = "date") {
      if (!date) {
        return;
      }
      let newDate = new Date(date);
      let year = newDate.getFullYear();
      let dayName = newDate.toLocaleString("default", { weekday: "long" });
      let dayNumber = newDate.toLocaleString("default", { day: "numeric" });
      // add 0 before day if less than 10
      if (dayNumber < 10) {
        dayNumber = "0" + dayNumber;
      }
      let monthName = newDate.toLocaleString("default", { month: "long" });
      let monthNameShort = newDate.toLocaleString("default", {
        month: "short",
      });
      let monthNumber = newDate.toLocaleString("default", { month: "numeric" });
      // add 0 before month if less than 10
      if (monthNumber < 10) {
        monthNumber = "0" + monthNumber;
      }
      let dateDots = `${dayNumber}.${monthNumber}.${year}`;
      if (type == "date") {
        return `${dayName}, ${dayNumber} ${monthName}, ${year}`;
      }
      if (type == "dots") {
        return dateDots;
      }
      if (type == "day") {
        return dayNumber;
      }
      if (type == "month") {
        return monthNameShort;
      }
      if (type == "year") {
        return year;
      }
    },
    $ago(date) {
      if (!date) {
        return;
      }
      // get time ago since  date
      let newDate = new Date(date);
      let today = new Date();
      let diff = today - newDate;
      let seconds = Math.floor(diff / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      let days = Math.floor(hours / 24);
      let weeks = Math.floor(days / 7);
      let months = Math.floor(days / 30);
      let years = Math.floor(days / 365);
      if (seconds < 60) {
        return seconds + " seconds ago";
      } else if (minutes < 60) {
        return minutes + " minutes ago";
      } else if (hours < 24) {
        return hours + " hours ago";
      } else if (days < 7) {
        return days + " days ago";
      } else if (weeks < 4) {
        return weeks + " weeks ago";
      } else if (months < 12) {
        return months + " months ago";
      } else {
        return years + " years ago";
      }
    },
    $toggleSANav() {
      this.$store.state.showSANav = !this.$store.state.showSANav;
      localStorage.setItem("showSANav", this.$store.state.showSANav);
    },
    $comma(amount) {
      if (!amount) {
        return;
      }
      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    $changePage(page) {
      if (this.currentPage === page) return;
      this.currentPage = page;
      this.getItems();
    },
    $previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getItems();
      }
    },
    $nextPage() {
      if (this.currentPage < this.pagesNumber) {
        this.currentPage++;
        this.getItems();
      }
    },
    $num(val) {
      // add 0 before number if less than 10
      if (val < 10) {
        return "0" + val;
      }
      return val;
    },
    $fiesIcons(type, name) {
      if (type == "folder") {
        return "folder.png";
      }
      if (type == "file") {
        let ext = name.split(".").pop();
        if (ext == "pdf") {
          return "pdf.png";
        }
        if (ext == "doc" || ext == "docx") {
          return "word.png";
        }
        if (ext == "xls" || ext == "xlsx") {
          return "excel.png";
        }
        if (ext == "ppt" || ext == "pptx") {
          return "ppt.png";
        }
        if (ext == "txt") {
          return "txt.png";
        }
        if (
          ext == "zip" ||
          ext == "7z" ||
          ext == "tar" ||
          ext == "gz" ||
          ext == "rar"
        ) {
          return "zip.png";
        }
        if (
          ext == "jpg" ||
          ext == "jpeg" ||
          ext == "png" ||
          ext == "gif" ||
          ext == "svg"
        ) {
          return "image.png";
        }
        if (
          ext == "mp3" ||
          ext == "wav" ||
          ext == "ogg" ||
          ext == "flac" ||
          ext == "wma"
        ) {
          return "audio.png";
        }
        if (
          ext == "mp4" ||
          ext == "avi" ||
          ext == "mkv" ||
          ext == "mov" ||
          ext == "flv"
        ) {
          return "video.png";
        }
        if (
          ext == "exe" ||
          ext == "msi" ||
          ext == "apk" ||
          ext == "dmg" ||
          ext == "deb"
        ) {
          return "exe.png";
        }
        if (
          ext == "html" ||
          ext == "css" ||
          ext == "js" ||
          ext == "php" ||
          ext == "py"
        ) {
          return "code.png";
        }
        if (
          ext == "json" ||
          ext == "xml" ||
          ext == "csv" ||
          ext == "tsv" ||
          ext == "yaml"
        ) {
          return "json.png";
        }
        if (ext == "ai" || ext == "eps") {
          return "ai.png";
        }
        if (ext == "psd" || ext == "psb") {
          return "potoshop.png";
        }
        return "file.png";
      }
    },
    $fp(price) {
      if (price >= 1000000000) {
        return (price / 1000000000).toFixed(1).replace(/\.0$/, "") + "B RWF";
      }
      if (price >= 1000000) {
        return (price / 1000000).toFixed(1).replace(/\.0$/, "") + "M RWF";
      }
      return price + " RWF";
    },
    $ampm(time) {
      if (!time) {
        return;
      }
      let newTime = time.split(":");
      let hours = newTime[0];
      let minutes = newTime[1];
      let ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      let strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
    $saveTxt(txt, lang) {
      this.$store
        .dispatch("ADD_LANGUAGE_TEXT", {
          data: {
            texts: {
              [lang]: txt,
            },
          },
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.$getTranslations();
            }
          }
        })
        .catch((err) => {});
    },
    $getlang() {
      return localStorage.getItem("lang");

      },
    $txt(txt) {
      let lang = localStorage.getItem("lang");
      if (!lang) {
        localStorage.setItem("lang", "english");
        lang = "french";
      }
      let newText = "sampletext";
      if (
        this.$store.state.languages &&
        this.$store.state.languages.length > 0
      ) {
        this.$store.state.languages.forEach((language) => {
          this.$store.state.translations.forEach((translation) => {
            if (translation[language] == txt) {
              newText = translation[lang];
            }
          });
        });

        if (newText && newText == "sampletext") {
          this.$saveTxt(txt, lang);
          return txt;
        } else {
          if (newText != "" && newText != null && newText != undefined) {
            return newText;
          } else {
            return txt;
          }
        }
      } else {
        return txt;
      }
    },
    $changeLang(lang) {
      localStorage.setItem("lang", lang);
      window.location.reload();
    },
    $volunteerLogin(volunteer, token) {
      localStorage.setItem("nmw_volunteer", JSON.stringify(volunteer));
      localStorage.setItem("nmw_volunteer_token", token);
      this.$router.push({ name: "VolunteerDashboard" });
    },
    $loggedVolunteer() {
      let volunteer = JSON.parse(localStorage.getItem("nmw_volunteer"));
      if (volunteer) {
        return volunteer;
      } else {
        return false;
      }
    },
    $volunteerLogout() {
      localStorage.removeItem("nmw_volunteer");
      localStorage.removeItem("nmw_volunteer_token");
      this.$router.push({ name: "login" });
    },
    $getVolunteerId() {
      let volunteer = JSON.parse(localStorage.getItem("nmw_volunteer"));
      if (volunteer) {
        return volunteer.id;
      } else {
        return false;
      }
    },
    $getVolunteerToken() {
      let token = localStorage.getItem("nmw_volunteer_token");
      if (token) {
        return token;
      } else {
        return false;
      }
    },
    $memberLogin(member, token) {
      localStorage.setItem("nmw_member", JSON.stringify(member));
      localStorage.setItem("nmw_member_token", token);
      this.$router.push({ name: "MemberRequests" });
    },
    $loggedMember() {
      let member = JSON.parse(localStorage.getItem("nmw_member"));
      if (member) {
        return member;
      } else {
        return false;
      }
    },
    $memberLogout() {
      localStorage.removeItem("nmw_member");
      localStorage.removeItem("nmw_member_token");
      this.$router.push({ name: "MemberLogin" });
    },
    $getMemberId() {
      let member = JSON.parse(localStorage.getItem("nmw_member"));
      if (member) {
        return member.id;
      } else {
        return false;
      }
    },
    $getMemberToken() {
      let token = localStorage.getItem("nmw_member_token");
      if (token) {
        return token;
      } else {
        return false;
      }
    },
    $getTranslations() {
      this.$store
        .dispatch("GET_LANGUAGE_TEXTS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 1000,
            page: 1,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.globalLanguages = res.data.columns;
              this.globalTranslations = res.data.languages.data;
              this.$store.state.languages = res.data.columns;
              this.$store.state.translations = res.data.languages.data;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    $frenchFormat(date) {
      let newDate = new Date(date);
      let year = newDate.getFullYear();
      let dayName = newDate.toLocaleString("default", { weekday: "long" });
      let dayNumber = newDate.toLocaleString("default", { day: "numeric" });
      // add 0 before day if less than 10
      if (dayNumber < 10) {
        dayNumber = "0" + dayNumber;
      }
      let monthName = newDate.toLocaleString("default", { month: "long" });
      let monthNameShort = newDate.toLocaleString("default", {
        month: "short",
      });
      let monthNumber = newDate.toLocaleString("default", { month: "numeric" });
      // add 0 before month if less than 10
      if (monthNumber < 10) {
        monthNumber = "0" + monthNumber;
      }
      let dateDots = `${dayNumber}.${monthNumber}.${year}`;
      return `${dayNumber}/${monthNumber}/${year}`;
    },
  },
};
</script>