<template>
  <div class="volunteer-sidebar">
    <div class="links">
      <router-link :to="{ name: 'VolunteerDashboard' }">{{ $txt("Schedule")}}</router-link>
      <router-link :to="{ name: 'VolunteerCollection' }"
        >{{ $txt("Collection")}}</router-link
      >
      <router-link :to="{ name: 'VolunteerMission' }">{{ $txt("Missions")}}</router-link>
      <router-link :to="{ name: 'VolunteerRequests' }">{{ $txt("Requests")}}</router-link>
      <router-link :to="{ name: 'VolunteerAccount' }">{{ $txt("Account")}}</router-link>
      <router-link
        to="/volunteer-dashboard/requests"
        v-if="$loggedVolunteer()"
        @click.prevent="$volunteerLogout()"
        >{{ $txt("Logout")}}</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    if(this.$loggedMember()){
      this.$router.push({ name: "MemberRequests" });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.volunteer-sidebar {
  width: 250px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-right: 1px solid #ccc;
  height: calc(100vh - 100px);
  .links {
    display: flex;
    flex-direction: column;
    a {
      color: #666;
      text-decoration: none;
      width: 100%;
      padding: 12px 10px;
      border-radius: 5px;
      text-align: left;
      font-size: 0.9em;
      &:hover {
        background-color: #0066ff48;
        color: #000;
      }
      &.router-link-exact-active {
        background-color: #0066ff2c;
        color: #000;
      }
    }
  }
}
</style>