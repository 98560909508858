<template>
  <div class="login">
    <Navbar />
    <section class="intro">
      <h1>{{ $txt("Member Register") }}</h1>
      <h2>{{ $txt("Connectez-vous à votre compte") }}</h2>
    </section>
    <section class="content">
      <section class="login-form">
        <form @submit.prevent="memberRegister">
          <div>
            <label for="name">{{ $txt("Name") }}:</label>
            <input type="text" v-model="form.name" required />
          </div>
          <div>
            <label for="phone">{{ $txt("Phone") }}:</label>
            <input type="text" v-model="form.phone" required />
          </div>
          <div>
            <label for="email">{{ $txt("Email") }}:</label>
            <input type="email" id="email" v-model="form.email" required />
          </div>
          <div>
            <label for="name">{{ $txt("Address") }}:</label>
            <input type="text" v-model="form.address" required />
          </div>
          <div>
            <label for="password">{{ $txt("City") }}:</label>
            <input type="text" v-model="form.city" required />
          </div>
          <div>
            <label for="password">{{ $txt("Postal Code") }}:</label>
            <input type="text" v-model="form.postal_code" required />
          </div>
          <div>
            <label for="password">{{ $txt("Mot de passe") }}</label>
            <input
              type="password"
              id="password"
              v-model="form.password"
              required
            />
          </div>
          <button type="submit">{{ $txt("Register") }}</button>
          <p class="dont">
            {{ $txt("Already have account?") }}
            <router-link :to="{ name: 'MemberLogin' }">{{
              $txt("Login")
            }}</router-link>
          </p>
        </form>
        <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
      </section>
    </section>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Navbar from "@/components/Navbar.vue";

export default {
  name: "LoginView",
  components: {
    Navbar,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
        name: "",
        phone: "",
        address: "",
        city: "",
        postal_code: "",
      },
      errorMessage: "",
    };
  },
  methods: {
    memberRegister() {
      this.$startLoader();
      this.$store
        .dispatch("ADD_MEMBER", {
          data: {
            ...this.form,
          },
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.memberLogin();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          this.$stopLoader();
        });
    },
    memberLogin() {
      this.$startLoader();
      this.$store
        .dispatch("MEMBER_LOGIN", {
          data: {
            ...this.form,
          },
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.$memberLogin(res.data.member, res.data.token);
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          this.$stopLoader();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.login {
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.intro {
  margin-bottom: 40px;
}
.intro h1 {
  color: $primary-color;
  font-size: 2.5em;
  margin-bottom: 10px;
}
.intro h2 {
  color: #2c3e50;
  font-size: 1.5em;
  margin-bottom: 20px;
}
.content {
  width: 100%;
  max-width: 400px;
  text-align: left;
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
}
section {
  margin-bottom: 40px;
}
h3 {
  color: $primary-color;
  font-size: 1.8em;
  margin-bottom: 15px;
}
form div {
  margin-bottom: 15px;
}
label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}
input {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
}
button {
  background-color: $primary-color;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  border-radius: 5px;
}
button:hover {
  background-color: #c0392b;
}
.error {
  color: red;
  margin-top: 20px;
}
.dont {
  margin-top: 20px;
  a {
    color: $primary-color;
    text-decoration: none;
  }
}
</style>
