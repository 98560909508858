<template>
  <div class="dashboard">
    <div class="dash-content" v-if="account">
      <Topbar :pageTitle="$txt('Account Settings')" />
      <div class="account-section">
        <div class="link-tabs">
          <span
            @click="activeTab = 'Account'"
            :class="activeTab == 'Account' ? 'active' : ''"
            >{{$txt("Account")}}</span
          >
          <span
            @click="activeTab = 'Password'"
            :class="activeTab == 'Password' ? 'active' : ''"
            >{{$txt("Password")}}</span
          >
        </div>
        <div class="update-account" v-if="activeTab == 'Account'">
          <h4>{{$txt("Update Account")}}</h4>
          <form action="">
            <div class="form-group">
              <div class="input-group">
                <label for="name">{{$txt("Name")}}</label>
                <input
                  type="text"
                  id="name"
                  v-model="account.name"
                  :placeholder="$txt('Name')"
                />
              </div>
              <div class="input-group">
                <label for="email">{{$txt("Email")}}</label>
                <input
                  type="email"
                  id="email"
                  v-model="account.email"
                  :placeholder="$txt('Email')"
                />
              </div>
              <div class="input-group">
                <label for="phone">{{$txt("Phone")}}</label>
                <input
                  type="text"
                  id="phone"
                  v-model="account.phone"
                  :placeholder="$txt('Phone')"
                />
              </div>
            </div>
            <div class="form-group">
              <button
                type="submit"
                class="btn btn-primary"
                @click.prevent="updateAccount"
              >
                {{$txt("Update Account")}}
              </button>
            </div>
          </form>
        </div>
        <div class="update-password" v-if="activeTab == 'Password'">
          <h4>{{$txt("Update Password")}}</h4>
          <form action="">
            <div class="form-group">
              <div class="input-group">
                <label for="oldPassword">{{$txt("Old Password")}}</label>
                <input
                  type="password"
                  id="oldPassword"
                  v-model="password.oldPassword"
                  :placeholder="$txt('Old Password')"
                />
              </div>
              <div class="input-group">
                <label for="newPassword">{{$txt("New Password")}}</label>
                <input
                  type="password"
                  id="newPassword"
                  v-model="password.newPassword"
                  :placeholder="$txt('New Password')"
                />
              </div>
              <div class="input-group">
                <label for="confirmPassword">{{$txt("Confirm Password")}}</label>
                <input
                  type="password"
                  id="confirmPassword"
                  v-model="password.confirmPassword"
                  :placeholder="$txt('Confirm Password')"
                />
              </div>
            </div>
            <div class="form-group">
              <button
                type="submit"
                class="btn btn-primary"
                @click.prevent="updatePassword"
              >
                {{$txt("Update Password")}}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Topbar from "@/views/admin/components/Topbar.vue";
export default {
  components: {
    Topbar,
  },
  data() {
    return {
      activeTab: "Account",
      account: null,
      password: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_MY_ADMIN_ACCOUNT", {
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.account = res.data;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })

        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    updatePassword() {
      if (
        this.password.oldPassword === "" ||
        this.password.newPassword === "" ||
        this.password.confirmPassword === ""
      ) {
        toast.error("All fields are required.");
        return;
      }
      if (this.password.newPassword !== this.password.confirmPassword) {
        toast.error("New password and confirm password do not match.");
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("CHANGE_MY_ADMIN_PASSWORD", {
          token: this.$getAdminToken(),
          data: {
            oldPassword: this.password.oldPassword,
            newPassword: this.password.newPassword,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.password = {
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",
              };
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    updateAccount() {
      this.$startLoader();
      this.$store
        .dispatch("UPDATE_MY_ADMIN_ACCOUNT", {
          token: this.$getAdminToken(),
          data: this.account,
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  background: #fafafa;
  overflow-y: hidden;
  .page-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .dash-content {
    height: fit-content;
    width: 100%;
  }
  .account-section {
    padding: 20px;
    .link-tabs {
      display: flex;
      border-bottom: 1px solid #e0e0e0;
      span {
        padding: 10px 20px;
        cursor: pointer;
        font-weight: 600;
        color: #222;
        border: 1px solid #e0e0e0;
        border-radius: 5px 5px 0 0;
        margin-bottom: -1px;
        background: #f9f9f9;
        &.active {
          color: $primary_color;
          border-bottom: 1px solid #f9f9f9;
        }
      }
    }
    h4 {
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 20px;
      margin-top: 3rem;
      text-align: left;
    }
    form {
      width: 100%;
      max-width: 400px;
      .form-group {
        margin-bottom: 20px;
        .input-group {
          margin-bottom: 10px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          label {
            font-weight: 600;
            margin-bottom: 5px;
            display: block;
          }
          input {
            width: 100%;
            padding: 10px;
            border: 1px solid #eee;
            border-radius: 5px;
            outline: none;
          }
        }
      }
      button {
        padding: 10px 20px;
        background: $primary_color;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        width: 100%;
      }
    }
  }
}
</style>