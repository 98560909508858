import axios from "axios";
import { BACKEND } from "../variables";
const GET_ALL_MEMBERS_REQUESTS_URL = BACKEND + "/member-requests";
const GET_REQUESTS_BY_MEMBER_ID_URL =
  BACKEND + "/member-requests/get-by-member";
const ADD_MEMBER_REQUEST_URL = BACKEND + "/member-requests/add";
const UPDATE_MEMBER_REQUEST_URL = BACKEND + "/member-requests/update";
const DELETE_MEMBER_REQUEST_URL = BACKEND + "/member-requests/delete";
const APPROVE_MEMBER_REQUEST_URL = BACKEND + "/member-requests/approve";
const REJECT_MEMBER_REQUEST_URL = BACKEND + "/member-requests/reject";
const members = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ["GET_ALL_MEMBERS_REQUESTS"]: (state, payload) => {
      return axios({
        url: GET_ALL_MEMBERS_REQUESTS_URL + `?page=${payload.data.page}`,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["GET_REQUESTS_BY_MEMBER_ID"]: (state, payload) => {
      return axios({
        url: GET_REQUESTS_BY_MEMBER_ID_URL + `?page=${payload.data.page}`,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["ADD_MEMBER_REQUEST"]: (state, payload) => {
      return axios({
        url: ADD_MEMBER_REQUEST_URL,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["UPDATE_MEMBER_REQUEST"]: (state, payload) => {
      return axios({
        url: UPDATE_MEMBER_REQUEST_URL,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["DELETE_MEMBER_REQUEST"]: (state, payload) => {
      return axios({
        url: DELETE_MEMBER_REQUEST_URL,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["APPROVE_MEMBER_REQUEST"]: (state, payload) => {
      return axios({
        url: APPROVE_MEMBER_REQUEST_URL,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["REJECT_MEMBER_REQUEST"]: (state, payload) => {
      return axios({
        url: REJECT_MEMBER_REQUEST_URL,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
  },
  modules: {},
};
export default members;
