<template>
  <div class="dashboard">
    <div class="dash-content">
      <Topbar :pageTitle="$txt('Members')" />
      <div class="my-table">
        <div class="table-header flex-btn">
          <form class="table-filters">
            <fieldset>
              <legend>{{ $txt("Merchant") }}</legend>
              <div class="form-group">
                <select
                  id="wing"
                  class="form-control"
                  v-model="searchQuery.member_id"
                >
                  <option value="">{{$txt("All")}}</option>
                  <option
                    v-for="member in members"
                    :key="member.id"
                    :value="member.id"
                  >
                    {{ member.name }}
                  </option>
                </select>
              </div>
            </fieldset>
            <fieldset>
              <legend>{{ $txt("Search") }}</legend>
              <div class="form-group">
                <input
                  type="text"
                  id="name"
                  class="form-control"
                  :placeholder="$txt('Search')"
                  v-model="searchQuery.search"
                />
              </div>
            </fieldset>
            <!-- Filter -->
            <button
              class="my-btn cw wd glow"
              type="submit"
              @click.prevent="getItems()"
            >
              {{ $txt("Filter") }}
            </button>
          </form>

          <button class="my-btn cw wd glow" @click="startAdd = true">
            {{ $txt("Add request type") }}
          </button>
        
        </div>
        <table v-if="allData.length > 0">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ $txt("Title") }}</th>
              <th>{{ $txt("Type") }}</th>
              <th>{{ $txt("Member") }}</th>
              <th>{{ $txt("Date") }}</th>
              <th>{{ $txt("Email") }}</th>
              <th>{{ $txt("Phone") }}</th>
              <th>{{ $txt("Address") }}</th>
              <th>{{ $txt("City") }}</th>
              <th>{{ $txt("Status") }}</th>
              <th>{{ $txt("Action") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in allData" :key="item.id">
              <td>
                {{ index + 1 }}
              </td>
              <td>{{ item.title }}</td>
              <td>{{ item.request_type }}</td>
              <td>{{ item.member.name }}</td>
              <td>{{ item.date }} {{ item.time }}</td>
              <td>{{ item.member.email }}</td>
              <td>{{ item.member.phone }}</td>
              <td>{{ item.member.address }}</td>
              <td>{{ item.member.city }}</td>
              <td>
                <span :class="`chip ${item.status}`">{{
                  $txt(item.status)
                }}</span>
              </td>
              <td>
                <a-dropdown>
                  <template #overlay>
                    <a-menu @click="handleMenuClick">
                      <a-menu-item
                        key="1"
                        @click="
                          selectedItem = item;
                          startView = true;
                        "
                      >
                        {{ $txt("View") }}
                      </a-menu-item>
                      <a-menu-item
                        key="2"
                        @click="
                          selectedItem = item;
                          startApprove = true;
                        "
                      >
                        {{ $txt("Approve") }}
                      </a-menu-item>
                      <a-menu-item
                        key="3"
                        @click="
                          selectedItem = item;
                          startReject = true;
                        "
                      >
                        {{ $txt("Reject") }}
                      </a-menu-item>
                    </a-menu>
                  </template>
                  <a-button>
                    {{ $txt("Actions") }}
                    <a-icon type="down" />
                  </a-button>
                </a-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button
            :class="{ disabled: currentPage === 1 }"
            @click="$previousPage()"
          >
            <label>{{ $txt("Previous") }}</label>
          </button>
          <p v-if="currentPage > 3">...</p>
          <button class="numbers" v-for="number in pagesNumber" :key="number">
            <label
              :class="{ active: currentPage === number }"
              for=""
              v-if="
                (number == 1 && currentPage <= 2) ||
                (currentPage + 2 >= pagesNumber && number >= pagesNumber - 2) ||
                (number >= currentPage - 2 && number <= currentPage + 2)
              "
              @click="$changePage(number)"
            >
              {{ number }}
            </label>
          </button>
          <p v-if="currentPage <= pagesNumber - 3">...</p>
          <button
            :class="{ disabled: currentPage === pagesNumber.length }"
            @click="$nextPage()"
          >
            <label>{{ $txt("Next") }}</label>
          </button>
        </div>
      </div>

      <Modal
        :title="$txt('View Request')"
        :size="'sm'"
        v-model:open="startView"
        centered
        :okText="'Ok'"
        :cancelText="$txt('Cancel')"

        @ok="approveItem()"
        width="500px"
        :footer="null"
      >
        <div class="request-content">
          <h2 class="member mt-10">{{ $txt("Member") }}:</h2>
          <p>
            <b>{{ $txt("Names") }}:</b> {{ selectedItem.member.name }}
          </p>
          <p>
            <b>{{ $txt("Phone") }}:</b> {{ selectedItem.member.phone }}
          </p>
          <p>
            <b>{{ $txt("Email") }}:</b> {{ selectedItem.member.email }}
          </p>
          <p>
            <b>{{ $txt("Address") }}:</b> {{ selectedItem.member.address }}
          </p>
          <p>
            <b>{{ $txt("City") }}:</b> {{ selectedItem.member.city }}
          </p>
          <p>
            <b>{{ $txt("Postal Code") }}:</b>
            {{ selectedItem.member.postal_code }}
          </p>
          <hr style="border: none; border-bottom: 1px solid #eee" />
          <h2 class="request mt-10">{{ $txt("Request") }}:</h2>
          <p>
            <b>{{ $txt("Title") }}:</b> {{ selectedItem.title }}
          </p>
          <p>
            <b>{{ $txt("Date") }}:</b> {{ $frenchFormat(selectedItem.date) }}
            {{ selectedItem.time }}
          </p>
          <p v-if="selectedItem.request_type">
            <b>{{ $txt("Request Type") }}:</b> {{ selectedItem.request_type }}
          </p>
          <p v-if="selectedItem.request_type === 'Car' && selectedItem.car ">
            <b>{{ $txt("Car") }}:</b> {{ selectedItem.car.plate_number }}
          </p>
          <p>
            <b>{{ $txt("Description") }}:</b> {{ selectedItem.description }}
          </p>
          <p>
            <b
              >{{ $txt("Status") }}:
              <span :class="`chip ${selectedItem.status}`">{{
                selectedItem.status
              }}</span></b
            >
          </p>
        </div>
      </Modal>
      <Modal
        :title="$txt('Approve Request')"
        :size="'sm'"
        v-model:open="startApprove"
        centered
        :okText="$txt('Approve')"
        :cancelText="$txt('Cancel')"
        @ok="approveItem()"
        width="400px"
      >
        <div class="form-group group-1">
          <div class="input-group">
            <label for="name">{{ $txt("Car") }}</label>
            <a-select
              id="name"
              v-model:value="selectedItem.car_id"
              placeholder="Car"
              style="width: 100%"
            >
              <a-select-option
                v-for="car in cars"
                :key="car.id"
                :value="car.id"
                >{{ car.plate_number }}</a-select-option
              >
            </a-select>
          </div>
          <div class="input-group">
            <label for="name">{{ $txt("Message") }}</label>
            <a-textarea
              v-model:value="selectedItem.message"
              class="form-control"
              placeholder="Message"
            />
          </div>
        </div>
      </Modal>

       <Modal
        :title="$txt('add request type')"
        :size="'sm'"
        v-model:open="startAdd"
        centered
        :okText="$txt('Save')"
        :cancelText="$txt('Cancel')"
        @ok="saveRequestType()"
        width="400px"
      >
        <div class="form-group group-1">
          <div class="input-group">
            <label for="name">{{ $txt("Name") }}</label>
            <a-input
              v-model:value="newRequestType.name"
              class="form-control"
              placeholder="Message"
            />
           
          </div>
  
        </div>
      </Modal>
      <Modal
        :title="$txt('Reject Request')"
        :size="'sm'"
        v-model:open="startReject"
        centered
        :okText="$txt('Reject')"
        :cancelText="$txt('Cancel')"
        @ok="rejectItem()"
        width="400px"
      >
        <p>{{ $txt("Are you sure you want to reject this request?") }}</p>
      </Modal>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Topbar from "@/views/admin/components/Topbar.vue";
import { Modal } from "ant-design-vue";
export default {
  components: {
    Topbar,
    Modal,
  },
  data() {
    return {
      allItemsCount: 1,
      itemsPerpage: 10,
      pagesNumber: 1,
      currentPage: 1,
      allData: [],
      searchQuery: {
        search: "",
        member_id: "",
      },
      startAdd: false,
      startEdit: false,
      startDelete: false,
      startApprove: false,
      startReject: false,
      startView: false,
      selectedItem: {
        name: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        postal_code: "",
      },
      newItem: {
        name: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        postal_code: "",
      },
      members: [],
      viewItems: false,
      schedules: [],
      cars: [],
      activeSchedule: "Today",
      scheduleDate: "",
      newRequestType:{
        name: "",
          },
      
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ALL_MEMBERS_REQUESTS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
            searchQuery: this.searchQuery,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.allData = res.data.data;
              this.allItemsCount = res.data.total;
              this.pagesNumber = Math.ceil(
                this.allItemsCount / this.itemsPerpage
              );
              this.$stopLoader();
              this.allData.forEach((item) => {
                if (!item.skills) {
                  item.skills = [];
                }
                if (!item.availability) {
                  item.availability = [];
                }
              });
            } else {
              toast.error(this.$txt(res.data.message));
              this.$stopLoader();
            }
          }
        })

        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    updateItem() {
      this.$startLoader();
      this.$store
        .dispatch("UPDATE_MEMBER_REQUEST", {
          data: {
            ...this.selectedItem,
          },
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(this.$txt(res.data.message));
              this.getItems();
              this.startEdit = false;
              this.$stopLoader();
            } else {
              toast.error(this.$txt(res.data.message));
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_MEMBER_REQUEST", {
          data: {
            ...this.selectedItem,
          },
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(this.$txt(res.data.message));
              this.getItems();
              this.startDelete = false;
              this.$stopLoader();
            } else {
              toast.error(this.$txt(res.data.message));
              this.$stopLoader();
            }
          }
        })

        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    approveItem() {
      this.$startLoader();
      this.$store
        .dispatch("APPROVE_MEMBER_REQUEST", {
          data: {
            ...this.selectedItem,
          },
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(this.$txt(res.data.message));
              this.getItems();
              this.startApprove = false;
              this.$stopLoader();
            } else {
              toast.error(this.$txt(res.data.message));
              this.$stopLoader();
            }
          }
        })

        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    rejectItem() {
      this.$startLoader();
      this.$store
        .dispatch("REJECT_MEMBER_REQUEST", {
          data: {
            ...this.selectedItem,
          },
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(this.$txt(res.data.message));
              this.getItems();
              this.startReject = false;
              this.$stopLoader();
            } else {
              toast.error(this.$txt(res.data.message));
              this.$stopLoader();
            }
          }
        })

        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    getMembers() {
      this.$store
        .dispatch("GET_MEMBERS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 1000,
            page: this.currentPage,
            searchQuery: this.searchQuery,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.members = res.data.data;
            }
          }
        })

        .catch((err) => {
          console.log(err);
        });
    },
    getCars() {
      this.$store
        .dispatch("GET_CARS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 1000,
            page: 1,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.cars = res.data.data;
            } else {
              toast.error(this.$txt(res.data.message));
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
        });
    },

    saveRequestType() {
      this.$startLoader();
      this.$store
        .dispatch("ADD_REQUESTTYPE", {
          data: {
            ...this.newRequestType,
          },
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(this.$txt(res.data.message));
              this.getItems();
              this.startAdd = false;
              this.$stopLoader();
            } else {
              toast.error(this.$txt(res.data.message));
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getCars();
    this.getItems();
    this.getMembers();
  },
};

</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  background: #fafafa;
  overflow-y: hidden;
  .page-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .dash-content {
    // padding: 0 20px;
    height: fit-content;
    // overflow-y: auto;
    width: 100%;
  }
}
</style>