import axios from "axios";
import { BACKEND } from "../variables";
const GET_ADMINS_URL = BACKEND + "/admins";
const ADD_ADMIN_URL = BACKEND + "/admins/register";
const ADMIN_LOGIN_URL = BACKEND + "/admins/login";
const ADMIN_LOGOUT_URL = BACKEND + "/admins/logout";
const UPDATE_ADMIN_URL = BACKEND + "/admins/update";
const DELETE_ADMIN_URL = BACKEND + "/admins/delete";
const GET_MY_ADMIN_ACCOUNT_URL = BACKEND + "/admins/getMyAccount";
const CHANGE_MY_ADMIN_PASSWORD_URL = BACKEND + "/admins/changePassword";
const UPDATE_MY_ADMIN_ACCOUNT_URL = BACKEND + "/admins/updateMyAccount";
const admins = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ["GET_ADMINS"]: (state, payload) => {
      return axios({
        url: GET_ADMINS_URL + `?page=${payload.data.page}`,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["ADD_ADMIN"](state, payload) {
      return axios({
        method: "POST",
        url: ADD_ADMIN_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["ADMIN_LOGIN"](state, payload) {
      return axios({
        method: "POST",
        url: ADMIN_LOGIN_URL,
        data: payload.data,
      });
    },
    ["ADMIN_LOGOUT"](state, payload) {
      return axios({
        method: "POST",
        url: ADMIN_LOGOUT_URL,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_ADMIN"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_ADMIN_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_ADMIN"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_ADMIN_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_MY_ADMIN_ACCOUNT"](state, payload) {
      return axios({
        url: GET_MY_ADMIN_ACCOUNT_URL,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["CHANGE_MY_ADMIN_PASSWORD"](state, payload) {
      return axios({
        method: "POST",
        url: CHANGE_MY_ADMIN_PASSWORD_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_MY_ADMIN_ACCOUNT"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_MY_ADMIN_ACCOUNT_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
  },
  modules: {},
};
export default admins;
