import axios from "axios";
import { BACKEND } from "../variables";
const GET_WAREHOUSES_URL = BACKEND + "/warehouses";
const GET_WAREHOUSE_BY_ID_URL = BACKEND + "/warehouses/get";
const ADD_WAREHOUSE_URL = BACKEND + "/warehouses/add";
const UPDATE_WAREHOUSE_URL = BACKEND + "/warehouses/update";
const DELETE_WAREHOUSE_URL = BACKEND + "/warehouses/delete";
const warehouses = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ["GET_WAREHOUSES"]: (state, payload) => {
      return axios({
        url: GET_WAREHOUSES_URL + `?page=${payload.data.page}`,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["GET_WAREHOUSE_BY_ID"]: (state, payload) => {
      return axios({
        url: GET_WAREHOUSE_BY_ID_URL,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["ADD_WAREHOUSE"](state, payload) {
      return axios({
        method: "POST",
        url: ADD_WAREHOUSE_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_WAREHOUSE"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_WAREHOUSE_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_WAREHOUSE"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_WAREHOUSE_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
  },
  modules: {},
};
export default warehouses;
