<template>
  <div class="NWMultiValue" v-if="valuetype === 'multi'">
    <div
      class="multivalue-item"
      v-for="(item, index) in newValues.length"
      :key="index"
    >
      <div class="multivalue-item-inputs">
        <a-input
          type="text"
          v-model:value="newValues[index].item"
          placeholder="Item"
          @change="dataChanges"
        />
        <a-input
          type="number"
          v-model:value="newValues[index].quantity"
          placeholder="Quantity"
          @change="dataChanges"
        />
        <a-button @click.prevent="removeValue(index)">-</a-button>
      </div>
    </div>
    <a-button
      style="background: #203529; border: none; color: white"
      @click.prevent="addValue()"
      >{{ btnText }}</a-button
    >
  </div>
  <div class="NWMultiValue" v-if="valuetype === 'single'">
    <div
      class="multivalue-item"
      v-for="(item, index) in newValues.length"
      :key="index"
    >
      <div class="multivalue-item-inputs" :class="valuetype">
        <a-input
          type="text"
          v-model:value="newValues[index]"
          :placeholder="placeholder"
          @change="dataChanges"
        />
        <a-button class="danger" @click.prevent="singleremoveValue(index)"
          ><i class="fa-solid fa-minus"></i
        ></a-button>
      </div>
    </div>
    <a-button
      style="background: #203529; border: none; color: white"
      @click.prevent="singleaddValue()"
      >{{ btnText }}</a-button
    >
  </div>
</template>

<script>
export default {
  name: "NWMultiValue",
  props: {
    values: {
      type: Array,
      default: () => [],
    },
    valuetype: {
      type: String,
      default: "multi",
    },
    placeholder: {
      type: String,
      default: "Value",
    },
    btnText: {
      type: String,
      default: "+ Add item",
    },
  },
  data() {
    return {
      newValues: [],
    };
  },
  methods: {
    addValue() {
      this.newValues.push({
        item: "",
        quantity: "",
      });
      this.dataChanges();
    },
    removeValue(index) {
      this.newValues.splice(index, 1);
      this.dataChanges();
    },
    singleaddValue() {
      this.newValues.push("");
      this.dataChanges();
    },
    singleremoveValue(index) {
      this.newValues.splice(index, 1);
      this.dataChanges();
    },
    dataChanges() {
      this.$emit("valueChange", this.newValues);
    },
  },
  mounted() {
    this.newValues = this.values;
  },
  watch: {
    values: {
      handler() {
        if (this.values && this.values != null) {
          this.newValues = this.values;
        } else {
          this.$emit("valueChange", this.newValues);
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.NWMultiValue {
  .multivalue-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    .multivalue-item-inputs {
      display: grid;
      grid-template-columns: 5fr 5fr 1fr;
      gap: 1rem;
      width: 100%;
      &.single {
        grid-template-columns: 10fr 1fr;
      }
      a-input {
        width: 100%;
      }
    }
  }
  .danger {
    background: #b9171f7c;
    border: none;
    color: white;
    line-height: 0;
    &:hover {
      background: #b9171f;
    }
  }
}
</style>