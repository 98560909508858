import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import adminRoutes from "./adminRoutes";
import ContactView from "../views/ContactView.vue";
import LoginView from "../views/LoginView.vue";
import SignUpView from "@/views/SignUpView.vue";
import VolunteerDashboard from "@/views/VolunteerDashboard/Dashboard.vue";
import VolunteerCollection from "@/views/VolunteerDashboard/Collections.vue";
import VolunteerMission from "@/views/VolunteerDashboard/Missions.vue";
import VolunteerRequests from "@/views/VolunteerDashboard/Requests.vue";
import VolunteerAccount from "@/views/VolunteerDashboard/Account.vue";
import MemberLogin from "@/views/MemberLogin.vue";
import MemberRegister from "@/views/MemberRegister.vue";
import MemberRequests from "@/views/MemberDashboard/Requests.vue";
import MemberAccount from "@/views/MemberDashboard/Account.vue";
import NotFound from "../views/404View.vue";
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
  },

  {
    path: "/contact",
    name: "contact",
    component: ContactView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },

  {
    path: "/sign",
    name: "sign",
    component: SignUpView,
  },
  {
    path: "/volunteer/dashboard",
    name: "VolunteerDashboard",
    component: VolunteerDashboard,
  },
  {
    path: "/volunteer/collection",
    name: "VolunteerCollection",
    component: VolunteerCollection,
  },
  {
    path: "/volunteer/mission",
    name: "VolunteerMission",
    component: VolunteerMission,
  },
  {
    path: "/volunteer/requests",
    name: "VolunteerRequests",
    component: VolunteerRequests,
  },
  {
    path: "/volunteer/account",
    name: "VolunteerAccount",
    component: VolunteerAccount,
  },
  {
    path: "/member/login",
    name: "MemberLogin",
    component: MemberLogin,
  },
  {
    path: "/member/register",
    name: "MemberRegister",
    component: MemberRegister,
  },
  {
    path: "/member/requests",
    name: "MemberRequests",
    component: MemberRequests,
  },
  {
    path: "/member/account",
    name: "MemberAccount",
    component: MemberAccount,
  },

  ...adminRoutes,
  {
    path: "/:pathMatch(.*)*",
    name: "pagesE",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
