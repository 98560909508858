import axios from "axios";
import { BACKEND } from "../variables";
const GET_ACTIVITIES_URL = BACKEND + "/activities";
const GET_ACTIVITIES_BY_ID_URL = BACKEND + "/activities/get";
const GET_ACTIVITIES_BY_VOLUNTEER_URL =
  BACKEND + "/activities/get-by-volunteer";
const ADD_ACTIVITY_URL = BACKEND + "/activities/add";
const UPDATE_ACTIVITY_URL = BACKEND + "/activities/update";
const DELETE_ACTIVITY_URL = BACKEND + "/activities/delete";
const REQUEST_ACTIVITY_URL = BACKEND + "/activity-requests/request";
const GET_REQUESTS_ACTIVITY_URL = BACKEND + "/activity-requests";
const GET_REQUEST_BY_VOLUNTEER_URL =
  BACKEND + "/activity-requests/get-by-volunteer";
const APPROVE_REQUEST__URL =
  BACKEND + "/activity-requests/approve-activity-request";
const DELETE_REQUEST__URL = BACKEND + "/activity-requests/delete";
const REJECT_REQUEST__URL =
  BACKEND + "/activity-requests/reject-activity-request";
const activities = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ["GET_ACTIVITIES"]: (state, payload) => {
      return axios({
        url: GET_ACTIVITIES_URL + `?page=${payload.data.page}`,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["GET_ACTIVITIES_BY_ID"]: (state, payload) => {
      return axios({
        url: GET_ACTIVITIES_BY_ID_URL + `?page=${payload.data.page}`,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["GET_ACTIVITIES_BY_VOLUNTEER"]: (state, payload) => {
      return axios({
        url: GET_ACTIVITIES_BY_VOLUNTEER_URL + `?page=${payload.data.page}`,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["ADD_ACTIVITY"](state, payload) {
      return axios({
        method: "POST",
        url: ADD_ACTIVITY_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_ACTIVITY"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_ACTIVITY_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_ACTIVITY"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_ACTIVITY_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["REQUEST_ACTIVITY"](state, payload) {
      return axios({
        method: "POST",
        url: REQUEST_ACTIVITY_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_REQUESTS_ACTIVITY"](state, payload) {
      return axios({
        method: "POST",
        url: GET_REQUESTS_ACTIVITY_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_REQUEST_BY_VOLUNTEER"](state, payload) {
      return axios({
        method: "POST",
        url: GET_REQUEST_BY_VOLUNTEER_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["APPROVE_REQUEST"](state, payload) {
      return axios({
        method: "POST",
        url: APPROVE_REQUEST__URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_REQUEST"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_REQUEST__URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["REJECT_REQUEST"](state, payload) {
      return axios({
        method: "POST",
        url: REJECT_REQUEST__URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
  },
  modules: {},
};
export default activities;
