<template>
  <div class="contact">
    <Navbar />
    <section class="intro">
      <h1>{{ $txt("Contactez NO MORE WASTE")}}</h1>
      <h2>{{ $txt("Nous sommes là pour vous aider")}}</h2>
    </section>
    <section class="content">
      <section class="contact-details">
        <h3>{{ $txt("Nos Coordonnées")}}</h3>
        <p>{{ $txt("Adresse")}} : 123 Rue de l'Écologie, 75000 Paris, France</p>
        <p>{{ $txt("Numéro")}} : +33 1 23 45 67 89</p>
        <p>{{ $txt("Email")}} : contact@nomorewaste.org</p>
      </section>
      <section class="contact-form">
        <h3>{{ $txt("Envoyez-nous un message")}}</h3>
        <form @submit.prevent="submitForm">
          <div>
            <label for="name">{{ $txt("Nom")}} :</label>
            <input type="text" id="name" v-model="form.name" required />
          </div>
          <div>
            <label for="email">{{ $txt("Email")}} :</label>
            <input type="email" id="email" v-model="form.email" required />
          </div>
          <div>
            <label for="message">{{ $txt("Message")}} :</label>
            <textarea id="message" v-model="form.message" required></textarea>
          </div>
          <button type="submit">{{$txt("Envoyer")}}</button>
        </form>
      </section>
    </section>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
export default {
  name: "ContactView",
  components: {
    Navbar,
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        message: "",
      },
    };
  },
  methods: {
    submitForm() {
      alert("Message envoyé !");
      // Logic to handle form submission, e.g., sending data to an API
    },
  },
};
</script>

<style scoped>
.contact {
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.intro {
  margin-bottom: 40px;
}
.intro h1 {
  color: #3C8A66;
  font-size: 2.5em;
  margin-bottom: 10px;
}
.intro h2 {
  color: #2c3e50;
  font-size: 1.5em;
  margin-bottom: 20px;
}
.content {
  width: 100%;
  max-width: 800px;
  text-align: left;
  margin-top: 20px;
}
section {
  margin-bottom: 40px;
}
h3 {
  color: #3C8A66;
  font-size: 1.8em;
  margin-bottom: 15px;
}
p {
  color: #2c3e50;
  font-size: 1.1em;
  text-align: left;
}
form div {
  margin-bottom: 15px;
}
label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}
input, textarea {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
}
button {
  background-color: #3C8A66;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
}
button:hover {
  background-color: #c0392b;
}
</style>
