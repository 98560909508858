import axios from "axios";
import { BACKEND } from "../variables";
const GET_COLLECTIONS_URL = BACKEND + "/collections";
const GET_COLLECTIONS_BY_ID_URL = BACKEND + "/collections/get";
const GET_COLLECTIONS_BY_VOLUNTEER_URL =
  BACKEND + "/collections/get-by-volunteer";
const ADD_COLLECTION_URL = BACKEND + "/collections/add";
const UPDATE_COLLECTION_URL = BACKEND + "/collections/update";
const DELETE_COLLECTION_URL = BACKEND + "/collections/delete";
const collections = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ["GET_COLLECTIONS"]: (state, payload) => {
      return axios({
        url: GET_COLLECTIONS_URL + `?page=${payload.data.page}`,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["GET_COLLECTIONS_BY_ID"]: (state, payload) => {
      return axios({
        url: GET_COLLECTIONS_BY_ID_URL + `?page=${payload.data.page}`,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["GET_COLLECTIONS_BY_VOLUNTEER"]: (state, payload) => {
      return axios({
        url: GET_COLLECTIONS_BY_VOLUNTEER_URL + `?page=${payload.data.page}`,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["ADD_COLLECTION"](state, payload) {
      return axios({
        method: "POST",
        url: ADD_COLLECTION_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_COLLECTION"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_COLLECTION_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_COLLECTION"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_COLLECTION_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
  },
  modules: {},
};
export default collections;
