<template>
  <div class="volunteerDashboard">
    <Navbar />
    <div class="volunteer-dashboard-body">
      <VolunteerSidebar />
      <div class="volunteer-content">
        <div class="my-table">
          <table v-if="allData.length > 0">
            <thead>
              <tr>
                <th>#</th>
                <th>{{ $txt("Date Sent")}}</th>
                <th>{{ $txt("Activity")}}</th>
                <th>{{ $txt("Activity Date")}}</th>
                <th>{{ $txt("Type")}}</th>
                <th>{{ $txt("Date")}}</th>
                <th>{{ $txt("Time")}}</th>
                <th>{{ $txt("Status")}}</th>
                <th>{{ $txt("Action")}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in allData" :key="item.id">
                <td>
                  {{ index + 1 }}
                </td>
                <td>
                  {{ $date(item.created_at) }}
                </td>
                <td>
                  {{ item.activity.execution_date }}
                  {{ $ampm(item.activity.execution_time) }}
                </td>
                <td>
                  <p class="ellipsis">{{ item.activity.description }}</p>
                </td>
                <td>
                  {{ item.activity_type }}
                </td>
                <td>
                  {{ item.date }}
                </td>
                <td>
                  {{ $ampm(item.time) }}
                </td>
                <td>
                  <span :class="`chip ${item.status}`">{{
                    item.status == "inProgress" ? "In Progress" : item.status
                  }}</span>
                </td>

                <td>
                  <a-dropdown>
                    <template #overlay>
                      <a-menu @click="handleMenuClick">
                        <a-menu-item
                          key="1"
                          @click="
                            selectedItem = item;
                            startDelete = true;
                          "
                        >
                          {{ $txt("Delete")}}
                        </a-menu-item>
                      </a-menu>
                    </template>
                    <a-button>
                      {{ $txt("Actions")}}
                      <a-icon type="down" />
                    </a-button>
                  </a-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="pagination">
            <button
              :class="{ disabled: currentPage === 1 }"
              @click="$previousPage()"
            >
              <label>{{ $txt("Previous")}}</label>
            </button>
            <p v-if="currentPage > 3">...</p>
            <button class="numbers" v-for="number in pagesNumber" :key="number">
              <label
                :class="{ active: currentPage === number }"
                for=""
                v-if="
                  (number == 1 && currentPage <= 2) ||
                  (currentPage + 2 >= pagesNumber &&
                    number >= pagesNumber - 2) ||
                  (number >= currentPage - 2 && number <= currentPage + 2)
                "
                @click="$changePage(number)"
              >
                {{ number }}
              </label>
            </button>
            <p v-if="currentPage <= pagesNumber - 3">...</p>
            <button
              :class="{ disabled: currentPage === pagesNumber.length }"
              @click="$nextPage()"
            >
              <label>{{ $txt("Next")}}</label>
            </button>
          </div>
        </div>
        <Modal
          :title="$txt('Delete Request')"
          :size="'sm'"
          v-model:open="startDelete"
          centered
          :okText="'Yes, delete'"
          @ok="deleteRequest()"
          width="600px"
        >
          <p>{{ $txt("Are you sure you want to cancel this request")}}?</p>
        </Modal>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import VolunteerSidebar from "@/views/VolunteerDashboard/components/VolunteerSidebar.vue";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { Modal } from "ant-design-vue";
export default {
  name: "AboutView",
  components: {
    Navbar,
    VolunteerSidebar,
    Modal,
  },
  data() {
    return {
      schedules: [],
      activeSchedule: "Today",
      scheduleDate: new Date().toISOString().split("T")[0],
      allItemsCount: 1,
      itemsPerpage: 10,
      pagesNumber: 1,
      currentPage: 1,
      allData: [],
      searchQuery: {
        search: "",
        volunteer_id: "",
      },
      volunteers: [],
      selectedItem: {},
      startShowItems: false,
      startRequest: false,
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_REQUEST_BY_VOLUNTEER", {
          token: this.$getVolunteerToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
            searchQuery: this.searchQuery,
            volunteer_id: this.$getVolunteerId(),
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.allData = res.data.data;
              this.allItemsCount = res.data.total;
              this.pagesNumber = Math.ceil(
                this.allItemsCount / this.itemsPerpage
              );
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    printList() {
      const printContents = document.getElementById("report-section").innerHTML;
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },
    filterVolunteer() {
      return this.volunteers.filter((volunteer) => {
        return volunteer.id === this.$getVolunteerId();
      });
    },
    deleteRequest() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_REQUEST", {
          data: {
            id: this.selectedItem.id,
          },
          token: this.$getVolunteerToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startRequest = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>

<style scoped lang="scss">
.schedule {
  padding: 2rem;
}
</style>
