<template>
  <div class="Cars">
    <div class="dash-content">
      <Topbar :pageTitle="$txt('Cars')" />
      <div class="my-table">
        <div class="table-header flex-btn">
          <form class="table-filters">
            <fieldset>
              <legend>{{ $txt("Search") }}</legend>
              <div class="form-group">
                <input
                  type="text"
                  id="name"
                  class="form-control"
                  :placeholder="$txt('Search')"
                  v-model="searchQuery.search"
                />
              </div>
            </fieldset>
            <!-- Filter -->
            <div class="form-group">
              <button
                class="my-btn cw wd glow"
                type="submit"
                @click.prevent="getItems()"
              >
                {{ $txt("Filter") }}
              </button>
            </div>
          </form>
          <button class="my-btn cw wd glow" @click="startAdd = true">
            {{ $txt("Add Car") }}
          </button>
        </div>
        <table v-if="allData.length > 0">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ $txt("Plate") }}</th>
              <th>{{ $txt("Model") }}</th>
              <th>{{ $txt("Brand") }}</th>
              <th>{{ $txt("Warehouse") }}</th>
              <th>{{ $txt("Status") }}</th>
              <th>{{ $txt("Action") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in allData" :key="item.id">
              <td>
                {{ index + 1 }}
              </td>
              <td>{{ item.plate_number }}</td>
              <td>{{ item.model }}</td>
              <td>{{ item.brand }}</td>
              <td>{{ item.warehouse.name }}</td>
              <td>
                <span :class="`chip ${item.status}`">{{
                  $txt(item.status)
                }}</span>
              </td>
              <td>
                <a-dropdown>
                  <template #overlay>
                    <a-menu @click="handleMenuClick">
                      <a-menu-item
                        key="2"
                        @click="
                          selectedItem = item;
                          startEdit = true;
                        "
                      >
                        {{ $txt("Edit") }}
                      </a-menu-item>
                      <a-menu-item
                        key="3"
                        @click="
                          selectedItem = item;
                          startDelete = true;
                        "
                      >
                        {{ $txt("Delete") }}
                      </a-menu-item>
                    </a-menu>
                  </template>
                  <a-button>
                    {{ $txt("Actions") }}
                    <a-icon type="down" />
                  </a-button>
                </a-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button
            :class="{ disabled: currentPage === 1 }"
            @click="$previousPage()"
          >
            <label>{{ $txt("Previous") }}</label>
          </button>
          <p v-if="currentPage > 3">...</p>
          <button class="numbers" v-for="number in pagesNumber" :key="number">
            <label
              :class="{ active: currentPage === number }"
              for=""
              v-if="
                (number == 1 && currentPage <= 2) ||
                (currentPage + 2 >= pagesNumber && number >= pagesNumber - 2) ||
                (number >= currentPage - 2 && number <= currentPage + 2)
              "
              @click="$changePage(number)"
            >
              {{ number }}
            </label>
          </button>
          <p v-if="currentPage <= pagesNumber - 3">...</p>
          <button
            :class="{ disabled: currentPage === pagesNumber.length }"
            @click="$nextPage()"
          >
            <label>{{ $txt("Next") }}</label>
          </button>
        </div>
      </div>

      <Modal
        :title="$txt('Add Car')"
        :size="'lg'"
        v-model:open="startAdd"
        centered
        :okText="$txt('Save')"
        :cancelText="$txt('Cancel')"
        @ok="createItem()"
        width="600px"
      >
        <div class="form-group group-2">
          <div class="input-group">
            <label for="name">{{ $txt("Plate") }}</label>
            <a-input
              type="text"
              id="name"
              v-model:value="newItem.plate_number"
              class="form-control"
              :placeholder="$txt('Plate number')"
            />
          </div>
          <div class="input-group">
            <label for="name">{{ $txt("Model") }}</label>
            <a-input
              type="text"
              id="name"
              v-model:value="newItem.model"
              class="form-control"
              :placeholder="$txt('Model')"
            />
          </div>
          <div class="input-group">
            <label for="name">{{ $txt("Brand") }}</label>
            <a-input
              type="text"
              id="name"
              v-model:value="newItem.brand"
              class="form-control"
              :placeholder="$txt('Brand')"
            />
          </div>
          <div class="input-group">
            <label for="name">{{ $txt("Warehouse") }}</label>
            <a-select
              v-model:value="newItem.warehouse_id"
              :placeholder="$txt('Warehouse')"
              style="width: 100%"
              :size="'large'"
            >
              <a-select-option
                v-for="warehouse in warehouses"
                :key="warehouse.id"
                :value="warehouse.id"
                >{{ warehouse.name }}</a-select-option
              >
            </a-select>
          </div>
        </div>
      </Modal>
      <!-- update modal -->
      <Modal
        :title="$txt('Edit Car')"
        :size="'lg'"
        v-model:open="startEdit"
        centered
        :okText="$txt('Update')"
        :cancelText="$txt('Cancel')"
        @ok="updateItem()"
        width="600px"
      >
        <div class="form-group group-2">
          <div class="input-group">
            <label for="name">{{ $txt("Plate") }}</label>
            <a-input
              type="text"
              id="name"
              v-model:value="selectedItem.plate_number"
              class="form-control"
              :placeholder="$txt('Plate number')"
            />
          </div>
          <div class="input-group">
            <label for="name">{{ $txt("Model") }}</label>
            <a-input
              type="text"
              id="name"
              v-model:value="selectedItem.model"
              class="form-control"
              :placeholder="$txt('Model')"
            />
          </div>
        </div>
        <div class="form-group group-3">
          <div class="input-group">
            <label for="name">{{ $txt("Brand") }}</label>
            <a-input
              type="text"
              id="name"
              v-model:value="selectedItem.brand"
              class="form-control"
              :placeholder="$txt('Brand')"
            />
          </div>
          <div class="input-group">
            <label for="name">{{ $txt("Warehouse") }}</label>
            <a-select
              v-model:value="selectedItem.warehouse_id"
              :placeholder="$txt('Warehouse')"
              style="width: 100%"
              :size="'large'"
            >
              <a-select-option
                v-for="warehouse in warehouses"
                :key="warehouse.id"
                :value="warehouse.id"
                >{{ warehouse.name }}</a-select-option
              >
            </a-select>
          </div>
          <div class="input-group">
              <label for="name">{{ $txt("Status") }}</label>
            <a-select
              v-model:value="selectedItem.status"
              :placeholder="$txt('Status')"
              style="width: 100%"
              :size="'large'"
            >
              <a-select-option
                  v-for="status in ['available', 'Unavailable']"
                  :key="status"
              >
                {{ status === 'available' ? $txt(status) : status }}
              </a-select-option>

            </a-select>
          </div>
        </div>
      </Modal>
      <!-- delete modal -->
      <Modal
        :title="$txt('Delete Car')"
        :size="'sm'"
        v-model:open="startDelete"
        centered
        :okText="$txt('Delete')"
        :cancelText="$txt('Cancel')"
        @ok="deleteItem()"
        width="600px"
      >
        <p>
          {{ $txt("Are you sure you want to delete this car") }}?
        </p>
      </Modal>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Topbar from "@/views/admin/components/Topbar.vue";
import { Modal } from "ant-design-vue";
export default {
  components: {
    Topbar,
    Modal,
  },
  data() {
    return {
      allItemsCount: 1,
      itemsPerpage: 10,
      pagesNumber: 1,
      currentPage: 1,
      allData: [],
      searchQuery: {
        search: "",
        created_by: "all",
      },
      startAdd: false,
      startEdit: false,
      startDelete: false,
      selectedItem: {},
      newItem: {
        name: "",
        address: "",
        description: "",
      },
      warehouses: [],
    };
  },
  methods: {
    getWarehouses() {
      this.$store
        .dispatch("GET_WAREHOUSES", {
          token: this.$getAdminToken(),
          data: {
            paginate: 1000,
            page: this.currentPage,
            searchQuery: this.searchQuery,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.warehouses = res.data.data;
            } else {
              toast.error(this.$txt(res.data.message));
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
        });
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_CARS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
            searchQuery: this.searchQuery,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.allData = res.data.data;
              this.allItemsCount = res.data.total;
              this.pagesNumber = Math.ceil(
                this.allItemsCount / this.itemsPerpage
              );
              this.$stopLoader();
            } else {
              toast.error(this.$txt(res.data.message));
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    createItem() {
      // start the loading
      this.$startLoader();

      this.$store
        .dispatch("ADD_CAR", {
          data: {
            ...this.newItem,
          },
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(this.$txt(res.data.message));
              this.getItems();
              this.startAdd = false;
              this.$stopLoader();
            } else {
              toast.error(this.$txt(res.data.message));
              this.$stopLoader();
            }
          }
        })

        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    updateItem() {
      this.$startLoader();
      this.$store
        .dispatch("UPDATE_CAR", {
          data: {
            ...this.selectedItem,
          },
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(this.$txt(res.data.message));
              this.getItems();
              this.startEdit = false;
              this.$stopLoader();
            } else {
              toast.error(this.$txt(res.data.message));
              this.$stopLoader();
            }
          }
        })

        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_CAR", {
          data: {
            ...this.selectedItem,
          },
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(this.$txt(res.data.message));
              this.getItems();
              this.startDelete = false;
              this.$stopLoader();
            } else {
              toast.error(this.$txt(res.data.message));
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getWarehouses();
    this.getItems();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.Warehouses {
  background: #fafafa;
  overflow-y: hidden;
  .page-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .dash-content {
    // padding: 0 20px;
    height: fit-content;
    // overflow-y: auto;
    width: 100%;
  }
}
</style>