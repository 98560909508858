<template>
  <div class="login">
    <Navbar />
    <section class="intro">
      <h1>{{ $txt("Connexion")}}</h1>
      <h2>{{ $txt("Connectez-vous à votre compte")}}</h2>
    </section>
    <section class="content">
      <section class="login-form">
        <h3>{{ $txt("Se connecter")}}</h3>
        <form @submit.prevent="submitForm">
          <div>
            <label for="email">{{ $txt("Email :")}}</label>
            <input type="email" id="email" v-model="form.email" required />
          </div>
          <div>
            <label for="password">{{ $txt("Mot de passe")}}</label>
            <input
              type="password"
              id="password"
              v-model="form.password"
              required
            />
          </div>
          <button type="submit">{{ $txt("Connexion")}}</button>
        </form>
        <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
      </section>
    </section>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "LoginView",
  components: {
    Navbar,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      errorMessage: "",
    };
  },
  methods: {
    async submitForm() {
      const apiUrl = "http://127.0.0.1:8000/api/v1/volunteers/login"; // Utilisez l'URL publique correcte

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: this.form.email,
            password: this.form.password,
          }),
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message || "Erreur de connexion");
        }

        console.log("Connexion réussie :", data);

        // Rediriger vers Google en cas de succès
        this.$volunteerLogin(data.volunteer, data.token);
      } catch (error) {
        console.error("Erreur de connexion :", error);
        this.errorMessage =
          error.message ||
          "Erreur de connexion. Veuillez vérifier vos identifiants.";
      }
    },
  },
};
</script>

<style scoped>
.login {
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.intro {
  margin-bottom: 40px;
}
.intro h1 {
  color: #3C8A66;
  font-size: 2.5em;
  margin-bottom: 10px;
}
.intro h2 {
  color: #2c3e50;
  font-size: 1.5em;
  margin-bottom: 20px;
}
.content {
  width: 100%;
  max-width: 400px;
  text-align: left;
  margin-top: 20px;
}
section {
  margin-bottom: 40px;
}
h3 {
  color: #3C8A66;
  font-size: 1.8em;
  margin-bottom: 15px;
}
form div {
  margin-bottom: 15px;
}
label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}
input {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
}
button {
  background-color: #3C8A66;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
}
button:hover {
  background-color: #c0392b;
}
.error {
  color: red;
  margin-top: 20px;
}
</style>
