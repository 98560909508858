import axios from "axios";
import { BACKEND } from "../variables";
const MEMBER_LOGIN_URL = BACKEND + "/members/login";
const MEMBER_LOGOUT_URL = BACKEND + "/members/logout";
const GET_MEMBERS_URL = BACKEND + "/members";
const ADD_MEMBER_URL = BACKEND + "/members/add";
const UPDATE_MEMBER_URL = BACKEND + "/members/update";
const DELETE_MEMBER_URL = BACKEND + "/members/delete";
const GET_MEMBER_ACCOUNT_URL = BACKEND + "/members/getMyAccount";
const CHANGE_MEMBER_PASSWORD_URL = BACKEND + "/members/changePassword";
const UPDATE_MEMBER_ACCOUNT_URL = BACKEND + "/members/updateMyAccount";
const members = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ["MEMBER_LOGIN"]: (state, payload) => {
      return axios({
        url: MEMBER_LOGIN_URL,
        method: "POST",
        data: payload.data,
      });
    },
    ["MEMBER_LOGOUT"]: (state, payload) => {
      return axios({
        url: MEMBER_LOGOUT_URL,
        method: "POST",
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["GET_MEMBERS"]: (state, payload) => {
      return axios({
        url: GET_MEMBERS_URL + `?page=${payload.data.page}`,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["ADD_MEMBER"]: (state, payload) => {
      return axios({
        url: ADD_MEMBER_URL,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["UPDATE_MEMBER"]: (state, payload) => {
      return axios({
        url: UPDATE_MEMBER_URL,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["DELETE_MEMBER"]: (state, payload) => {
      return axios({
        url: DELETE_MEMBER_URL,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["GET_MEMBER_ACCOUNT"]: (state, payload) => {
      return axios({
        url: GET_MEMBER_ACCOUNT_URL,
        method: "POST",
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["CHANGE_MEMBER_PASSWORD"]: (state, payload) => {
      return axios({
        url: CHANGE_MEMBER_PASSWORD_URL,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["UPDATE_MEMBER_ACCOUNT"]: (state, payload) => {
      return axios({
        url: UPDATE_MEMBER_ACCOUNT_URL,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
  },
  modules: {},
};
export default members;
