<template>
  <div class="Sidebar">
    <div class="sidebar-header">
      <img
        :src="$img('nmwlogo.png')"
        alt="K-Realty Logo"
        @error="$imageurlalt()"
      />
    </div>
    <ul class="nav-links">
      <!-- dashboard -->
      <li>
        <router-link
          :to="{ name: 'AdminDashboard' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/dashboard') }"
        >
          <i class="fas fa-tachometer-alt"></i>
          <span> {{ $txt("Dashboard") }}</span>
        </router-link>
      </li>
      <!-- rooms -->
      <li>
        <router-link
          :to="{ name: 'AdminMerchants' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/merchants') }"
        >
          <i class="fa-brands fa-product-hunt"></i>
          <span>{{ $txt("Merchants") }}</span>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'AdminSchedule' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/schedule') }"
        >
          <i class="fa-solid fa-clock"></i>
          <span>{{ $txt("Schedule") }}</span>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'AdminCollections' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/collections') }"
        >
          <i class="fa-solid fa-layer-group"></i>
          <span>{{ $txt("Collections") }}</span>
        </router-link>
      </li>
      <!-- activities -->
      <li>
        <router-link
          :to="{ name: 'AdminActivities' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/activities') }"
        >
          <i class="fa-solid fa-clipboard-list"></i>
          <span>{{ $txt("Activities/Missions") }}</span>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'AdminVolunteers' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/volunteers') }"
        >
          <i class="fa-solid fa-users"></i>
          <span>{{ $txt("Volunteers") }}</span>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'AdminRequests' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/volunteerRequests') }"
        >
          <i class="fa-solid fa-person-circle-question"></i>
          <span>{{ $txt("Volunteer requests") }}</span>
        </router-link>
      </li>

      <!---members ---->
      <li>
        <router-link
          :to="{ name: 'AdminMembers' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/members') }"
        >
          <i class="fa-solid fa-users"></i>
          <span>{{ $txt("Members") }}</span>
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'AdminMembersRequests' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/memberrequests') }"
        >
          <i class="fa-solid fa-person-circle-question"></i>
          <span>{{ $txt("Members requests") }}</span>
        </router-link>
      </li>

      <!-- Cars -->
      <li>
        <router-link
          :to="{ name: 'AdminCars' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/cars') }"
        >
          <i class="fa-solid fa-car"></i>
          <span>{{ $txt("Cars") }}</span>
        </router-link>
      </li>
      <!-- Warehouses -->
      <li>
        <router-link
          :to="{ name: 'AdminWarehouses' }"
          class="nav-link"
          :class="{ active: $urlContains('admin/warehouses') }"
        >
          <i class="fa-solid fa-warehouse"></i>
          <span>{{ $txt("Warehouses") }}</span>
        </router-link>
      </li>
      <!-- Stock -->
      <li>
        <div
          :to="{ name: 'AdminStock' }"
          class="nav-link nav-link-with-sublinks"
          :class="{ active: $urlContains('admin/stock') }"
          @click="toggleSubLinks('stock')"
        >
          <div>
            <i class="fa-solid fa-box"></i>
            <span>{{ $txt("Stock") }}</span>
          </div>
          <i
            class="fa-solid fa-chevron-down"
            :class="activePage == 'stock' ? 'active' : ''"
          ></i>
        </div>
        <ul class="sub-links" :class="activePage == 'stock' ? 'active' : ''">
          <li>
            <router-link
              :to="{ name: 'AdminStock' }"
              class="nav-link"
              :class="{ active: $urlContains('admin/stock/items') }"
            >
              <span>{{$txt("Stock Items")}}</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'AdminStockReports' }"
              class="nav-link"
              :class="{ active: $urlContains('admin/stock/reports') }"
            >
              <span>{{ $txt("Stock Reports") }}</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <div
          :to="{ name: 'AdminDashboard' }"
          class="nav-link nav-link-with-sublinks"
          :class="{ active: $urlContains('admin/settings') }"
          @click="toggleSubLinks('settings')"
        >
          <div>
            <i class="fa-solid fa-cog"></i>
            <span>{{ $txt("Settings") }}</span>
          </div>
          <i
            class="fa-solid fa-chevron-down"
            :class="activePage == 'settings' ? 'active' : ''"
          ></i>
        </div>
        <ul class="sub-links" :class="activePage == 'settings' ? 'active' : ''">
          <li>
            <router-link
              :to="{ name: 'AdminAdmins' }"
              class="nav-link"
              :class="{ active: $urlContains('admin/settings/admins') }"
            >
              <span>Admins</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'AdminLanguages' }"
              class="nav-link"
              :class="{ active: $urlContains('admin/settings/languages') }"
            >
              <span>{{ $txt("Languages") }}</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'AdminAccount' }"
              class="nav-link"
              :class="{ active: $urlContains('admin/settings/account') }"
            >
              <span>{{ $txt("Account Settings") }}</span>
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      userRole: "",
      activePage: "",
    };
  },
  methods: {
    toggleSubLinks(id) {
      if (this.activePage == id) {
        this.activePage = "";
      } else {
        this.activePage = id;
      }
    },
  },
  mounted() {
    if (this.$urlContains("admin/merchants")) {
      this.activePage = "merchants";
    }
    if (this.$urlContains("admin/collections")) {
      this.activePage = "collections";
    }
    if (this.$urlContains("admin/activities")) {
      this.activePage = "activities";
    }
    if (this.$urlContains("admin/stock")) {
      this.activePage = "stock";
    }
    if (this.$urlContains("admin/settings")) {
      this.activePage = "settings";
    }
  },
  watch: {
    $route(to, from) {
      this.activePage = null;
      if (this.$urlContains("admin/merchants")) {
        this.activePage = "merchants";
      }
      if (this.$urlContains("admin/collections")) {
        this.activePage = "collections";
      }
      if (this.$urlContains("admin/activities")) {
        this.activePage = "activities";
      }
      if (this.$urlContains("admin/stock")) {
        this.activePage = "stock";
      }
      if (this.$urlContains("admin/settings")) {
        this.activePage = "settings";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
* {
  transition: all 0.5s ease-in-out;
}
.Sidebar {
  width: 100%;
  height: 100vh;
  background-color: white;
  border-right: 1px solid white;
  border-right: 1px solid var(--neutral-gray-gray-50, #e5e8ee);
  box-shadow: 4px 0px 30px 0px rgba(131, 98, 234, 0.05);
  overflow-y: hidden;
  .sidebar-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $primary_color;
    flex-wrap: wrap;
    padding: 1rem 0;
    img {
      width: 50%;
      max-width: 100px;
      display: block;
    }
  }
  .nav-links {
    width: 100%;
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: $secondary_color;
    overflow: auto;
    padding-bottom: 2rem;
    li {
      width: 100%;
      display: flex;
      flex-direction: column;
      .nav-link {
        font-weight: 400;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0.6rem 1rem;
        padding-left: 1rem;
        height: 45px;
        font-weight: 400;
        cursor: pointer;
        span,
        i {
          font-size: 0.8rem;
          color: #ffffff8e;
          text-transform: capitalize;
        }
        i {
          margin-right: 0.5rem;
        }
        &:hover,
        &.active {
          background-color: darken($secondary_color, 4%);
          span,
          i {
            color: #fff;
          }
        }
        &.nav-link-with-sublinks {
          display: flex;
          justify-content: space-between;
          &.active {
            span,
            i {
              color: #fff;
            }
          }
        }
        .fa-chevron-down {
          font-size: 0.8rem;
          color: #ffffff8e;
          transition: all 0.3s ease-in-out;
          &.active {
            transform: rotate(180deg);
          }
        }
      }
      .sub-links {
        width: 100%;
        height: 0;
        overflow: hidden;
        transition: height 0.3s ease-in-out;
        background-color: darken($secondary_color, 3%);
        &.active {
          height: 100%;
          li {
            .nav-link {
              padding-left: 2rem;
              color: #fff;
              border-left: 4px solid transparent;
              span {
                font-weight: 400;
                font-size: 0.8rem;
                color: #ffffff8e;
              }
              &:hover,
              &.active {
                background: darken($secondary_color, 7%);
                span {
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>