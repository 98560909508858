// api
let API_URL = "";
if (process.env.NODE_ENV === "development") {
  API_URL = process.env.VUE_APP_DEV_API_URL;
} else {
  API_URL = process.env.VUE_APP_PROD_API_URL;
}
export const BACKEND = API_URL;

// upload
let uploadurl = "";
if (process.env.NODE_ENV === "development") {
  uploadurl = process.env.VUE_APP_DEV_UPLOAD_URL;
} else {
  uploadurl = process.env.VUE_APP_PROD_UPLOAD_URL;
}
export const UPLOAD_URL = uploadurl;

// backend
let backendUrl = "";
if (process.env.NODE_ENV === "development") {
  backendUrl = process.env.VUE_APP_DEV_BACKEND_URL;
} else {
  backendUrl = process.env.VUE_APP_PROD_BACKEND_URL;
}
export const BACKEND_URL = backendUrl;
