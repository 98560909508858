import axios from "axios";
import { BACKEND } from "../variables";
const GET_LANGUAGE_TEXTS_URL = BACKEND + "/languages/texts";
const ADD_LANGUAGE_URL = BACKEND + "/languages/add";
const UPDATE_LANGUAGE_URL = BACKEND + "/languages/update";
const DELETE_LANGUAGE_URL = BACKEND + "/languages/delete";
const ADD_LANGUAGE_TEXT_URL = BACKEND + "/languages/add-text";
const UPDATE_LANGUAGE_TEXT_URL = BACKEND + "/languages/update-text";
const DELETE_LANGUAGE_TEXT_URL = BACKEND + "/languages/delete-text";
const languages = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ["GET_LANGUAGE_TEXTS"]: (state, payload) => {
      return axios({
        url: GET_LANGUAGE_TEXTS_URL + `?page=${payload.data.page}`,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["ADD_LANGUAGE"](state, payload) {
      return axios({
        method: "POST",
        url: ADD_LANGUAGE_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_LANGUAGE"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_LANGUAGE_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_LANGUAGE"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_LANGUAGE_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["ADD_LANGUAGE_TEXT"](state, payload) {
      return axios({
        method: "POST",
        url: ADD_LANGUAGE_TEXT_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_LANGUAGE_TEXT"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_LANGUAGE_TEXT_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_LANGUAGE_TEXT"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_LANGUAGE_TEXT_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
  },
  modules: {},
};
export default languages;
