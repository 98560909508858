import axios from "axios";
import { BACKEND } from "../variables";
const GET_ALL_SCHEDULE_URL = BACKEND + "/volunteers/get-all-schedule";
const GET_TODAY_SCHEDULE_URL = BACKEND + "/volunteers/get-today-schedule";
const GET_TOMORROW_SCHEDULE_URL = BACKEND + "/volunteers/get-tomorrow-schedule";
const GET_THIS_WEEK_SCHEDULE_URL =
  BACKEND + "/volunteers/get-this-week-schedule";
const GET_THIS_MONTH_SCHEDULE_URL =
  BACKEND + "/volunteers/get-this-month-schedule";
const GET_SCHEDULE_BY_DATE_URL = BACKEND + "/volunteers/get-schedule-by-date";
const schedule = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ["GET_ALL_SCHEDULE"]: (state, payload) => {
      return axios({
        url: GET_ALL_SCHEDULE_URL + `?page=${payload.data.page}`,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["GET_TODAY_SCHEDULE"]: (state, payload) => {
      return axios({
        url: GET_TODAY_SCHEDULE_URL,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["GET_TOMORROW_SCHEDULE"]: (state, payload) => {
      return axios({
        url: GET_TOMORROW_SCHEDULE_URL,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["GET_THIS_WEEK_SCHEDULE"]: (state, payload) => {
      return axios({
        url: GET_THIS_WEEK_SCHEDULE_URL,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["GET_THIS_MONTH_SCHEDULE"]: (state, payload) => {
      return axios({
        url: GET_THIS_MONTH_SCHEDULE_URL,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["GET_SCHEDULE_BY_DATE"]: (state, payload) => {
      return axios({
        url: GET_SCHEDULE_BY_DATE_URL,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
  },
  modules: {},
};
export default schedule;
