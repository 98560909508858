import AdminLogin from "../views/admin/AdminLogin.vue";
import Admin from "../views/admin/Home.vue";
import AdminDashboard from "../views/admin/Dashboard.vue";
import AdminMerchants from "../views/admin/Merchants.vue";
import AdminCollections from "../views/admin/Collections.vue";
import AdminActivities from "../views/admin/Activities.vue";
import AdminVolunteers from "../views/admin/Volunteers.vue";
import AdminWarehouses from "../views/admin/Warehouses.vue";
import AdminStock from "../views/admin/Stock.vue";
import AdminStockItems from "../views/admin/StockItems.vue";
import AdminStockReports from "../views/admin/StockReports.vue";
import AdminAdmins from "../views/admin/Admins.vue";
import AdminLanguages from "../views/admin/Languages.vue";
import AdminAccount from "../views/admin/Account.vue";
import AdminSchedule from "../views/admin/Schedule.vue";
import AdminRequests from "../views/admin/Requests.vue";

import AdminMembers from "../views/admin/Members.vue";
import AdminMembersRequests from "../views/admin/MembersRequests.vue";
import AdminCars from "../views/admin/Cars.vue";
const adminRoutes = [
  {
    path: "/admin/login",
    name: "AdminLogin",
    component: AdminLogin,
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    redirect: "/admin/dashboard",
    children: [
      {
        path: "/admin/dashboard",
        name: "AdminDashboard",
        component: AdminDashboard,
      },
      {
        path: "/admin/merchants",
        name: "AdminMerchants",
        component: AdminMerchants,
      },
      {
        path: "/admin/collections",
        name: "AdminCollections",
        component: AdminCollections,
      },
      {
        path: "/admin/activities",
        name: "AdminActivities",
        component: AdminActivities,
      },
      {
        path: "/admin/volunteers",
        name: "AdminVolunteers",
        component: AdminVolunteers,
      },
      {
        path: "/admin/warehouses",
        name: "AdminWarehouses",
        component: AdminWarehouses,
      },
      {
        path: "/admin/stock",
        name: "AdminStock",
        component: AdminStock,
      },
      {
        path: "/admin/stock/items/:id/:name",
        name: "AdminStockItems",
        component: AdminStockItems,
      },
      {
        path: "/admin/stock/reports",
        name: "AdminStockReports",
        component: AdminStockReports,
      },
      {
        path: "/admin/settings/admins",
        name: "AdminAdmins",
        component: AdminAdmins,
      },
      {
        path: "/admin/settings/languages",
        name: "AdminLanguages",
        component: AdminLanguages,
      },
      {
        path: "/admin/settings/account",
        name: "AdminAccount",
        component: AdminAccount,
      },
      {
        path: "/admin/schedule",
        name: "AdminSchedule",
        component: AdminSchedule,
      },
      {
        path: "/admin/volunteerRequests",
        name: "AdminRequests",
        component: AdminRequests,
      },
      {
        path: "/admin/members",
        name: "AdminMembers",
        component: AdminMembers,
      },

      {
        path: "/admin/memberrequests",
        name: "AdminMembersRequests",
        component: AdminMembersRequests,
      },
      {
        path: "/admin/cars",
        name: "AdminCars",
        component: AdminCars,
      },
    ],
  },
];

export default adminRoutes;
