<template>
  <div class="volunteerDashboard">
    <Navbar />
    <div class="volunteer-dashboard-body">
      <VolunteerSidebar />
      <div class="volunteer-content">
        <div class="schedule">
          <h2>{{ $txt("My Schedule")}}</h2>

          <Schedule
            :schedules="schedules"
            @TodayClicked="
              activeSchedule = 'Today';
              getSchedules();
            "
            @TomorrowClicked="
              activeSchedule = 'Tomorrow';
              getSchedules();
            "
            @ThisWeekClicked="
              activeSchedule = 'This Week';
              getSchedules();
            "
            @ThisMonthClicked="
              activeSchedule = 'This Month';
              getSchedules();
            "
            @customDateClicked="
              scheduleDate = $event;
              activeSchedule = 'Custom Date';
              getSchedules();
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import VolunteerSidebar from "@/views/VolunteerDashboard/components/VolunteerSidebar.vue";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  name: "AboutView",
  components: {
    Navbar,
    VolunteerSidebar,
  },
  data() {
    return {
      schedules: [],
      activeSchedule: "Today",
      scheduleDate: new Date().toISOString().split("T")[0],
    };
  },
  methods: {
    getSchedules() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ALL_SCHEDULE", {
          token: this.$getAdminToken(),
          data: {
            volunteer_id: this.$getVolunteerId(),
            date: this.scheduleDate,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.schedules = res.data;
            } else {
              toast.error(res.data.message);
            }
          }
          this.$stopLoader();
        })

        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getSchedules();
  },
};
</script>

<style scoped lang="scss">
.schedule {
  padding: 2rem;
}
</style>
