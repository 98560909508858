import axios from "axios";
import { BACKEND } from "../variables";
const GET_STOCKS_BY_WAREHOUSE_URL = BACKEND + "/stock/get-by-warehouse";
const ADD_STOCK_ITEM_URL = BACKEND + "/stock/add";
const UPDATE_STOCK_ITEM_URL = BACKEND + "/stock/update";
const DELETE_STOCK_ITEM_URL = BACKEND + "/stock/delete";
const STOCK_IN_URL = BACKEND + "/stock/stock-in";
const STOCK_OUT_URL = BACKEND + "/stock/stock-out";
const GET_STOCK_REPORTS_URL = BACKEND + "/stock-history";
const GET_STOCK_REPORT_BY_ID_URL = BACKEND + "/stock-history/get";
const GET_STOCKS_URL = BACKEND + "/stock";
const stock = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ["GET_STOCKS"]: (state, payload) => {
      return axios({
        url: GET_STOCKS_URL + `?page=${payload.data.page}`,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["GET_STOCKS_BY_WAREHOUSE"]: (state, payload) => {
      return axios({
        url: GET_STOCKS_BY_WAREHOUSE_URL + `?page=${payload.data.page}`,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["ADD_STOCK_ITEM"]: (state, payload) => {
      return axios({
        url: ADD_STOCK_ITEM_URL,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["UPDATE_STOCK_ITEM"]: (state, payload) => {
      return axios({
        url: UPDATE_STOCK_ITEM_URL,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["DELETE_STOCK_ITEM"]: (state, payload) => {
      return axios({
        url: DELETE_STOCK_ITEM_URL,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["STOCK_IN"]: (state, payload) => {
      return axios({
        url: STOCK_IN_URL,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["STOCK_OUT"]: (state, payload) => {
      return axios({
        url: STOCK_OUT_URL,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["GET_ALL_STOCKS_REPORTS"]: (state, payload) => {
      return axios({
        url: GET_STOCK_REPORTS_URL,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["GET_STOCK_REPORT_BY_ID"]: (state, payload) => {
      return axios({
        url: GET_STOCK_REPORT_BY_ID_URL,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
  },
  modules: {},
};
export default stock;
