import axios from "axios";
import { BACKEND } from "../variables";
const GET_REQUESTTYPES_URL = BACKEND + "/requestTypes";
const ADD_REQUESTTYPE_URL = BACKEND + "/requestTypes/add";
const UPDATE_REQUESTTYPE_URL = BACKEND + "/requestTypes/update";
const DELETE_REQUESTTYPE_URL = BACKEND + "/requestTypes/delete";
const requestTypes = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ["GET_REQUESTTYPES"]: (state, payload) => {
      return axios({
        url: GET_REQUESTTYPES_URL + `?page=${payload.data.page}`,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["ADD_REQUESTTYPE"](state, payload) {
      return axios({
        method: "POST",
        url: ADD_REQUESTTYPE_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_REQUESTTYPE"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_REQUESTTYPE_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_REQUESTTYPE"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_REQUESTTYPE_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
  },
  modules: {},
};
export default requestTypes;
