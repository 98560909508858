<template>
  <div class="Schedule">
    <div class="day-links">
      <!-- <div
        class="day-link-item"
        @click="TodayClicked()"
        :class="{ active: currentView == 'Today' }"
      >
        Today
      </div>
      <div
        class="day-link-item"
        @click="TomorrowClicked()"
        :class="{ active: currentView == 'Tomorrow' }"
      >
        Tomorrow
      </div>
      <div
        class="day-link-item"
        @click="ThisWeekClicked()"
        :class="{ active: currentView == 'This Week' }"
      >
        This Week
      </div>
      <div
        class="day-link-item"
        @click="ThisMonthClicked()"
        :class="{ active: currentView == 'This Month' }"
      >
        This Month
      </div>
      <div class="select-date">
        <label for="">Select date</label>
        <a-input
          type="date"
          placeholder="Select Date"
          @change="customDateClicked()"
          v-model:value="date"
        />
      </div> -->
    </div>
    <div class="calendar is-light-mode">
      <div class="colors">
        <div class="color yellow"></div>
        <span>{{$txt("activity")}}</span>
        <div class="color green"></div>
        <span>{{$txt("Collection")}}</span>
      </div>
      <Qalendar
        :events="format(schedules)"
        :config="config"
        style="min-height: 60vh"
        v-if="config.locale!=''"
      />
    </div>
  </div>
</template>

<script>
import { Qalendar } from "qalendar";
export default {
  name: "Schedule",
  props: {
    schedules: {
      type: Array,
      default: () => [],
    },
    
  },
  components: {
    Qalendar,
  },
  data() {
    return {
      date: "",
      currentView: "Today",
      events: [
        // ...
        {
          title: "Advanced algebra",
          with: "Chandler Bing",
          time: { start: "2024-07-27 12:05", end: "2024-07-27 13:35" },
          color: "yellow",
          isEditable: true,
          id: "753944708f0f",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores assumenda corporis doloremque et expedita molestias necessitatibus quam quas temporibus veritatis. Deserunt excepturi illum nobis perferendis praesentium repudiandae saepe sapiente voluptatem!",
        },
        {
          title: "Ralph on holiday",
          with: "Rachel Greene",
          time: { start: "2022-05-10", end: "2022-05-22" },
          color: "green",
          isEditable: true,
          id: "5602b6f589fc",
        },
        // ...
      ],
      config: {
        week: {
          // Takes the value 'sunday' or 'monday'
          // However, if startsOn is set to 'sunday' and nDays to 5, the week displayed will be Monday - Friday
          startsOn: "monday",
          // Takes the values 5 or 7.
          nDays: 7,
          // Scroll to a certain hour on mounting a week. Takes any value from 0 to 23.
          // This option is not compatible with the 'dayBoundaries'-option, and will simply be ignored if custom day boundaries are set.
          scrollToHour: 5,
        },
        month: {
          // Hide leading and trailing dates in the month view (defaults to true when not set)
          showTrailingAndLeadingDates: false,
        },
        // Takes any valid locale that the browser understands. However, not all locales have been thorougly tested in Qalendar
        // If no locale is set, the preferred browser locale will be used
        style: {
          // When adding a custom font, please also set the fallback(s) yourself
          fontFamily: "sans-serif",
        },
        // if not set, the mode defaults to 'week'. The three available options are 'month', 'week' and 'day'
        // Please note, that only day and month modes are available for the calendar in mobile-sized wrappers (~700px wide or less, depending on your root font-size)
        defaultMode: "month",
        // The silent flag can be added, to disable the development warnings. This will also bring a slight performance boost
        isSilent: true,
        showCurrentTime: true, // Display a line indicating the current time
        locale:""
      },
    };
  },
  methods: {
    TodayClicked() {
      // this.currentView = "Today";
      // this.$emit("TodayClicked");
      this.config.defaultMode = "day";
      this.config.selectedDate = new Date();
    },
    TomorrowClicked() {
      this.currentView = "Tomorrow";
      // this.$emit("TomorrowClicked");
      this.config.defaultMode = "day";
      this.config.selectedDate = new Date(
        new Date().getTime() + 24 * 60 * 60 * 1000
      );
    },
    ThisWeekClicked() {
      this.currentView = "This Week";
      // this.$emit("ThisWeekClicked");
      this.config.mode = "week";
      this.config.selectedDate = new Date();
    },
    ThisMonthClicked() {
      this.currentView = "This Month";
      this.$emit("ThisMonthClicked");
    },
    customDateClicked() {
      this.currentView = "Custom Date";
      this.$emit("customDateClicked", this.date);
    },
    datetimepassed(date, time) {
      const datetime = new Date(date + " " + time);
      return datetime < new Date();
    },
    format(schedules) {
      return schedules.map((schedule) => {
        return {
          title: schedule.description,
          with: schedule.volunteer ? schedule.volunteer.name : "none",
          time: {
            start: schedule.execution_date + " " + schedule.execution_time,
            // add 2 hr
            end: this.addHours(
              schedule.execution_date,
              schedule.execution_time,
              2
            ),
          },
          color: schedule.type == "activity" ? "yellow" : "green",
          isEditable: false,
          id: schedule.id,
          description: schedule.description,
        };
      });
    },
    addHours(dateStr, timeStr, hoursToAdd) {
      const dateTime = new Date(dateStr + " " + timeStr);
      dateTime.setHours(dateTime.getHours() + hoursToAdd);

      // Formatting the date and time
      const year = dateTime.getFullYear();
      const month = String(dateTime.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const day = String(dateTime.getDate()).padStart(2, "0");
      const hours = String(dateTime.getHours()).padStart(2, "0");
      const minutes = String(dateTime.getMinutes()).padStart(2, "0");
      const seconds = String(dateTime.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
  },
  mounted() {
    this.config.locale=this.$getlang()=="french" ?"fr-FR":"en-US"
    this.currentView = "Today";

  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.Schedule {
  display: flex;
  flex-direction: column;

  .day-links {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 20px;
    .day-link-item {
      cursor: pointer;
      padding: 10px 20px;
      border-radius: 5px;
      background-color: #f5f5f5;
      &.active,
      &:hover {
        background-color: #203529;
        color: #fff;
      }
    }
    .select-date {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      label {
        font-size: 13px;
        color: #464646;
        display: none;
      }
      .ant-input {
        width: 200px;
        padding: 10px 10px;
      }
    }
  }
  .shedule-items-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .schedule-item {
      border-radius: 10px;
      overflow: hidden;
      background-color: #f5f5f5;
      .title {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        padding-top: 10px;
        margin: 0;
        font-size: 16px;
        .status {
          color: #fff;
          &.assigned,
          &.unavailable {
            background-color: #e08a00;
            padding: 0.1rem 0.5rem;
            border-radius: 50px;
            font-size: 0.75rem;
          }
          &.pending {
            background-color: #3b3b3b;
            padding: 0.1rem 0.5rem;
            border-radius: 50px;
            font-size: 0.75rem;
          }
          &.processing,
          &.inProgress {
            background-color: #bee000;
            padding: 0.1rem 0.5rem;
            border-radius: 50px;
            font-size: 0.75rem;
          }
          &.completed,
          &.available {
            background-color: green;
            padding: 0.1rem 0.5rem;
            border-radius: 50px;
            font-size: 0.75rem;
          }
        }
      }
      .date {
        display: flex;
        font-size: 16px;
        color: #464646;
        padding: 0 10px;
        margin: 0;
        column-gap: 0.5rem;
      }
      .type {
        font-size: 14px;
        color: #777;
        width: 100%;
        padding: 10px;
        margin: 0;
        margin-top: 1rem;
        &.activity {
          background-color: #00c500;
          color: #fff;
          padding: 0.25rem 1rem;
          border-radius: 0px 0px 10px 10px;
          font-size: 0.8rem;
          text-transform: uppercase;
          display: flex;
          justify-content: flex-end;
        }
        &.collection {
          background-color: #00a0c5;
          color: #fff;
          padding: 0.25rem 1rem;
          border-radius: 0px 0px 10px 10px;
          font-size: 0.8rem;
          text-transform: uppercase;
          display: flex;
          justify-content: flex-end;
        }
      }
      .volunteer {
        font-size: 16px;
        color: #464646;
        padding: 0 10px;
        margin: 0;
        margin-top: 0.5rem;
      }
      &.passed {
        opacity: 0.5;
      }
    }
  }
  .colors {
    display: flex;
    gap: 5px;
    margin-bottom: 1rem;
    align-items: center;
    .color {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      display: flex;
    }
    .yellow {
      background-color: #e08a00;
    }
    .green {
      background-color: green;
      margin-left: 10px;
    }
  }
}
</style>