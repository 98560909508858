<template>
  <div class="dashboard">
    <div class="dash-content" v-if="currentWareHouse">
      <Topbar :pageTitle="currentWareHouse.name" />
      <div class="my-table">
        <div class="table-header flex-btn">
          <form class="table-filters">
            <fieldset>
              <legend>{{ $txt("Search")}}</legend>
              <div class="form-group">
                <input
                  type="text"
                  id="name"
                  class="form-control"
                  :placeholder="$txt('Search')"
                  v-model="searchQuery.search"
                />
              </div>
            </fieldset>
            <!-- Filter -->
            <div class="form-group">
              <button
                class="my-btn cw wd glow"
                type="submit"
                @click.prevent="getItems()"
              >
                {{ $txt("Filter")}}
              </button>
            </div>
          </form>
          <div class="stockBtns">
            <button
              class="my-btn cw wd in"
              @click="
                startStockin = true;
                currentAction = 'in';
              "
            >
              {{ $txt("Stock In")}}
            </button>
            <button
              class="my-btn cw wd out"
              @click="
                startStockin = true;
                currentAction = 'out';
              "
            >
              {{ $txt("Stock Out")}}
            </button>
          </div>
          <button class="my-btn cw wd glow" @click="startAdd = true">
            {{ $txt("Add Item")}}
          </button>
        </div>
        <table v-if="allData.length > 0">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ $txt("Name")}}</th>
              <th>{{ $txt("Quantity")}}</th>
              <th>{{ $txt("Unit")}}</th>
              <th>{{ $txt("Category")}}</th>
              <th>{{ $txt("Bar Code")}}</th>
              <th>{{ $txt("Action")}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in allData" :key="item.id">
              <td>
                {{ index + 1 }}
              </td>
              <td>{{ item.name }}</td>
              <td>{{ item.quantity }}</td>
              <td>{{ item.unit }}</td>
              <td>{{ item.category }}</td>
              <td>{{ item.barcode }}</td>
              <td>
                <a-dropdown>
                  <template #overlay>
                    <a-menu @click="handleMenuClick">
                      <a-menu-item
                        key="2"
                        @click="
                          selectedItem = item;
                          startEdit = true;
                        "
                      >
                        {{ $txt("Edit")}}
                      </a-menu-item>
                      <a-menu-item
                        key="3"
                        @click="
                          selectedItem = item;
                          startDelete = true;
                        "
                      >
                        {{ $txt("Delete")}}
                      </a-menu-item>
                    </a-menu>
                  </template>
                  <a-button>  {{ $txt("Actions")}} </a-button>
                </a-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button
            :class="{ disabled: currentPage === 1 }"
            @click="$previousPage()"
          >
            <label> {{ $txt("Previous")}}</label>
          </button>
          <p v-if="currentPage > 3">...</p>
          <button class="numbers" v-for="number in pagesNumber" :key="number">
            <label
              :class="{ active: currentPage === number }"
              for=""
              v-if="
                (number == 1 && currentPage <= 2) ||
                (currentPage + 2 >= pagesNumber && number >= pagesNumber - 2) ||
                (number >= currentPage - 2 && number <= currentPage + 2)
              "
              @click="$changePage(number)"
            >
              {{ number }}
            </label>
          </button>
          <p v-if="currentPage <= pagesNumber - 3">...</p>
          <button
            :class="{ disabled: currentPage === pagesNumber.length }"
            @click="$nextPage()"
          >
            <label> {{ $txt("Next")}}</label>
          </button>
        </div>
      </div>

      <Modal
        :title="$txt('Add Item')"
        :size="'lg'"
        v-model:open="startAdd"
        centered
        :okText="$txt('Save')"
        :cancelText="$txt('Cancel')"
        @ok="createItem()"
        width="600px"
      >
        <div class="form-group group-2">
          <div class="input-group">
            <label for="name"> {{ $txt("Product Name")}}</label>
            <a-input
              type="text"
              id="name"
              v-model:value="newItem.name"
              class="form-control"
              :placeholder="$txt('Product Name')"
            />
          </div>
          <div class="input-group">
            <label for="name"> {{ $txt("Quantity")}} <span> {{ $txt("(Numbers only)")}}</span></label>
            <a-input
              type="number"
              id="name"
              v-model:value="newItem.quantity"
              class="form-control"
              :placeholder="$txt('Price')"
            />
          </div>
        </div>
        <div class="form-group group-2">
          <div class="input-group">
            <label for="name"> {{ $txt("Unit")}}</label>
            <a-select
              v-model:value="newItem.unit"
              :placeholder="$txt('Select Unit')"
              style="width: 100%"
            >
              <a-select-option value="pounds">{{ $txt("Pounds")}}</a-select-option>
              <a-select-option value="kg">Kg</a-select-option>
              <a-select-option value="g">g</a-select-option>
              <a-select-option value="pcs">pcs</a-select-option>
              <a-select-option value="box">{{$txt("boxes")}}</a-select-option>
              <a-select-option value="sacks">{{$txt("sacks")}}</a-select-option>
              <a-select-option value="crates">{{$txt("crates")}}</a-select-option>
              <a-select-option value="littres">{{$txt("littres")}}</a-select-option>
              <a-select-option value="meters">{{$txt("meters")}}</a-select-option>
            </a-select>
          </div>
          <div class="input-group">
            <label for="name"> {{ $txt("Category")}}</label>
            <a-select
              v-model:value="newItem.category"
              placeholder="Select Category"
              style="width: 100%"
            >
              <a-select-option value="food">{{ $txt("Food")}}</a-select-option>
              <a-select-option value="drinks">{{$txt("Drinks")}}</a-select-option>
              <a-select-option value="clothes">{{ $txt("Clothes")}}</a-select-option>
              <a-select-option value="electronics">{{$txt("Electronics")}}</a-select-option>
              <a-select-option value="furniture">{{$txt("Furniture")}}</a-select-option>
              <a-select-option value="stationary">{{ $txt("Stationary")}}</a-select-option>
              <a-select-option value="tools">{{ $txt("Tools")}}</a-select-option>
              <a-select-option value="machinery">{{ $txt("Machinery")}}</a-select-option>
              <a-select-option value="other">{{ $txt("Other")}}</a-select-option>
            </a-select>
          </div>
        </div>
        <div class="form-group group-1">
          <div class="input-group">
            <label for="name"> {{ $txt("Description")}}</label>
            <a-textarea
              id="name"
              v-model:value="newItem.description"
              class="form-control"
              :placeholder="$txt('Description')"
            />
          </div>
        </div>
      </Modal>
      <!-- update modal -->
      <Modal
        :title="$txt('Edit Item')"
        :size="'lg'"
        v-model:open="startEdit"
        centered
        :okText="$txt('Update')"
        :cancelText="$txt('Cancel')"
        @ok="updateItem()"
        width="600px"
      >
        <div class="form-group group-3">
          <div class="input-group">
            <label for="name">{{ $txt("Bar Code")}}</label>
            <a-input
              type="text"
              id="name"
              v-model:value="selectedItem.barcode"
              class="form-control"
              :placeholder="$txt('Barcode')"
            />
          </div>
          <div class="input-group">
            <label for="name">{{ $txt("Product Name")}}</label>
            <a-input
              type="text"
              id="name"
              v-model:value="selectedItem.name"
              class="form-control"
              placeholder="Product Name"
            />
          </div>
          <div class="input-group">
            <label for="name">{{ $txt("Quantity")}} <span>{{ $txt("(Numbers only)")}}</span></label>
            <a-input
              type="number"
              id="name"
              v-model:value="selectedItem.quantity"
              class="form-control"
              :placeholder="$txt('Quantity')"
            />
          </div>
        </div>
        <div class="form-group group-2">
          <div class="input-group">
            <label for="name">{{ $txt("Unit")}}</label>
            <a-select
              v-model:value="selectedItem.unit"
              :placeholder="$txt('Select Unit')"
              style="width: 100%"
            >
              <a-select-option value="pounds">Pounds</a-select-option>
              <a-select-option value="kg">Kg</a-select-option>
              <a-select-option value="g">g</a-select-option>
              <a-select-option value="pcs">pcs</a-select-option>
              <a-select-option value="box">boxes</a-select-option>
              <a-select-option value="sacks">sacks</a-select-option>
              <a-select-option value="crates">crates</a-select-option>
              <a-select-option value="littres">littres</a-select-option>
              <a-select-option value="meters">meters</a-select-option>
            </a-select>
          </div>
          <div class="input-group">
            <label for="name">{{ $txt("Category")}}</label>
            <a-select
              v-model:value="selectedItem.category"
              :placeholder="$txt('Select Category')"
              style="width: 100%"
            >
              <a-select-option value="food">{{ $txt("Food")}}</a-select-option>
              <a-select-option value="drinks">{{ $txt("Drinks")}}</a-select-option>
              <a-select-option value="clothes">{{ $txt("Clothes")}}</a-select-option>
              <a-select-option value="electronics">{{ $txt("Electronics")}}</a-select-option>
              <a-select-option value="furniture">{{ $txt("Furniture")}}</a-select-option>
              <a-select-option value="stationary">{{ $txt("Stationary")}}</a-select-option>
              <a-select-option value="tools">{{ $txt("Tools")}}</a-select-option>
              <a-select-option value="machinery">{{ $txt("Machinery")}}</a-select-option>
              <a-select-option value="other">{{ $txt("Other")}}</a-select-option>
            </a-select>
          </div>
        </div>
      </Modal>
      <!-- delete modal -->
      <Modal
        :title="$txt('Delete Item')"
        :size="'sm'"
        v-model:open="startDelete"
        centered
        :okText="$txt('Delete')"
        :cancelText="$txt('Cancel')"
        @ok="deleteItem()"
        width="600px"
      >
        <p> {{ $txt("Are you sure you want to delete this Item?")}}</p>
      </Modal>
      <!-- stock in modal -->
      <Modal
        :title="$txt('Stock') + currentAction"
        :size="'lg'"
        v-model:open="startStockin"
        centered
        :okText="$txt('Stock')+ currentAction"
        @ok="stockIn()"
        width="600px"
      >
        <p class="stockin-header">{{ $txt("Create your stock") }}{{ currentAction }} {{ $txt("list") }}
        </p>
        <div class="stock-in-content">
          <div class="stock-in-form dflex align-center column-gap-2">
            <div class="stock-in-form-inputs">
              <div class="form-group group-2">
                <div class="input-group">
                  <a-select
                    v-model:value="newStockinItem.id"
                    :placeholder="$txt('Select Item')"
                    style="width: 100%"
                  >
                    <a-select-option
                      v-for="item in $theRestItems()"
                      :key="item.id"
                      :value="item.id"
                      >{{ item.name }}</a-select-option
                    >
                    <a-select-option :value="'other'">{{ $txt("Other") }}</a-select-option>
                  </a-select>
                </div>
                <div class="input-group">
                  <a-input
                    type="number"
                    v-model:value="newStockinItem.quantity"
                    class="form-control"
                    :placeholder="$txt('Quantity')"
                  />
                </div>
              </div>
              <div v-if="newStockinItem.id == 'other'">
                <div class="form-group group-2">
                  <div class="input-group">
                    <a-input
                      type="text"
                      v-model:value="newStockinItem.name"
                      class="form-control"
                      :placeholder="$txt('Item Name')"
                    />
                  </div>
                  <div class="input-group">
                    <a-select
                      v-model:value="newStockinItem.category"
                      :placeholder="$txt('Select Category')"
                      style="width: 100%"
                    >
                      <a-select-option value="food">{{$txt("Food")}}</a-select-option>
                      <a-select-option value="drinks">{{$txt("Drinks")}}</a-select-option>
                      <a-select-option value="clothes">{{ $txt("Clothes")}}</a-select-option>
                      <a-select-option value="electronics">{{$txt("Electronics")}}</a-select-option>
                      <a-select-option value="furniture">{{$txt("Furniture")}}</a-select-option>
                      <a-select-option value="stationary">{{$txt("Stationary")}}</a-select-option>
                      <a-select-option value="tools">{{$txt("Tools")}}</a-select-option>
                      <a-select-option value="machinery">{{$txt("Machinery")}}</a-select-option>
                      <a-select-option value="other">{{$txt("Other")}}</a-select-option>
                    </a-select>
                  </div>
                </div>
                <div class="form-group group-2">
                  <div class="input-group">
                    <a-select
                      v-model:value="newStockinItem.unit"
                      :placeholder="$txt('Select Unit')"
                      style="width: 100%"
                    >
                      <a-select-option value="pounds">Pounds</a-select-option>
                      <a-select-option value="kg">Kg</a-select-option>
                      <a-select-option value="g">g</a-select-option>
                      <a-select-option value="pcs">pcs</a-select-option>
                      <a-select-option value="box">boxes</a-select-option>
                      <a-select-option value="sacks">sacks</a-select-option>
                      <a-select-option value="crates">crates</a-select-option>
                      <a-select-option value="littres">littres</a-select-option>
                      <a-select-option value="meters">meters</a-select-option>
                    </a-select>
                  </div>
                  <div class="input-group">
                    <a-input
                      type="text"
                      v-model:value="newStockinItem.barcode"
                      class="form-control"
                      :placeholder="$txt('Barcode')"
                    />
                  </div>
                </div>

                <div class="form-group group-1">
                  <div class="input-group">
                    <a-textarea
                      v-model:value="newStockinItem.description"
                      class="form-control"
                      :placeholder="$txt('Description')"
                    />
                  </div>
                </div>
              </div>
            </div>
            <button type="primary" @click="addStockInItem()" class="my-btn cw">
              {{ $txt("Add")}}
            </button>
          </div>
          <table class="stockin-list">
            <thead>
              <tr>
                <th>{{ $txt("Item Name")}}</th>
                <th>{{ $txt("Quantity")}}</th>
                <th></th>
              </tr>
            </thead>
            <tr
              class="stockin-list-item"
              v-for="item in stockinList"
              :key="item.name"
            >
              <td>{{ item.name }}</td>
              <td>
                <a-input
                  type="number"
                  v-model:value="item.quantity"
                  class="form-control"
                  :placeholder="$txt('Stock In')"
                  @change="checkQuantity(item.id, item.quantity)"
                />
              </td>
              <td>
                <button
                  type="danger"
                  @click="removeStockInItem(item)"
                  class="my-btn cw"
                >
                  {{ $txt("Remove")}}
                </button>
              </td>
            </tr>
          </table>
        </div>
      </Modal>
      <!-- report modal -->
      <Modal
          :title="$txt('Stock ') + currentAction + $txt(' Report')"
          :size="'lg'"
        v-model:open="showReport"
        centered
        :okText="$txt('Close')"
        @ok="showReport = false"
        width="800px"
      >
        <div id="report-section" style="width: 100%; overflow-x: auto">
          <p
            v-if="reportItems && reportItems.items.length > 0"
            style="font-size: 14px; text-align: center; width: 100%"
          >
            {{ $txt("Stock")}} {{ currentAction }} {{ $txt("Report by")}} {{ reportItems.creator.name }}
            <i>({{ $date(reportItems.created_at) }})</i>
          </p>
          <table
            class="stockin-list"
            border="1"
            style="border-collapse: collapse; width: 100%"
          >
            <thead>
              <tr>
                <th style="padding: 5px 10px">{{ $txt("Item Name")}}</th>
                <th style="padding: 5px 10px">{{ $txt("Quantity")}}</th>
                <th style="padding: 5px 10px">{{ $txt("New Quantity")}}</th>
                <th style="padding: 5px 10px">{{ $txt("Unit")}}</th>
                <th style="padding: 5px 10px">{{ $txt("Category")}}</th>
                <th style="padding: 5px 10px">{{ $txt("Bar Code")}}</th>
                <th style="padding: 5px 10px">{{ $txt("Warehouse")}}</th>
                <th style="padding: 5px 10px">{{ $txt("Description")}}</th>
              </tr>
            </thead>
            <tr
              class="stockin-list-item"
              v-for="item in reportItems.items"
              :key="item.id"
            >
              <td style="padding: 5px 10px">
                {{ $stockItem(item.stock_id).name }}
              </td>
              <td style="padding: 5px 10px">{{ item.quantity }}</td>
              <td style="padding: 5px 10px">
                {{ $stockItem(item.stock_id).quantity }}
              </td>
              <td style="padding: 5px 10px">
                {{ $stockItem(item.stock_id).unit }}
              </td>
              <td style="padding: 5px 10px">
                {{ $stockItem(item.stock_id).category }}
              </td>
              <td style="padding: 5px 10px">
                {{ $stockItem(item.stock_id).barcode }}
              </td>
              <td style="padding: 5px 10px">
                {{ currentWareHouse.name }}
              </td>
              <td style="padding: 5px 10px">
                {{ $stockItem(item.stock_id).description }}
              </td>
            </tr>
          </table>
        </div>
        <button class="print-report" @click="printReport()">
          {{$txt("Print Report")}}
        </button>
      </Modal>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Topbar from "@/views/admin/components/Topbar.vue";
import { Modal } from "ant-design-vue";
export default {
  components: {
    Topbar,
    Modal,
  },
  data() {
    return {
      allItemsCount: 1,
      itemsPerpage: 10,
      pagesNumber: 1,
      currentPage: 1,
      allData: [],
      searchQuery: {
        search: "",
        created_by: "all",
      },
      startAdd: false,
      startEdit: false,
      startDelete: false,
      selectedItem: {},
      newItem: {
        name: "",
        owner: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        postal_code: "",
      },
      stockinList: [],
      newStockinItem: {
        id: null,
        name: "",
        quantity: 0,
        category: null,
        unit: null,
        barcode: "",
        description: "",
        price: 0,
        type: "",
      },
      warehouse_id: null,
      startStockin: false,
      history_id: null,
      showReport: false,
      reportItems: [],
      currentAction: "in",
      currentWareHouse: null,
    };
  },
  methods: {
    getWarehouse() {
      this.$store
        .dispatch("GET_WAREHOUSE_BY_ID", {
          token: this.$getAdminToken(),
          data: {
            id: this.warehouse_id,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.currentWareHouse = res.data;
            } else {
              toast.error(res.data.message);
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_STOCKS_BY_WAREHOUSE", {
          token: this.$getAdminToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
            warehouse_id: this.warehouse_id,
            searchQuery: this.searchQuery,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.allData = res.data.data;
              this.allItemsCount = res.data.total;
              this.pagesNumber = Math.ceil(
                this.allItemsCount / this.itemsPerpage
              );
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    createItem() {
      // checking if the user added a name
      if (this.newItem.name) {
        // start the loading
        this.$startLoader();
        this.newItem.warehouse_id = this.warehouse_id;
        this.$store
          .dispatch("ADD_STOCK_ITEM", {
            data: {
              ...this.newItem,
            },
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startAdd = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })

          .catch((err) => {
            if (err.response.status === 401) {
              toast.error("Session expired, please login again.");
              this.$router.push({ name: "login" });
            } else {
              toast.error("Something went wrong, please try again later.");
            }
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    updateItem() {
      if (this.selectedItem.name) {
        this.$startLoader();
        this.$store
          .dispatch("UPDATE_STOCK_ITEM", {
            data: {
              ...this.selectedItem,
            },
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startEdit = false;
                this.$stopLoader();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })

          .catch((err) => {
            if (err.response.status === 401) {
              toast.error("Session expired, please login again.");
              this.$router.push({ name: "login" });
            } else {
              toast.error("Something went wrong, please try again later.");
            }
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_STOCK_ITEM", {
          data: {
            ...this.selectedItem,
          },
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startDelete = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    refreshNewStockinItem() {
      this.newStockinItem = {
        id: null,
        name: "",
        quantity: 0,
        category: "",
        unit: "",
        barcode: "",
        description: "",
        price: 0,
        type: "",
      };
    },
    ListContains(id) {
      return this.stockinList.find((item) => item.id === id);
    },
    addStockInItem() {
      if (this.currentAction == "out" && this.newStockinItem.id !== "other") {
        if (
          !this.checkQuantity(
            this.newStockinItem.id,
            this.newStockinItem.quantity
          )
        ) {
          return;
        }
      }
      if (this.newStockinItem.id == null || this.newStockinItem.id == "") {
        toast.error("Please select an item");
        return;
      }
      if (this.newStockinItem.quantity <= 0) {
        toast.error("Quantity should be greater than 0");
        return;
      }
      if (this.newStockinItem.id !== "other") {
        if (this.ListContains(this.newStockinItem.id)) {
          this.stockinList.find(
            (item) => item.id === this.newStockinItem.id
          ).quantity += this.newStockinItem.quantity;
        } else {
          let newItem = {
            ...this.allData.find((item) => item.id === this.newStockinItem.id),
          };
          newItem.quantity = this.newStockinItem.quantity;
          this.stockinList.push(newItem);
        }
        this.refreshNewStockinItem();
      } else {
        this.newStockinItem.id = "custom" + this.stockinList.length;
        this.stockinList.push(this.newStockinItem);
        this.refreshNewStockinItem();
      }
    },
    removeStockInItem(item) {
      this.stockinList = this.stockinList.filter((i) => i.id !== item.id);
    },
    stockIn() {
      if (this.stockinList.length > 0) {
        let ACTION = this.currentAction == "in" ? "STOCK_IN" : "STOCK_OUT";
        this.$startLoader();
        this.$store
          .dispatch(ACTION, {
            data: {
              items: this.stockinList,
              warehouse_id: this.warehouse_id,
            },
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(res.data.message);
                this.getItems();
                this.startStockin = false;
                this.stockinList = [];
                this.history_id = res.data.report;
                // open the report in a new tab
                this.$stopLoader();
                this.getReport();
              } else {
                toast.error(res.data.message);
                this.$stopLoader();
              }
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              toast.error("Session expired, please login again.");
              this.$router.push({ name: "login" });
            } else {
              toast.error("Something went wrong, please try again later.");
            }
            this.$stopLoader();
          });
      } else {
        toast.error("Please add items to stock in");
      }
    },
    getReport() {
      this.$startLoader();
      this.$store
        .dispatch("GET_STOCK_REPORT_BY_ID", {
          token: this.$getAdminToken(),
          data: {
            id: this.history_id,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            let resdata = res.data;
            resdata.items = JSON.parse(res.data.items);
            this.reportItems = resdata;
            this.showReport = true;
            this.$stopLoader();
          } else {
            toast.error(res.data.message);
            this.$stopLoader();
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    $stockItem(stock_id) {
      return this.allData.find((item) => item.id === stock_id);
    },
    printReport() {
      const printContents = document.getElementById("report-section").innerHTML;
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },
    checkQuantity(item_id, quantity) {
      let item = this.allData.find((item) => item.id === item_id);
      if (item.quantity < quantity) {
        toast.error("Available quantity  is " + item.quantity);
        // this.stockinList.find((i) => i.id === item_id).quantity = item.quantity;
        return false;
      }
      return true;
    },
    $theRestItems() {
      return this.allData.filter(
        (item) => !this.stockinList.find((i) => i.id === item.id)
      );
    },
  },
  mounted() {
    this.warehouse_id = this.$route.params.id;
    if (this.warehouse_id) {
      this.searchQuery.warehouse_id = this.warehouse_id;
      this.getWarehouse();
      this.getItems();
    } else {
      this.$router.push({ name: "AdminWarehouses" });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  background: #fafafa;
  overflow-y: hidden;
  .page-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .dash-content {
    // padding: 0 20px;
    height: fit-content;
    // overflow-y: auto;
    width: 100%;
  }
}
.stockBtns {
  .in {
    background: rgba(0, 132, 255, 0.589);
  }
  .out {
    background: rgba(255, 136, 0, 0.589);
    color: black;
  }
}
.stock-in-content {
  input {
    border: 1px solid #e2e2e2;
    padding: 5px 5px;
    border-radius: 5px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    thead {
      background: #f5f5f5;
      th {
        padding: 10px;
        text-align: left;
      }
    }
    tr {
      td {
        padding: 10px;
        border-bottom: 1px solid #e2e2e2;
        input {
          width: 90%;
        }
        button {
          padding: 5px 10px !important;
          border-radius: 5px;
          background: #a30000;
          color: white;
          border: none;
          cursor: pointer;
          margin: 0 10px;
        }
      }
    }
  }
}
#report-section {
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    thead {
      background: #f5f5f5;
      th {
        padding: 10px;
        text-align: left;
      }
    }
    tr {
      td {
        padding: 10px;
        border-bottom: 1px solid #e2e2e2;
      }
    }
  }
}
.print-report {
  padding: 10px 20px;
  border-radius: 5px;
  background: #1d2088;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}
</style>