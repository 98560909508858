<template>
  <div class="dashboard">
    <div class="dash-content">
      <Topbar :pageTitle="$txt('Volunteers')"  />
      <div class="my-table">
        <div class="table-header flex-btn">
          <form class="table-filters">
            <fieldset>
              <legend>{{ $txt("Search")}}</legend>
              <div class="form-group">
                <input
                  type="text"
                  id="name"
                  class="form-control"
                  placeholder="Search"
                  v-model="searchQuery.search"
                />
              </div>
            </fieldset>
            <!-- Filter -->
            <button
              class="my-btn cw wd glow"
              type="submit"
              @click.prevent="getItems()"
            >
              {{ $txt("Filter")}}
            </button>
          </form>
        </div>
        <table v-if="allData.length > 0">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ $txt("Names")}}</th>
              <th>{{ $txt("Email")}}</th>
              <th>{{ $txt("Phone")}}</th>
              <th>{{ $txt("Address")}}</th>
              <th>{{ $txt("City")}}</th>
              <th>{{ $txt("Postal Code")}}</th>
              <th>{{ $txt("Status")}}</th>
              <th>{{ $txt("Action")}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in allData" :key="item.id">
              <td>
                {{ index + 1 }}
              </td>
              <td>{{ item.name }}</td>
              <td>{{ item.email }}</td>
              <td>{{ item.phone }}</td>
              <td>{{ item.address }}</td>
              <td>{{ item.city }}</td>
              <td>{{ item.postal_code }}</td>
              <td>
                <span :class="`chip ${item.status}`">{{
                  item.status == "inProgress" ? "In Progress" : $txt(item.status)
                }}</span>
              </td>

              <td>
                <a-dropdown>
                  <template #overlay>
                    <a-menu @click="handleMenuClick">
                      <a-menu-item
                        key="1"
                        @click="
                          selectedItem = item;
                          startSchedule = true;
                          getSchedules();
                        "
                      >
                        {{ $txt("View Schedule")}}
                      </a-menu-item>
                      <a-menu-item
                        key="2"
                        @click="
                          selectedItem = item;
                          startEdit = true;
                        "
                      >
                        {{ $txt("Edit")}}
                      </a-menu-item>
                      <a-menu-item
                        key="3"
                        @click="
                          selectedItem = item;
                          startDelete = true;
                        "
                      >
                        {{ $txt("Delete")}}
                      </a-menu-item>
                      <a-menu-item
                        key="4"
                        @click="
                          selectedItem = item;
                          startAvailability = true;
                        "
                      >
                        {{ $txt("Availability")}}
                      </a-menu-item>
                    </a-menu>
                  </template>
                  <a-button>
                    {{ $txt("Actions")}}
                    <a-icon type="down" />
                  </a-button>
                </a-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button
            :class="{ disabled: currentPage === 1 }"
            @click="$previousPage()"
          >
            <label>{{ $txt("Previous")}}</label>
          </button>
          <p v-if="currentPage > 3">...</p>
          <button class="numbers" v-for="number in pagesNumber" :key="number">
            <label
              :class="{ active: currentPage === number }"
              for=""
              v-if="
                (number == 1 && currentPage <= 2) ||
                (currentPage + 2 >= pagesNumber && number >= pagesNumber - 2) ||
                (number >= currentPage - 2 && number <= currentPage + 2)
              "
              @click="$changePage(number)"
            >
              {{ number }}
            </label>
          </button>
          <p v-if="currentPage <= pagesNumber - 3">...</p>
          <button
            :class="{ disabled: currentPage === pagesNumber.length }"
            @click="$nextPage()"
          >
            <label>{{ $txt("Next")}}</label>
          </button>
        </div>
      </div>

      <Modal
        :title="$txt('Edit Volunteer')"
        :size="'lg'"
        v-model:open="startEdit"
        centered
        :okText="$txt('Save')"
        :cancelText="$txt('Cancel')"
        @ok="updateItem()"
        @cancel="selectedItem = { ...newItem }"
        width="600px"
      >
        <div class="form-group group-3">
          <div class="input-group">
            <label for="name">{{ $txt("Names")}}</label>
            <a-input
              type="text"
              id="name"
              v-model:value="selectedItem.name"
              class="form-control"
              :placeholder="$txt('Names')"
            />
          </div>
          <div class="input-group">
            <label for="name">{{ $txt("Email")}}</label>
            <a-input
              type="email"
              id="name"
              v-model:value="selectedItem.email"
              class="form-control"
              :placeholder="$txt('Email')"
            />
          </div>
          <div class="input-group">
            <label for="name">{{ $txt("Phone")}}</label>
            <a-input
              type="tel"
              id="name"
              v-model:value="selectedItem.phone"
              class="form-control"
              :placeholder="$txt('Phone')"
            />
          </div>
        </div>
        <div class="form-group group-2">
          <div class="input-group">
            <label for="name">{{ $txt("Address")}}</label>
            <a-input
              type="text"
              id="name"
              v-model:value="selectedItem.address"
              class="form-control"
              :placeholder="$txt('address')"
            />
          </div>
          <div class="input-group">
            <label for="name">{{ $txt("City")}}</label>
            <a-input
              type="email"
              id="name"
              v-model:value="selectedItem.city"
              class="form-control"
              :placeholder="$txt('City')"
            />
          </div>
        </div>
        <div class="form-group group-2">
          <div class="input-group">
            <label for="name">{{ $txt("Postal Code")}}</label>
            <a-input
              type="tel"
              id="name"
              v-model:value="selectedItem.postal_code"
              class="form-control"
              :placeholder="$txt('Postal Code')"
            />
          </div>
          <div class="input-group">
            <label for="name">{{ $txt("Status")}}</label>
            <a-select
              v-model:value="selectedItem.status"
              style="width: 100%"
              :placeholder="$txt('Select Status')"
            >
              <a-select-option value="available">{{$txt("available")}}</a-select-option>
              <a-select-option value="unavailable">{{ $txt("Unavailable")}}</a-select-option>
            </a-select>
          </div>
        </div>
        <div class="form-group group-1">
          <div class="input-group">
            <label for="name">{{ $txt("Skills")}}</label>
            <NWMultiValue
              :values="selectedItem.skills"
              :valuetype="'single'"
              :placeholder="$txt('Skill name')"
              :btnText="$txt('+ Add Skill')"
              @valueChange="selectedItem.skills = $event"
            />
          </div>
        </div>
        <div class="form-group group-1">
          <div class="input-group">
            <label for="name">{{ $txt("Availability")}}</label>
            <DatesMultiValue
              :values="selectedItem.availability"
              @valueChange="selectedItem.availability = $event"
            />
          </div>
        </div>
      </Modal>
      <Modal
        :title="$txt('Delete Volunteer')"
        :size="'sm'"
        v-model:open="startDelete"
        centered
        :okText="$txt('Delete')"
        :cancelText="$txt('Cancel')"
        @ok="deleteItem()"
        width="400px"
      >
        <p>{{ $txt("Are you sure you want to delete this volunteer?")}}</p>
      </Modal>
      <Modal
        :title="selectedItem.name + $txt('\'s Availability')"
        :size="'sm'"
        v-model:open="startAvailability"
        centered
        :okText="$txt('Delete')"
        :cancelText="$txt('Cancel')"
        @ok="deleteItem()"
        width="400px"
        :footer="null"
      >
        <div class="availability">
          <div
            class="availability-item"
            v-for="item in selectedItem.availability"
            :key="item.day"
          >
            <div class="dflex align-center column-gap-05">
                <p><b>{{ $txt(item.day) }}:</b></p>
                <p>{{ $txt('From') }} {{ item.start_time }}  {{ $txt('To') }} {{ item.end_time }}</p>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
          :title="selectedItem.name + $txt('\'s Schedule')"

          :size="'sm'"
        v-model:open="startSchedule"
        centered
        width="80vh"
        height="80vh"
        :footer="null"
      >
        <div class="Schedule">
          <Schedule
            :schedules="schedules"
            @TodayClicked="
              activeSchedule = 'Today';
              getSchedules();
            "
            @TomorrowClicked="
              activeSchedule = 'Tomorrow';
              getSchedules();
            "
            @ThisWeekClicked="
              activeSchedule = 'This Week';
              getSchedules();
            "
            @ThisMonthClicked="
              activeSchedule = 'This Month';
              getSchedules();
            "
            @customDateClicked="
              scheduleDate = $event;
              activeSchedule = 'Custom Date';
              getSchedules();
            "
          />
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Topbar from "@/views/admin/components/Topbar.vue";
import { Modal } from "ant-design-vue";
export default {
  components: {
    Topbar,
    Modal,
  },
  data() {
    return {
      allItemsCount: 1,
      itemsPerpage: 10,
      pagesNumber: 1,
      currentPage: 1,
      allData: [],
      searchQuery: {
        search: "",
      },
      startAdd: false,
      startEdit: false,
      startDelete: false,
      startAssign: false,
      startAvailability: false,
      startSchedule: false,
      selectedItem: {
        name: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        postal_code: "",
        status: "",
        skills: [],
        availability: [],
      },
      newItem: {
        name: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        postal_code: "",
        status: "",
        skills: [],
        availability: [],
      },
      volunteers: [],
      merchants: [],
      viewItems: false,
      schedules: [],
      activeSchedule: "Today",
      scheduleDate: "",
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_VOLUNTEERS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
            searchQuery: this.searchQuery,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.allData = res.data.data;
              this.allItemsCount = res.data.total;
              this.pagesNumber = Math.ceil(
                this.allItemsCount / this.itemsPerpage
              );
              this.$stopLoader();
              this.allData.forEach((item) => {
                if (!item.skills) {
                  item.skills = [];
                }
                if (!item.availability) {
                  item.availability = [];
                }
              });
            } else {
              toast.error(this.$txt(res.data.message));
              this.$stopLoader();
            }
          }
        })

        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    updateItem() {
      this.$startLoader();
      this.$store
        .dispatch("UPDATE_VOLUNTEER", {
          data: {
            ...this.selectedItem,
          },
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(this.$txt(res.data.message));
              this.getItems();
              this.startEdit = false;
              this.$stopLoader();
            } else {
              toast.error(this.$txt(res.data.message));
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_VOLUNTEER", {
          data: {
            ...this.selectedItem,
          },
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(this.$txt(res.data.message));
              this.getItems();
              this.startDelete = false;
              this.$stopLoader();
            } else {
              toast.error(this.$txt(res.data.message));
              this.$stopLoader();
            }
          }
        })

        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },

    getSchedules() {
      this.$startLoader();
      let action = "";
      switch (this.activeSchedule) {
        case "Today":
          action = "GET_TODAY_SCHEDULE";
          break;
        case "Tomorrow":
          action = "GET_TOMORROW_SCHEDULE";
          break;
        case "This Week":
          action = "GET_THIS_WEEK_SCHEDULE";
          break;
        case "This Month":
          action = "GET_THIS_MONTH_SCHEDULE";
          break;
        default:
          action = "GET_SCHEDULE_BY_DATE";
          break;
      }
      this.$store
        .dispatch(action, {
          token: this.$getAdminToken(),
          data: {
            volunteer_id: this.selectedItem.id,
            date: this.scheduleDate,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.schedules = res.data;
              this.startSchedule = true;
            } else {
              toast.error(res.data.message);
            }
          }
          this.$stopLoader();
        })

        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  background: #fafafa;
  overflow-y: hidden;
  .page-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .dash-content {
    // padding: 0 20px;
    height: fit-content;
    // overflow-y: auto;
    width: 100%;
  }
}
</style>