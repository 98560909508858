import { createStore } from "vuex";
import { UPLOAD_URL, BACKEND_URL } from "./variables.js";
import merchants from "./modules/merchants.js";
import collections from "./modules/collections.js";
import volunteers from "./modules/volunteers.js";
import activities from "./modules/activities.js";
import schedule from "./modules/schedule.js";
import warehouses from "./modules/warehouses.js";
import stock from "./modules/stock.js";
import admins from "./modules/admins.js";
import languages from "./modules/languages.js";
import others from "./modules/others.js";
import members from "./modules/members.js";
import memberRequests from "./modules/memberRequests.js";
import cars from "./modules/cars.js";
import requestTypes from "./modules/requestType.js";

export default createStore({
  state: {
    startLoader: false,
    showSANav: true,
    activePage: null,
    apiUploadUrl: UPLOAD_URL,
    backendUrl: BACKEND_URL,
    languages: ["french", "english", "Spanish"],
    translations: [],
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    merchants,
    collections,
    volunteers,
    activities,
    schedule,
    warehouses,
    stock,
    admins,
    languages,
    others,
    members,
    memberRequests,
    cars,
    requestTypes,
  },
});
