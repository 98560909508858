<template>
  <div class="navbar">
    <router-link to="/">{{ $txt("Home") }}</router-link>
    <router-link to="/about">{{ $txt("About us") }}</router-link>
    <router-link to="/contact">{{ $txt("Contact") }}</router-link>
    <router-link :to="{ name: 'MemberLogin' }" v-if="!$loggedMember() && !$loggedVolunteer()">{{ $txt("Member Login") }}</router-link>
    <router-link :to="{ name: 'VolunteerDashboard' }" v-if="$loggedMember()">{{ $txt("Member Dashboard") }}</router-link>
    <router-link to="/login" v-if="!$loggedVolunteer() && !$loggedMember()">{{ $txt("Volunteer Login") }}</router-link>
    <router-link to="/sign" v-if="!$loggedVolunteer() && !$loggedMember()">{{ $txt("Sign-Up") }}</router-link>
    <router-link :to="{ name: 'VolunteerDashboard' }" v-if="$loggedVolunteer() && !$loggedMember()">{{ $txt("Volunteer Dashboard") }}</router-link>
    <div class="lang-switch">
      <ChangeLang :showDrop="showDrop" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      showDrop: false,
    };
  },
};
</script>

<style scoped>
.navbar {
  display: flex;
  gap: 20px;
  align-items: center;
}

.lang-switch {
  margin-left: 20px;
}
</style>
