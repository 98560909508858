<template>
  <div class="volunteerDashboard">
    <Navbar />
    <div class="volunteer-dashboard-body">
      <VolunteerSidebar />
      <div class="volunteer-content">
        <div class="my-table">
          <div class="table-header flex-btn">
            <form class="table-filters">
              <fieldset>
                <legend>{{ $txt("Volunteer")}}</legend>
                <div class="form-group">
                  <select
                    id="wing"
                    class="form-control"
                    v-model="searchQuery.volunteer_id"
                  >
                    <option value="">{{ $txt("All")}}</option>
                    <option
                      v-for="volunteer in filterVolunteer()"
                      :key="volunteer.id"
                      :value="volunteer.id"
                    >
                      {{ volunteer.name }}
                    </option>
                  </select>
                </div>
              </fieldset>
              <fieldset>
                <legend>{{ $txt("Search")}}</legend>
                <div class="form-group">
                  <input
                    type="text"
                    id="name"
                    class="form-control"
                    :placeholder="$txt('Search')"
                    v-model="searchQuery.search"
                  />
                </div>
              </fieldset>
              <!-- Filter -->
              <button
                class="my-btn cw wd glow"
                type="submit"
                @click.prevent="getItems()"
              >
                {{ $txt("Filter")}}
              </button>
            </form>
          </div>
          <table v-if="allData.length > 0">
            <thead>
              <tr>
                <th>#</th>
                <th>{{ $txt("Description")}}</th>
                <th>{{ $txt("Volunteer")}}</th>
                <th>{{ $txt("Address")}}</th>
                <th>{{ $txt("Time")}}</th>
                <th>{{ $txt("Items")}}</th>
                <th>{{ $txt("Status")}}</th>
                <th>{{ $txt("Action")}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in allData" :key="item.id">
                <td>
                  {{ index + 1 }}
                </td>
                <td>
                  <p class="ellipsis">{{ item.description }}</p>
                </td>
                <td>
                  <div class="table-list" v-if="item.volunteer">
                    <div class="table-list-item">
                      <b>{{ $txt("Name")}}: </b>
                      <span>{{ item.volunteer.name }}</span>
                    </div>
                    <div class="table-list-item">
                      <b>{{ $txt("Email")}}: </b>
                      <span>{{ item.volunteer.email }}</span>
                    </div>
                    <div class="table-list-item">
                      <b>{{ $txt("Phone")}}: </b>
                      <span>{{ item.volunteer.phone }}</span>
                    </div>
                  </div>
                  <span v-else>{{ $txt("Not assigned")}}</span>
                </td>

                <td>{{ item.merchant ? item.merchant.address : "-" }}</td>
                <td>
                  <div class="table-list">
                    <div class="table-list-item">
                      <b>{{ $txt("Date")}}: </b>
                      <span>{{ item.execution_date }}</span>
                    </div>
                    <div class="table-list-item">
                      <b>{{ $txt("Time")}}: </b>
                      <span>{{ item.execution_time }}</span>
                    </div>
                  </div>
                </td>
                <td>{{ item.items.length }}</td>
                <td>
                  <span :class="`chip ${item.status}`">{{
                      item.status == "inProgress" ? $txt("In Progress") : $txt(item.status)
                  }}</span>
                </td>
                <td>
                  <a-dropdown>
                    <template #overlay>
                      <a-menu @click="handleMenuClick">
                        <a-menu-item
                          key="1"
                          @click="
                            selectedItem = item;
                            startRequest = true;
                          "
                          v-if="!item.volunteer"
                        >
                          {{ $txt("Request Mission")}}
                        </a-menu-item>
                        <a-menu-item
                          key="4"
                          @click="
                            selectedItem = item;
                            startShowItems = true;
                          "
                        >
                          {{ $txt("Items list")}}
                        </a-menu-item>
                      </a-menu>
                    </template>
                    <a-button>
                      {{ $txt("Actions")}}
                      <a-icon type="down" />
                    </a-button>
                  </a-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="pagination">
            <button
              :class="{ disabled: currentPage === 1 }"
              @click="$previousPage()"
            >
              <label>{{ $txt("Previous")}}</label>
            </button>
            <p v-if="currentPage > 3">...</p>
            <button class="numbers" v-for="number in pagesNumber" :key="number">
              <label
                :class="{ active: currentPage === number }"
                for=""
                v-if="
                  (number == 1 && currentPage <= 2) ||
                  (currentPage + 2 >= pagesNumber &&
                    number >= pagesNumber - 2) ||
                  (number >= currentPage - 2 && number <= currentPage + 2)
                "
                @click="$changePage(number)"
              >
                {{ number }}
              </label>
            </button>
            <p v-if="currentPage <= pagesNumber - 3">...</p>
            <button
              :class="{ disabled: currentPage === pagesNumber.length }"
              @click="$nextPage()"
            >
              <label>{{ $txt("Next")}}</label>
            </button>
          </div>
        </div>

        <Modal
          :title="$txt('Mission Items')"
          :size="'sm'"
          v-model:open="startShowItems"
          centered
          :okText="$txt('Delete')"
          :cancelText="$txt('Cancel')"
          @ok="deleteItem()"
          width="600px"
          :footer="null"
        >
          <div id="report-section">
            <div class="items-item">
              <h3>
                {{ $txt("Items to be used in a Mission on")}}
                {{ selectedItem.execution_date }}
                {{ $ampm(selectedItem.execution_time) }}
              </h3>
              <div class="assigned">
                <b>{{ $txt("Volunteer")}}:</b>
                <span
                  >&nbsp;{{
                    selectedItem.volunteer ? selectedItem.volunteer.name : "-"
                  }}</span
                >
              </div>
              <p style="margin-top: 10px">{{ selectedItem.description }}</p>
              <table border="1" style="border-collapse: collapse; width: 100%">
                <thead>
                  <tr>
                    <th style="padding: 10px">{{ $txt("Item")}}</th>
                    <th style="padding: 10px">{{ $txt("Quantity")}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in selectedItem.items" :key="index">
                    <td style="padding: 10px">{{ item.item_name }}</td>
                    <td style="padding: 10px">
                      {{ item.quantity }} {{ item.unit }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="pritn-btn mt-10">
            <button class="my-btn cw glow" @click="printList()">
              <i class="fa fa-print"></i>
              {{ $txt("Print")}}
            </button>
          </div>
        </Modal>
        <Modal
          :title="$txt('Request Mission')"
          :size="'sm'"
          v-model:open="startRequest"
          centered
          :okText="$txt('Send Request')"
          :cancelText="$txt('Cancel')"
          @ok="sendRequest()"
          width="600px"
        >
          <div class="form-group">
            <label for="date">{{ $txt("Date to be executed")}}</label>
            <input
              type="date"
              id="date"
              class="form-control"
              v-model="requestData.date"
            />
          </div>
          <div class="form-group">
            <label for="time">{{ $txt("Time to start")}}</label>
            <input
              type="time"
              id="time"
              class="form-control"
              v-model="requestData.time"
            />
          </div>
          <div class="form-group">
            <label for="description">{{ $txt("Description")}}</label>
            <textarea
              id="description"
              class="form-control"
              v-model="requestData.description"
            ></textarea>
          </div>
        </Modal>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import VolunteerSidebar from "@/views/VolunteerDashboard/components/VolunteerSidebar.vue";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { Modal } from "ant-design-vue";
export default {
  name: "AboutView",
  components: {
    Navbar,
    VolunteerSidebar,
    Modal,
  },
  data() {
    return {
      schedules: [],
      activeSchedule: "Today",
      scheduleDate: new Date().toISOString().split("T")[0],
      allItemsCount: 1,
      itemsPerpage: 10,
      pagesNumber: 1,
      currentPage: 1,
      allData: [],
      searchQuery: {
        search: "",
        volunteer_id: "",
      },
      volunteers: [],
      selectedItem: {},
      startShowItems: false,
      startRequest: false,
      requestData: {
        date: "",
        time: "",
        description: "",
      },
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ACTIVITIES", {
          token: this.$getVolunteerToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
            searchQuery: this.searchQuery,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.allData = res.data.data;
              this.allItemsCount = res.data.total;
              this.pagesNumber = Math.ceil(
                this.allItemsCount / this.itemsPerpage
              );
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    getVolunteers() {
      this.$store
        .dispatch("GET_VOLUNTEERS", {
          token: this.$getVolunteerToken(),
          data: {
            paginate: 1000,
            page: this.currentPage,
            searchQuery: this.searchQuery,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.volunteers = res.data.data;
            } else {
              toast.error(res.data.message);
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    printList() {
      const printContents = document.getElementById("report-section").innerHTML;
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },
    filterVolunteer() {
      return this.volunteers.filter((volunteer) => {
        return volunteer.id === this.$getVolunteerId();
      });
    },
    sendRequest() {
      this.$startLoader();
      this.$store
        .dispatch("REQUEST_ACTIVITY", {
          data: {
            volunteer_id: this.$getVolunteerId(),
            activity_id: this.selectedItem.id,
            activity_type: "activity",
            date: this.requestData.date,
            time: this.requestData.time,
            description: this.requestData.description,
          },
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success("Request sent successfully.");
              this.getItems();
              this.startRequest = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getItems();
    this.getVolunteers();
  },
};
</script>

<style scoped lang="scss">
.schedule {
  padding: 2rem;
}
</style>
