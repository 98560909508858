<template>
  <div class="NWMultiValue">
    <div
      class="multivalue-item"
      v-for="(item, index) in newValues.length"
      :key="index"
    >
      <div class="multivalue-item-inputs">
        <a-select
          type="text"
          v-model:value="newValues[index].day"
          placeholder="Day"
          @change="dataChanges"
        >
          <a-select-option v-for="day in $getDays()" :key="day" :value="day">{{
              $txt(day)
          }}</a-select-option>
        </a-select>
        <a-input
          type="time"
          v-model:value="newValues[index].start_time"
          placeholder="Start Time"
          @change="dataChanges"
        />
        <a-input
          type="time"
          v-model:value="newValues[index].end_time"
          placeholder="Start Time"
          @change="dataChanges"
        />
        <a-button class="danger" @click.prevent="removeValue(index)"
          ><i class="fa-solid fa-minus"></i
        ></a-button>
      </div>
    </div>
    <a-button class="ant-button" @click.prevent="addValue()"
      >+ {{$txt("Add Day")}}</a-button
    >
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {
  name: "NWMultiValue",
  props: {
    values: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      newValues: [],
      days: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
    };
  },
  methods: {
    addValue() {
      this.newValues.push({
        day: "",
        start_time: "",
        end_time: "",
      });
      this.dataChanges();
    },
    removeValue(index) {
      this.newValues.splice(index, 1);
      this.dataChanges();
    },
    singleaddValue() {
      this.newValues.push("");
      this.dataChanges();
    },
    singleremoveValue(index) {
      this.newValues.splice(index, 1);
      this.dataChanges();
    },
    dataChanges() {

    if (this.compareTimes()) {
console.log(this.newValues)
      this.$emit("valueChange", this.newValues);
    }
    },
    $getDays() {
      return this.days.filter((day) => {
        return !this.newValues.some((value) => value.day === day);
      });
    },
    compareTimes() {


      this.newValues.forEach((obj, index) => {
      let startTime = new Date(`1970-01-01T${obj.start_time}:00Z`);
      let endTime = new Date(`1970-01-01T${obj.end_time}:00Z`);



      if (startTime > endTime) {
        obj.end_time = ""; // Set end_time to empty string
        toast.error("change yours hours");
        return false
      }

    });
      return true
}
  },
  mounted() {
    this.newValues = this.values;
  },
  watch: {
    values: {
      handler() {
        if (this.values && this.values != null) {
          this.newValues = this.values;
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.NWMultiValue {
  .multivalue-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    .multivalue-item-inputs {
      display: grid;
      grid-template-columns: 3fr 3fr 3fr 1fr;
      gap: 1rem;
      width: 100%;
      margin-bottom: 1rem;
      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        .ant-select {
          width: 100%;
        }
        .ant-input {
          width: 100%;
        }
      }
      a-input {
        width: 100%;
      }
    }
  }
  .ant-button {
    background: #203529;
    border: none;
    color: white;
    &:hover {
      background: #203529;
    }
  }
  .danger {
    background: #b9171f7c;
    border: none;
    color: white;
    line-height: 0;
    &:hover {
      background: #b9171f;
    }
  }
}
</style>