<template>
  <div class="ChangeLang">
    <a-dropdown :trigger="['click']">
      <a class="ant-dropdown-link" @click.prevent>
        <div class="lang">
          <span
            >{{$txt("Language:")}} <b>{{$txt(lang) }}</b></span
          >
        </div>
      </a>
      <template #overlay>
        <a-menu>
          <a-menu-item
            v-for="language in without($store.state.languages)"
            :key="language"
            @click="choose(language)"
          >
            <span>{{ $txt(language) }}</span>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </div>
</template>

<script>
export default {
  name: "ChangeLang",
  data() {
    return {
      lang: "en",
      toggle: false,
    };
  },
  props: {
    showDrop: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    choose(lang) {
      this.lang = lang;
      this.toggle = false;
      this.$changeLang(lang);
    },
    without(arr) {
      return arr.filter((item) => item != this.lang);
    },
  },
  mounted() {
    this.lang = localStorage.getItem("lang");
  },
  computed: {},
  // watch showDrop
  watch: {},
};
</script>
<style lang="scss" scoped>
.ChangeLang {
  display: flex;
  align-items: center;
  position: relative;
}

.langauges {
  span {
    color: #333;
  }
}

.lang {
  display: flex;
  align-items: center;
  margin: 0 10px;
  cursor: pointer;
  background: #eee;
  padding: 5px;
  font-size: 0.8rem;
  img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}

.language-toggle {
  position: absolute;
  top: 30px;
  right: 0;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 10px;
  width: max-content;
  z-index: 999;
  color: #000;
  .lang {
    margin: 0;
    font-size: 0.8rem;
    background: #fff;
    padding: 5px;
    cursor: pointer;
    &:hover {
      background: #eee;
    }
  }
}

i {
  color: #fff;
}

@media screen and (max-width: 576px) {
  .langauges {
    // display: none;
  }

  .ChangeLang {
    // flex-direction: column;
  }
}
</style>

