<template>
  <footer class="footer">
    <div class="footer-content">
      <router-link to="/about">{{ $txt("About us") }}</router-link>|
      <router-link to="/contact">{{ $txt("Contact") }}</router-link>
    </div>
    <div class="footer-copyright">
      © 2024 NO MORE WASTE. All rights reserved.
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.footer {
  background-color: #88B04B;
  color: white;
  padding: 20px 0;
  text-align: center;
  font-size: 20px;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.footer-content span {
  cursor: pointer;
}

.footer-copyright {
  margin-top: 10px;
  font-size: 18px;
  color: #ddd;
}
</style>
