<template>
  <div class="dashboard">
    <div class="dash-content">
      <Topbar :pageTitle="$txt('Languages')" />
      <div class="my-table">
        <div class="table-header flex-btn">
          <form class="table-filters">
            <fieldset>
              <legend>{{ $txt("Search") }}</legend>
              <div class="form-group">
                <input
                  type="text"
                  id="name"
                  class="form-control"
                  :placeholder="$txt('Search')"
                  v-model="searchQuery.search"
                />
              </div>
            </fieldset>
            <!-- Filter -->
            <button
              class="my-btn cw wd glow"
              type="submit"
              @click.prevent="getItems()"
            >
              {{ $txt("Filter") }}
            </button>
          </form>
          <button class="my-btn cw wd glow" @click="startAddLanguage = true">
            {{ $txt("Add Language") }}
          </button>
          <button class="my-btn cw wd glow" @click="showAdd()">
            {{ $txt("Add Text") }}
          </button>
        </div>
        <table v-if="allData.length > 0">
          <thead>
            <tr>
              <th>#</th>
              <th v-for="column in columns" :key="column">
                <div class="dflex">
                  {{ $txt(column) }}
                  <span
                    class="edit"
                    @click="
                      selectedLanguage.language = column;
                      selectedLanguage.newLanguage = column;
                      startEditLanguage = true;
                    "
                  >
                    <i class="fa-solid fa-pencil"></i
                  ></span>
                  <span
                    class="delete"
                    @click="
                      selectedLanguage.language = column;
                      startDeleteLanguage = true;
                    "
                  >
                    <i class="fa-solid fa-trash"></i
                  ></span>
                </div>
              </th>
              <th>{{ $txt("Action")}}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in allData" :key="item.id">
              <td>
                {{ index + 1 }}
              </td>
              <td v-for="column in columns" :key="column.id">
                {{ item[column] }}
              </td>
              <td>
                <a-dropdown>
                  <template #overlay>
                    <a-menu @click="handleMenuClick">
                      <a-menu-item
                        key="2"
                        @click="
                          selectedItem = item;
                          showEdit(item);
                        "
                      >
                        {{$txt("Edit")}}
                      </a-menu-item>
                      <a-menu-item
                        key="3"
                        @click="
                          selectedItem = item;
                          showDelete(item);
                        "
                      >
                        {{ $txt("Delete")}}
                      </a-menu-item>
                    </a-menu>
                  </template>
                  <a-button>
                    {{ $txt("Actions")}}
                    <a-icon type="down" />
                  </a-button>
                </a-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button
            :class="{ disabled: currentPage === 1 }"
            @click="$previousPage()"
          >
            <label> {{ $txt("Previous") }}</label>
          </button>
          <p v-if="currentPage > 3">...</p>
          <button class="numbers" v-for="number in pagesNumber" :key="number">
            <label
              :class="{ active: currentPage === number }"
              for=""
              v-if="
                (number == 1 && currentPage <= 2) ||
                (currentPage + 2 >= pagesNumber && number >= pagesNumber - 2) ||
                (number >= currentPage - 2 && number <= currentPage + 2)
              "
              @click="$changePage(number)"
            >
              {{ number }}
            </label>
          </button>
          <p v-if="currentPage <= pagesNumber - 3">...</p>
          <button
            :class="{ disabled: currentPage === pagesNumber.length }"
            @click="$nextPage()"
          >
            <label> {{ $txt("Next") }}</label>
          </button>
        </div>
      </div>
      <!-- add modal -->
      <Modal
        :title="$txt('Add Text')"
        :size="'lg'"
        v-model:open="startAdd"
        centered
        :okText="$txt('Add')"
        :cancelText="$txt('Cancel')"
        @ok="addLanguageText()"
        width="400px"
      >
        <div class="form-group group-1">
          <div class="input-group" v-for="column in columns" :key="column">
            <label for="name">{{ $txt(column) }}</label>
            <a-input
              type="text"
              id="name"
              v-model:value="newItem.texts[column]"
              class="form-control"
              :placeholder="$txt('column')"
            />
          </div>
        </div>
      </Modal>

      <Modal
        :title="$txt('Edit Text')"
        :size="'lg'"
        v-model:open="startEdit"
        centered
        :okText="$txt('Save')"
        :cancelText="$txt('Cancel')"
        @ok="updateItem()"
        width="400px"
      >
        <div class="form-group group-1">
          <div class="input-group" v-for="column in columns" :key="column">
            <label for="name">{{ $txt(column) }}</label>
            <a-input
              type="text"
              id="name"
              v-model:value="selectedItem.texts[column]"
              class="form-control"
              :placeholder="$txt('column')"
            />
          </div>
        </div>
      </Modal>
      <!-- delete modal -->
      <Modal
        :title="$txt('Delete text')"
        :size="'sm'"
        v-model:open="startDelete"
        centered
        :okText="$txt('Delete')"
        :cancelText="$txt('Cancel')"
        @ok="deleteItem()"
        width="400px"
      >
        <p>{{$txt("Are you sure you want to delete this text?")}}</p>
      </Modal>
      <!-- add language -->
      <Modal
        :title="$txt('Add Language')"
        :size="'sm'"
        v-model:open="startAddLanguage"
        centered
        :okText="$txt('Add')"
        :cancelText="$txt('Cancel')"
        @ok="addLanguage()"
        width="400px"
      >
        <div class="form-group group-1">
          <div class="input-group">
            <label for="name">{{$txt("Language")}}</label>
            <a-input
              type="text"
              id="name"
              v-model:value="newLanguage.language"
              class="form-control"
              :placeholder="$txt('language')"
            />
          </div>
        </div>
      </Modal>
      <!-- edit language -->
      <Modal
        :title="$txt('Edit Language')"
        :size="'sm'"
        v-model:open="startEditLanguage"
        centered
        :okText="$txt('Save')"
        :cancelText="$txt('Cancel')"
        @ok="updateLanguage()"
        width="400px"
      >
        <div class="form-group group-1">
          <div class="input-group">
            <label for="name">{{$txt("Language")}}</label>
            <a-input
              type="text"
              id="name"
              v-model:value="selectedLanguage.newLanguage"
              class="form-control"
              :placeholder="$txt('Language')"
            />
          </div>
        </div>
      </Modal>
      <!-- delete language -->
      <Modal
        :title="$txt('Delete Language')"
        :size="'sm'"
        v-model:open="startDeleteLanguage"
        centered
        :okText="$txt('Delete')"
        :cancelText="$txt('Cancel')"
        @ok="deleteLanguage()"
        width="400px"
      >
        <p>{{$txt("Are you sure you want to delete this language?")}}</p>
      </Modal>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Topbar from "@/views/admin/components/Topbar.vue";
import { Modal } from "ant-design-vue";
export default {
  components: {
    Topbar,
    Modal,
  },
  data() {
    return {
      allItemsCount: 1,
      itemsPerpage: 20,
      pagesNumber: 1,
      currentPage: 1,
      allData: [],
      searchQuery: {
        search: "",
      },
      startAdd: false,
      startAddLanguage: false,
      startEdit: false,
      startEditLanguage: false,
      startDeleteLanguage: false,
      startDelete: false,
      selectedItem: {},
      newItem: {
        name: "",
        email: "",
        phone: "",
        password: "",
      },
      viewItems: false,
      columns: [],
      selectedLanguage: {
        language: "",
        newLanguage: "",
      },
      newLanguage: {
        language: "",
      },
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_LANGUAGE_TEXTS", {
          token: this.$getAdminToken(),
          data: {
            paginate: this.itemsPerpage,
            page: this.currentPage,
            searchQuery: this.searchQuery,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.allData = res.data.languages.data;
              this.allItemsCount = res.data.languages.total;
              this.pagesNumber = Math.ceil(
                this.allItemsCount / this.itemsPerpage
              );
              this.columns = res.data.columns;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })

        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    addLanguage() {
      if (!this.newLanguage.language) {
        toast.error("Please fill all fields.");
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("ADD_LANGUAGE", {
          data: {
            ...this.newLanguage,
          },
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startAddLanguage = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    updateLanguage() {
      this.$startLoader();
      this.$store
        .dispatch("UPDATE_LANGUAGE", {
          data: {
            ...this.selectedLanguage,
          },
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startEditLanguage = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    deleteLanguage() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_LANGUAGE", {
          data: {
            ...this.selectedLanguage,
          },
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startDeleteLanguage = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })

        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },

    addLanguageText() {
      if (!this.newItem.texts) {
        toast.error("Please fill all fields.");
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("ADD_LANGUAGE_TEXT", {
          data: {
            ...this.newItem,
          },
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startAdd = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    updateItem() {
      this.$startLoader();
      this.$store
        .dispatch("UPDATE_LANGUAGE_TEXT", {
          data: {
            ...this.selectedItem,
          },
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startEdit = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_LANGUAGE_TEXT", {
          data: {
            ...this.selectedItem,
          },
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startDelete = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })

        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },

    showAdd() {
      let texts = {};
      this.columns.forEach((column) => {
        texts[column] = "";
      });
      this.newItem.texts = texts;
      this.startAdd = true;
    },
    showEdit(item) {
      let texts = {};
      this.columns.forEach((column) => {
        texts[column] = item[column];
      });
      this.selectedItem = { ...item, texts };
      this.startEdit = true;
    },
    showDelete(item) {
      let texts = {};
      this.columns.forEach((column) => {
        texts[column] = item[column];
      });
      this.selectedItem = { ...item, texts };
      this.startDelete = true;
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  background: #fafafa;
  overflow-y: hidden;
  .page-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .dash-content {
    // padding: 0 20px;
    height: fit-content;
    // overflow-y: auto;
    width: 100%;
  }
}
th {
  .dflex {
    align-items: center;
    column-gap: 5px;
    .edit,
    .delete {
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f0f0f0;
      border-radius: 50%;
      cursor: pointer;
      &.delete {
        background: #c40000;
        color: #fff;
      }
    }
  }
}
</style>