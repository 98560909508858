import axios from "axios";
import { BACKEND } from "../variables";
const GET_CARS_URL = BACKEND + "/cars";
const ADD_CAR_URL = BACKEND + "/cars/add";
const UPDATE_CAR_URL = BACKEND + "/cars/update";
const DELETE_CAR_URL = BACKEND + "/cars/delete";
const cars = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ["GET_CARS"]: (state, payload) => {
      return axios({
        url: GET_CARS_URL + `?page=${payload.data.page}`,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["ADD_CAR"](state, payload) {
      return axios({
        method: "POST",
        url: ADD_CAR_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_CAR"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_CAR_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_CAR"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_CAR_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
  },
  modules: {},
};
export default cars;
