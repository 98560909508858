<template>
  <div class="volunteerDashboard">
    <Navbar />
    <div class="volunteer-dashboard-body">
      <MemberSidebar />
      <div class="volunteer-content">
        <div class="my-table">
          <div class="table-header flex-btn">
            <form class="table-filters">
              <fieldset>
                <legend>{{ $txt("Search") }}</legend>
                <div class="form-group">
                  <input
                    type="text"
                    id="name"
                    class="form-control"
                    :placeholder="$txt('Search')"
                    v-model="searchQuery.search"
                  />
                </div>
              </fieldset>
              <!-- Filter -->
              <div class="form-group">
                <button
                  class="my-btn cw wd glow"
                  type="submit"
                  @click.prevent="getItems()"
                >
                  {{ $txt("Filter") }}
                </button>
              </div>
            </form>
            <button class="my-btn cw wd glow" @click="startAdd = true">
              {{ $txt("Send a Request") }}
            </button>
          </div>
          <table v-if="allData.length > 0">
            <thead>
              <tr>
                <th>#</th>
                <th> {{ $txt("Title")}}</th>
                <th> {{ $txt("Date")}}</th>
                <th> {{ $txt("Request Type")}}</th>
                <th> {{ $txt("Assignee")}}</th>
                <th> {{ $txt("Status")}}</th>
                <th> {{ $txt("Action")}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in allData" :key="item.id">
                <td>
                  {{ index + 1 }}
                </td>
                <td>
                  {{ item.title }}
                </td>
                <td>
                  {{ $frenchFormat(item.date) }}
                  {{ item.time }}
                </td>
                <td>
                  {{ item.request_type }}
                </td>
                <td>
                  {{
                    item.activity && item.activity.volunteer
                      ? item.activity.volunteer.name
                      : "-"
                  }}
                </td>
                <td>
                  <span :class="`chip ${item.status}`">{{
                    item.status == "inProgress" ? "In Progress" : $txt(item.status)
                  }}</span>
                </td>

                <td>
                  <a-dropdown>
                    <template #overlay>
                      <a-menu @click="handleMenuClick">
                        <a-menu-item
                          key="1"
                          @click="
                            selectedItem = item;
                            startEdit = true;

                        "
                          v-if="item.status == 'pending'"
                        >
                          {{ $txt(" Edit")}}
                        </a-menu-item>
                        <a-menu-item
                          key="2"
                          @click="
                            selectedItem = item;
                            startDelete = true;
                          "
                          v-if="item.status !== 'approved'"
                        >
                          {{ $txt("Delete")}}
                        </a-menu-item>
                      </a-menu>
                    </template>
                    <a-button>
                      {{ $txt("Actions")}}
                      <a-icon type="down" />
                    </a-button>
                  </a-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="pagination">
            <button
              :class="{ disabled: currentPage === 1 }"
              @click="$previousPage()"
            >
              <label> {{ $txt("Previous")}}</label>
            </button>
            <p v-if="currentPage > 3">...</p>
            <button class="numbers" v-for="number in pagesNumber" :key="number">
              <label
                :class="{ active: currentPage === number }"
                for=""
                v-if="
                  (number == 1 && currentPage <= 2) ||
                  (currentPage + 2 >= pagesNumber &&
                    number >= pagesNumber - 2) ||
                  (number >= currentPage - 2 && number <= currentPage + 2)
                "
                @click="$changePage(number)"
              >
                {{ number }}
              </label>
            </button>
            <p v-if="currentPage <= pagesNumber - 3">...</p>
            <button
              :class="{ disabled: currentPage === pagesNumber.length }"
              @click="$nextPage()"
            >
              <label> {{ $txt("Next")}}</label>
            </button>
          </div>
        </div>

        <Modal
          :title="$txt('Add Request')"
          :size="'lg'"
          v-model:open="startAdd"
          centered
          :okText="$txt('Save')"
          @ok="createItem()"
          width="600px"
        >
          <div class="form-group group-1">
            <div class="input-group">
              <label for="name">{{ $txt("Title") }}</label>
              <a-input
                type="text"
                id="name"
                v-model:value="newItem.title"
                class="form-control"
                :placeholder="$txt('Tilte')"
              />
            </div>
          </div>
          <div class="form-group group-3">
            <div class="input-group">
              <label for="name">{{ $txt("Date") }}</label>
              <a-input
                type="date"
                id="name"
                v-model:value="newItem.date"
                class="form-control"
                :placeholder="$txt('Date')"
              />
            </div>
            <div class="input-group">
              <label for="name">{{ $txt("Time") }}</label>
              <a-input
                type="time"
                id="name"
                v-model:value="newItem.time"
                class="form-control"
                :placeholder="$txt('Time')"
              />
            </div>
            <div class="input-group">
              <label for="name">{{ $txt("Request Type") }}</label>
             <a-select
                v-model:value="newItem.request_type"
                :placeholder="$txt('Request Type')"
                style="width: 100%"
                :size="'large'"
              >
              <a-select-option v-for="requestType in requestTypes" :key="requestType.name" :value="requestType.name">
              {{
                requestType.name
              }}
              </a-select-option>


                
              </a-select>
            </div>
          </div>
          <div class="form-group group-1">
            <div class="input-group">
              <label for="name">{{ $txt("Description") }}</label>
              <a-textarea
                type="text"
                id="name"
                v-model:value="newItem.description"
                class="form-control"
                :placeholder="$txt('Description')"
              />
            </div>
          </div>
        </Modal>

        <!-- update -->

        <Modal
          :title="$txt('Edit Request')"
          :size="'lg'"
          v-model:open="startEdit"
          centered
          :okText="$txt('Save')"
          @ok="updateItem()"
          width="600px"
        >
          <div class="form-group group-1">
            <div class="input-group">
              <label for="name">{{ $txt("Title") }}</label>
              <a-input
                type="text"
                id="name"
                v-model:value="selectedItem.title"
                class="form-control"
                :placeholder="$txt('Tilte')"
              />
            </div>
          </div>
          <div class="form-group group-3">
            <div class="input-group">
              <label for="name">{{ $txt("Date") }}</label>
              <a-input
                type="date"
                id="name"
                v-model:value="selectedItem.date"
                class="form-control"
                :placeholder="$txt('Date')"
              />
            </div>
            <div class="input-group">
              <label for="name">{{ $txt("Time") }}</label>
              <a-input
                type="time"
                id="name"
                v-model:value="selectedItem.time"
                class="form-control"
                :placeholder="$txt('Time')"
              />
            </div>
            <div class="input-group">
              <label for="name">{{ $txt("Request Type") }}</label>
              <a-select
                v-model:value="selectedItem.request_type"
                :placeholder="$txt('Request Type')"
                style="width: 100%"
                :size="'large'"
              >
              <a-select-option v-for="requestType in requestTypes" :key="requestType" value="requestType.name">
              {{
                requestType.name
              }}
              </a-select-option>  
              </a-select>
            </div>
          </div>
          <div class="form-group group-1">
            <div class="input-group">
              <label for="name">{{ $txt("Description") }}</label>
              <a-textarea
                type="text"
                id="name"
                v-model:value="selectedItem.description"
                class="form-control"
                :placeholder="$txt('Description')"
              />
            </div>
          </div>
        </Modal>
        <Modal
          :title="'Delete Request'"
          :size="'sm'"
          v-model:open="startDelete"
          centered
          :okText="'Yes, delete'"
          @ok="deleteItem()"
          width="600px"
        >
          <p>Are you sure you want to cancel this request?</p>
        </Modal>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import MemberSidebar from "@/views/MemberDashboard/components/MemberSidebar.vue";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { Modal } from "ant-design-vue";
export default {
  name: "AboutView",
  components: {
    Navbar,
    MemberSidebar,
    Modal,
  },
  data() {
    return {
      schedules: [],
      activeSchedule: "Today",
      scheduleDate: new Date().toISOString().split("T")[0],
      allItemsCount: 1,
      itemsPerpage: 10,
      pagesNumber: 1,
      currentPage: 1,
      allData: [],
      searchQuery: {
        search: "",
      },
      volunteers: [],
      selectedItem: {},
      startShowItems: false,
      startRequest: false,
      startAdd: false,
      startEdit: false,
      startDelete: false,
      newItem: {
        title: "",
        date: "",
        time: "",
        request_type: "",
        description: "",
      },
      requestTypes: [],
    };
  },
  methods: {
 getRequestType() {
      this.$startLoader();
      this.$store
        .dispatch("GET_REQUESTTYPES", {
          token: this.$getMemberToken(),
          data: {
            paginate: 1000,
            page: 1,
            
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.requestTypes = res.data;
          
            }   
       
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$memberLogout();
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },

    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_REQUESTS_BY_MEMBER_ID", {
          token: this.$getMemberToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
            searchQuery: this.searchQuery,
            member_id: this.$getMemberId(),
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.allData = res.data.data;
              this.allItemsCount = res.data.total;
              this.pagesNumber = Math.ceil(
                this.allItemsCount / this.itemsPerpage
              );
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$memberLogout();
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    createItem() {
      this.$startLoader();
      this.$store
        .dispatch("ADD_MEMBER_REQUEST", {
          data: {
            title: this.newItem.title,
            date: this.newItem.date,
            time: this.newItem.time,
            request_type: this.newItem.request_type,
            description: this.newItem.description,
            member_id: this.$getMemberId(),
          },
          token: this.$getMemberToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startAdd = false;
            } else {
              toast.error(res.data.message);
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$memberLogout();
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    updateItem() {
      this.$startLoader();
      this.$store
        .dispatch("UPDATE_MEMBER_REQUEST", {
          data: {
            ...this.selectedItem,
          },
          token: this.$getMemberToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startEdit = false;
            } else {
              toast.error(res.data.message);
            }
          }
          this.$stopLoader();
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$memberLogout();
          } else {
            toast.error("Something went wrong, please try again later.");
          }
        });
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_MEMBER_REQUEST", {
          data: {
            id: this.selectedItem.id,
          },
          token: this.$getMemberToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(res.data.message);
              this.getItems();
              this.startDelete = false;
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    printList() {
      const printContents = document.getElementById("report-section").innerHTML;
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },
    filterVolunteer() {
      return this.volunteers.filter((volunteer) => {
        return volunteer.id === this.$getMemberId();
      });
    },
  },
  mounted() {

this.getRequestType();
  this.getItems();
  },
};
</script>

<style scoped lang="scss">
.schedule {
  padding: 2rem;
}
</style>
