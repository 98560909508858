<template>
  <div class="dashboard">
    <div class="dash-content">
      <Topbar :pageTitle="$txt('Activities')" />
      <div class="my-table">
        <div class="table-header flex-btn">
          <form class="table-filters">
            <fieldset>
              <legend>{{ $txt("Volunteer") }}</legend>
              <div class="form-group">
                <select
                  id="wing"
                  class="form-control"
                  v-model="searchQuery.volunteer_id"
                >
                  <option value="">{{ $txt("All")}}</option>
                  <option
                    v-for="volunteer in volunteers"
                    :key="volunteer.id"
                    :value="volunteer.id"
                  >
                    {{ volunteer.name }}
                  </option>
                </select>
              </div>
            </fieldset>
            <fieldset>
              <legend>{{ $txt("Search") }}</legend>
              <div class="form-group">
                <input
                  type="text"
                  id="name"
                  class="form-control"
                  :placeholder="$txt('Search')"
                  v-model="searchQuery.search"
                />
              </div>
            </fieldset>
            <!-- Filter -->
            <button
              class="my-btn cw wd glow"
              type="submit"
              @click.prevent="getItems()"
            >
              {{ $txt("Filter") }}
            </button>
          </form>
          <button class="my-btn cw wd glow" @click="startAdd = true">
            {{ $txt("Add Activity") }}
          </button>
        </div>
        <table v-if="allData.length > 0">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ $txt("Title") }}</th>
              <th>{{ $txt("Volunteer") }}</th>
              <th>{{ $txt("Time") }}</th>
              <th>{{ $txt("Items") }}</th>
              <th>{{ $txt("Status") }}</th>
              <th>{{ $txt("Action") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in allData" :key="item.id">
              <td>
                {{ index + 1 }}
              </td>
              <td>
                <p class="ellipsis">{{ item.description }}</p>
              </td>
              <td>
                <div class="table-list" v-if="item.volunteer">
                  <div class="table-list-item">
                    <b>{{ $txt("Name:") }} </b>
                    <span>{{ item.volunteer.name }}</span>
                  </div>
                  <div class="table-list-item">
                    <b>{{ $txt("Email:") }} </b>
                    <span>{{ item.volunteer.email }}</span>
                  </div>
                  <div class="table-list-item">
                    <b>{{ $txt("Phone:") }} </b>
                    <span>{{ item.volunteer.phone }}</span>
                  </div>
                </div>
                <span v-else>{{ $txt("Not assigned")}}</span>
              </td>

              <td>
                <div class="table-list">
                  <div class="table-list-item">
                    <b>{{ $txt("Date:") }} </b>
                    <span>{{ $getlang()=="french" ? $frenchFormat(item.execution_date) : item.execution_date }}</span>
                  </div>
                  <div class="table-list-item">
                    <b> {{ $txt("Time:") }} </b>
                    <span>{{ item.execution_time }}</span>
                  </div>
                </div>
              </td>
              <td>{{ item.items.length }}</td>
              <td>
                <span :class="`chip ${item.status}`">{{
                  item.status == "inProgress" ? "In Progress" : $txt(item.status)
                }}</span>
              </td>

              <td>
                <a-dropdown>
                  <template #overlay>
                    <a-menu @click="handleMenuClick">
                      <a-menu-item
                        key="1"
                        @click="
                          selectedItem = item;
                          startEdit = true;
                        "
                      >

                        {{ $txt("Edit") }}
                      </a-menu-item>
                      <a-menu-item
                        key="2"
                        @click="
                          selectedItem = item;
                          startDelete = true;
                        "
                      >
                        {{ $txt("Delete") }}
                      </a-menu-item>
                      <a-menu-item
                        key="3"
                        @click="
                          selectedItem = item;
                          startShowItems = true;
                        "
                      >
                        {{ $txt("Items list") }}
                      </a-menu-item>
                    </a-menu>
                  </template>
                  <a-button>
                    {{ $txt("Actions") }}
                    <a-icon type="down" />
                  </a-button>
                </a-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button
            :class="{ disabled: currentPage === 1 }"
            @click="$previousPage()"
          >
            <label>{{ $txt("Previous") }}</label>
          </button>
          <p v-if="currentPage > 3">...</p>
          <button class="numbers" v-for="number in pagesNumber" :key="number">
            <label
              :class="{ active: currentPage === number }"
              for=""
              v-if="
                (number == 1 && currentPage <= 2) ||
                (currentPage + 2 >= pagesNumber && number >= pagesNumber - 2) ||
                (number >= currentPage - 2 && number <= currentPage + 2)
              "
              @click="$changePage(number)"
            >
              {{ number }}
            </label>
          </button>
          <p v-if="currentPage <= pagesNumber - 3">...</p>
          <button
            :class="{ disabled: currentPage === pagesNumber.length }"
            @click="$nextPage()"
          >
            <label>{{ $txt("Next") }}</label>
          </button>
        </div>
      </div>

      <Modal
        :title="$txt('Add Activity')"
        :size="'lg'"
        v-model:open="startAdd"
        centered
        :okText="$txt('Save')"
        :cancelText="$txt('Cancel')"
        @ok="createItem()"
        width="600px"
      >
        <div class="form-group group-1">
          <div class="input-group">
            <label for="name">{{ $txt("Description") }}</label>
            <a-textarea
              type="description"
              id="name"
              v-model:value="newItem.description"
              class="form-control"
              :placeholder="$txt('Description')"
            />
          </div>
          <div class="input-group">
            <label for="name">{{ $txt("Member Request") }}</label>
            <a-select
              v-model:value="newItem.request_id"
              style="width: 100%"
              :placeholder="$txt('Select Member Request')"
            >
              <a-select-option :value="null">{{
                $txt("Select associated Member Request")
              }}</a-select-option>
              <a-select-option
                v-for="memberRequest in memberRequests"
                :key="memberRequest.id"
                :value="memberRequest.id"
                >{{ memberRequest.title }}</a-select-option
              >
            </a-select>
          </div>
        </div>
        <div class="form-group group-2">
          <div class="input-group">
            <label for="name">{{ $txt("Execution Date") }}</label>
            <a-input
              type="date"
              id="name"
              v-model:value="newItem.execution_date"
              class="form-control"
              :placeholder="$txt('Execution Date')"
              @change="getAvaialableVolunteers()"
            />
          </div>
          <div class="input-group">
            <label for="name">{{ $txt("Execution Time") }}</label>
            <a-input
              type="time"
              id="name"
              v-model:value="newItem.execution_time"
              class="form-control"
              :placeholder="$txt('Execution Time')"
              @change="getAvaialableVolunteers()"
            />
          </div>
        </div>
        <div class="form-group group-2">
          <div class="input-group">
            <label for="name">{{ $txt("Volunteer") }}</label>
            <a-select
              v-model:value="newItem.volunteer_id"
              style="width: 100%"
              :placeholder="$txt('Select Volunteer')"
            >
              <a-select-option :value="null">{{
                $txt("Select Volunteer")
              }}</a-select-option>
              <a-select-option
                v-for="volunteer in volunteers"
                :key="volunteer.id"
                :value="volunteer.id"
                >{{ volunteer.name }}</a-select-option
              >
            </a-select>
          </div>
          <div class="input-group">
            <label for="name">{{ $txt("Status") }}</label>
            <a-select
              v-model:value="newItem.status"
              style="width: 100%"
              :placeholder="$txt('Select Status')"
            >
              <a-select-option value="pending">{{
                $txt("pending")
              }}</a-select-option>
              <a-select-option value="assigned">{{
                $txt("assigned")
              }}</a-select-option>
              <a-select-option value="inProgress">{{
                $txt("in Progress")
              }}</a-select-option>
              <a-select-option value="completed">{{
                $txt("completed")
              }}</a-select-option>
            </a-select>
          </div>
        </div>
        <div class="form-group group-1">
          <div class="input-group">
            <label for="name">{{ $txt("Items") }}</label>
            <NWMultiValue
              :values="newItem.items"
              @valueChange="newItem.items = $event"
            />
          </div>
        </div>
      </Modal>

      <Modal
        :title="$txt('Edit Activity')"
        :size="'lg'"
        v-model:open="startEdit"
        centered
        :okText="$txt('Save')"
        :cancelText="$txt('Cancel')"
        @ok="updateItem()"
        width="600px"
      >
        <div class="form-group group-1">
          <div class="input-group">
            <label for="name">{{$txt('Description')}}</label>
            <a-textarea
              type="description"
              id="name"
              v-model:value="selectedItem.description"
              class="form-control"
              :placeholder="$txt('Description')"
            />
          </div>
          <div class="input-group">
            <label for="name">{{$txt('Member Request')}}</label>
            <a-select
              v-model:value="selectedItem.request_id"
              style="width: 100%"
              :placeholder="$txt('Select Member Request')"
            >
              <a-select-option :value="null"
                >{{$txt('Select associated Member Request')}}</a-select-option
              >
              <a-select-option
                v-for="memberRequest in memberRequests"
                :key="memberRequest.id"
                :value="memberRequest.id"
                >{{ memberRequest.title }}</a-select-option
              >
            </a-select>
          </div>
        </div>
        <div class="form-group group-2">
          <div class="input-group">
            <label for="name">{{$txt('Execution Date')}}</label>
            <a-input
              type="date"
              id="name"
              v-model:value="selectedItem.execution_date"
              class="form-control"
              :placeholder="$txt('Execution Date')"
              @change="getAvaialableVolunteers()"
            />
          </div>
          <div class="input-group">
            <label for="name">{{$txt('Execution Time')}}</label>
            <a-input
              type="time"
              id="name"
              v-model:value="selectedItem.execution_time"
              class="form-control"
              :placeholder="$txt('Execution Time')"
              @change="getAvaialableVolunteers()"
            />
          </div>
        </div>
        <div class="form-group group-2">
          <div class="input-group">
            <label for="name">{{$txt('Volunteer')}}</label>
            <a-select
              v-model:value="selectedItem.volunteer_id"
              style="width: 100%"
              :placeholder="$txt('Select Volunteer')"
            >
              <a-select-option :value="null">{{$txt('Select Volunteer')}}</a-select-option>
              <a-select-option
                v-for="volunteer in volunteers"
                :key="volunteer.id"
                :value="volunteer.id"
                >{{ volunteer.name }}</a-select-option
              >
            </a-select>
          </div>
          <div class="input-group">
            <label for="name">{{$txt('Status')}}</label>
            <a-select
              v-model:value="selectedItem.status"
              style="width: 100%"
              :placeholder="$txt('Select Status')"
            >
              <a-select-option value="pending">{{$txt('pending')}}</a-select-option>
              <a-select-option value="assigned">{{$txt('assigned')}}</a-select-option>
              <a-select-option value="inProgress">{{$txt('in Progress')}}</a-select-option>
              <a-select-option value="completed">{{$txt('completed')}}</a-select-option>
            </a-select>
          </div>
        </div>
        <div class="form-group group-1">
          <div class="input-group">
            <label for="name">{{$txt('Items')}}</label>
            <NWMultiValue
              :values="selectedItem.items"
              @valueChange="selectedItem.items = $event"
            />
          </div>
        </div>
      </Modal>
      <Modal
        :title="$txt('Delete Activity')"
        :size="'sm'"
        v-model:open="startDelete"
        centered
        :okText="$txt('Delete')"
        :cancelText="$txt('Cancel')"
        @ok="deleteItem()"
        width="600px"
      >
        <p>{{$txt('Are you sure you want to delete this activity?')}}</p>
      </Modal>

      <Modal
        :title="$txt('Mission Items')"
        :size="'sm'"
        v-model:open="startShowItems"
        centered
        :okText="$txt('Delete')"
        :cancelText="$txt('Cancel')"
        @ok="deleteItem()"
        width="600px"
        :footer="null"
      >
        <div id="report-section">
          <div class="items-item">
            <h3>
              {{$txt('Items to be used in a Mission on')}}
              {{ selectedItem.execution_date }}
              {{ $ampm(selectedItem.execution_time) }}
            </h3>
            <div class="assigned">
              <b>{{$txt('Assigned to:')}}</b>
              <span v-if=" selectedItem.volunteer">&nbsp;{{ selectedItem.volunteer.name }}</span>
            </div>
            <p style="margin-top: 10px">{{ selectedItem.description }}</p>
            <table border="1" style="border-collapse: collapse; width: 100%" v-if="selectedItem.items.length>0">
              <thead>
                <tr>
                  <th style="padding: 10px">{{$txt('Item')}}</th>
                  <th style="padding: 10px">{{$txt('Quantity')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in selectedItem.items" :key="index">
                  <td style="padding: 10px">{{ item.item }}</td>
                  <td style="padding: 10px">{{ item.quantity }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="pritn-btn mt-10">
          <button class="my-btn cw glow" @click="printList()">
            <i class="fa fa-print"></i>
            {{$txt('Print')}}
          </button>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Topbar from "@/views/admin/components/Topbar.vue";
import { Modal } from "ant-design-vue";
export default {
  components: {
    Topbar,
    Modal,
  },
  data() {
    return {
      allItemsCount: 1,
      itemsPerpage: 10,
      pagesNumber: 1,
      currentPage: 1,
      allData: [],
      searchQuery: {
        search: "",
        merchant_id: "",
        volunteer_id: "",
      },
      startAdd: false,
      startEdit: false,
      startDelete: false,
      startAssign: false,
      startShowItems: false,
      selectedItem: {},
      newItem: {
        volunteer_id: null,
        description: "",
        execution_date: "",
        execution_time: "",
        status: "pending",
        request_id: null,
        items: [
        ],
      },
      volunteers: [],
      merchants: [],
      viewItems: false,
      availableVolunteers: [],
      memberRequests: [],
    };
  },
  methods: {
    getMemberRequests() {
      this.$store
        .dispatch("GET_ALL_MEMBERS_REQUESTS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 1000,
            page: 1,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.memberRequests = res.data.data;
            }
          }
        })

        .catch((err) => {});
    },
    getAvaialableVolunteers() {
      this.$store
        .dispatch("GET_AVAILABLE_VOLUNTEERS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 1000,
            page: 1,
            searchQuery: this.searchQuery,
            date: this.startAdd
              ? this.newItem.execution_date
              : this.selectedItem.execution_date,
            time: this.startAdd
              ? this.newItem.execution_time
              : this.selectedItem.execution_time,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.availableVolunteers = res.data.data;
            } else {
              toast.error(this.$txt(res.data.message));
            }
          }
        })

        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    getVolunteers() {
      this.$store
        .dispatch("GET_VOLUNTEERS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 1000,
            page: 1,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.volunteers = res.data.data;
            } else {
              toast.error(res.data.message);
            }
          }
        })

        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    getMerchants() {
      this.$store
        .dispatch("GET_MERCHANTS", {
          token: this.$getAdminToken(),
          data: {
            paginate: 1000,
            page: 1,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.merchants = res.data.data;
            } else {
              toast.error(res.data.message);
            }
          }
        })

        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ACTIVITIES", {
          token: this.$getAdminToken(),
          data: {
            paginate: 10,
            page: this.currentPage,
            searchQuery: this.searchQuery,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.allData = res.data.data;
              this.allItemsCount = res.data.total;
              this.pagesNumber = Math.ceil(
                this.allItemsCount / this.itemsPerpage
              );
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })

        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    createItem() {
      if (
        this.newItem.description &&
        this.newItem.execution_date &&
        this.newItem.execution_time
      ) {
        this.$startLoader();
        this.$store
          .dispatch("ADD_ACTIVITY", {
            data: {
              ...this.newItem,
            },
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(this.$txt(res.data.message));
                this.getItems();
                this.startAdd = false;
                this.$stopLoader();
              } else {
                toast.error(this.$txt(res.data.message));
                this.$stopLoader();
              }
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              this.$router.push({ name: "login" });
            } else {
              toast.error("Something went wrong, please try again later.");
            }
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    updateItem() {
      if (
        this.selectedItem.description &&
        this.selectedItem.execution_date &&
        this.selectedItem.execution_time
      ) {
        this.$startLoader();
        this.$store
          .dispatch("UPDATE_ACTIVITY", {
            data: {
              ...this.selectedItem,
            },
            token: this.$getAdminToken(),
          })
          .then((res) => {
            if (res) {
              if (res.status === 200) {
                toast.success(this.$txt(res.data.message));
                this.getItems();
                this.startEdit = false;
                this.$stopLoader();
              } else {
                toast.error(this.$txt(res.data.message));
                this.$stopLoader();
              }
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              this.$router.push({ name: "login" });
            } else {
              toast.error("Something went wrong, please try again later.");
            }
            this.$stopLoader();
          });
      } else {
        toast.error("Please fill all fields");
      }
    },
    deleteItem() {
      this.$startLoader();
      this.$store
        .dispatch("DELETE_ACTIVITY", {
          data: {
            ...this.selectedItem,
          },
          token: this.$getAdminToken(),
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              toast.success(this.$txt(res.data.message));
              this.getItems();
              this.startDelete = false;
              this.$stopLoader();
            } else {
              toast.error(this.$txt(res.data.message));
              this.$stopLoader();
            }
          }
        })

        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    printList() {
      const printContents = document.getElementById("report-section").innerHTML;
      const originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      window.location.reload();
    },
  },
  mounted() {
    this.getMemberRequests();
    this.getVolunteers();
    this.getMerchants();
    this.getItems();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  background: #fafafa;
  overflow-y: hidden;
  .page-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .dash-content {
    // padding: 0 20px;
    height: fit-content;
    // overflow-y: auto;
    width: 100%;
  }
}
</style>