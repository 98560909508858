<template>
  <header class="header">
    <div class="header-content">
      <div class="logo">
        <img src="/images/nmwlogo.png" alt="NoMoreWaste" />
      </div>
      <div class="navbar-links">
        <Navbar />
      </div>
    </div>
  </header>
</template>

<script>
import Navbar from "./Navbar.vue";

export default {
  components: {
    Navbar,
  },
};
</script>

<style scoped>
.header {
  background-color: #88B04B;
  width: 100%;
  padding: 20px 0;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px; /* Ajuste la largeur maximale */
  margin: 0 auto;
  padding: 0 20px; /*  ajuster ce padding si nécessaire */
}

.logo {
  margin-left: -100px; /* Déplace le logo plus vers la gauche */
}

.logo img {
  height: 85px;
}

.navbar-links {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
</style>
