<template>
  <div class="dashboard">
    <div class="dash-content">
      <Topbar :pageTitle="$txt('Dashboard')" />
      <div class="dash-items">
        <div class="dash-item" v-for="item in dashItems" :key="item.name">
          <div class="dash-item-top">
            <div class="dash-item-top-left">
              <div class="dash-item-icon">
                <i :class="item.icon"></i>
              </div>
            </div>
            <div class="dash-item-top-right">
              <div class="dash-item-info">
                <p>{{ item.value }}</p>
              </div>
            </div>
          </div>
          <div class="dash-item-bottom">
            <div class="dash-item-bottom-left">
              <h3>{{ $txt(item.name)}}</h3>
            </div>
            <div class="dash-item-bottom-right">
              <router-link :to="{ name: item.link }">
                {{ $txt("View more")}}
                <i class="fas fa-arrow-right"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="schedule">
        <hr />
        <h2>{{ $txt("Schedule") }}</h2>

        <Schedule
          :schedules="schedules"

          @TodayClicked="
            activeSchedule = 'Today';
            getSchedules();
          "
          @TomorrowClicked="
            activeSchedule = 'Tomorrow';
            getSchedules();
          "
          @ThisWeekClicked="
            activeSchedule = 'This Week';
            getSchedules();
          "
          @ThisMonthClicked="
            activeSchedule = 'This Month';
            getSchedules();
          "
          @customDateClicked="
            scheduleDate = $event;
            activeSchedule = 'Custom Date';
            getSchedules();
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Topbar from "@/views/admin/components/Topbar.vue";
export default {
  components: {
    Topbar,
  },
  data() {
    return {
      dashItems: [],
      schedules: [],
    };
  },
  methods: {
    getDashes() {
      this.$startLoader();
      this.$store
        .dispatch("GET_DASHES", {
          token: this.$getAdminToken(),
          data: {
            date: this.scheduleDate,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.dashItems = res.data;
              this.getSchedules();
            }
          }
          this.$stopLoader();
        })

        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
    getSchedules() {
      this.$startLoader();
      this.$store
        .dispatch("GET_ALL_SCHEDULE", {
          token: this.$getAdminToken(),
          data: {
            date: this.scheduleDate,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.schedules = res.data;
            } else {
              toast.error(res.data.message);
            }
          }
          this.$stopLoader();
        })

        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Session expired, please login again.");
            this.$router.push({ name: "login" });
          } else {
            toast.error("Something went wrong, please try again later.");
          }
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.getDashes();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.dashboard {
  background: #fafafa;
  overflow-y: hidden;
  .dash-content {
    padding: 20px;
    .dash-items {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-gap: 20px;
      margin-top: 20px;
      .dash-item {
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 0 10px 0 #00000009;
        position: relative;
        overflow: hidden;
        .dash-item-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px 20px;
          border-bottom: 1px solid #00000009;
          min-height: 80px;
          .dash-item-top-left {
            .dash-item-icon {
              position: absolute;
              background: #179E3B;
              width: 90px;
              height: 70px;
              border-radius: 50%;
              top: -1rem;
              left: -1rem;
              display: flex;
              justify-content: center;
              align-items: center;
              i {
                font-size: 2rem;
                color: #fff;
                top: 5px;
                left: 10px;
                padding-top: 10px;
                padding-left: 10px;
              }
            }
          }
          .dash-item-top-right {
            .dash-item-info {
              p {
                position: absolute;
                right: 20px;
                top: 35%;
                transform: translateY(-50%);
                font-size: 2rem;
                color: #306647;
                font-weight: 700;
                text-align: right;
              }
            }
          }
        }
        .dash-item-bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px 20px;
          .dash-item-bottom-left {
            h3 {
              font-size: 12px;
              color: #333;
              font-weight: 600;
            }
          }
          .dash-item-bottom-right {
            display: flex;
            align-items: center;
            a {
              font-size: 12px;
              color: $primary_color;
              i {
                font-size: 12px;
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
    .schedule {
      hr {
        margin: 20px 0;
        border: 0;
        border-top: 1px solid #00000009;
      }
    }
  }
}
</style>