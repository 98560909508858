<template>
  <div class="member-sidebar">
    <div class="links">
      <router-link :to="{ name: 'MemberRequests' }">{{ $txt("Requests")}}</router-link>
      <router-link :to="{ name: 'MemberAccount' }">{{ $txt("Account")}}</router-link>
      <router-link
        to="/member-dashboard/requests"
        v-if="$loggedMember()"
        @click.prevent="$memberLogout()"
        >{{ $txt("Logout")}}</router-link
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/assets/main.scss";
.member-sidebar {
  width: 250px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-right: 1px solid #ccc;
  height: calc(100vh - 100px);
  .links {
    display: flex;
    flex-direction: column;
    a {
      color: #666;
      text-decoration: none;
      width: 100%;
      padding: 12px 10px;
      border-radius: 5px;
      text-align: left;
      font-size: 0.9em;
      &:hover {
        background-color: #0066ff48;
        color: #000;
      }
      &.router-link-exact-active {
        background-color: #0066ff2c;
        color: #000;
      }
    }
  }
}
</style>