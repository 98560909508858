<template>
  <div class="register">
    <Navbar />
    <section class="intro">
      <h1>{{ $txt("Inscription") }}</h1>
      <h2>{{ $txt("Créez votre compte") }}</h2>
      <br />
      <br />
      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
      <p v-if="successMessage" class="success">{{ successMessage }}</p>
    </section>
    <section class="content">
      <section class="register-form">
        <h3>{{ $txt("S'inscrire") }}</h3>
        <form @submit.prevent="submitForm">
          <div>
            <label for="name">{{ $txt("Nom") }} :</label>
            <input type="text" id="name" v-model="form.name" required />
          </div>
          <div>
            <label for="email">{{ $txt("Email :") }}</label>
            <input type="email" id="email" v-model="form.email" required />
          </div>
          <div>
            <label for="password">{{ $txt("Mot de passe") }}</label>
            <input
              type="password"
              id="password"
              v-model="form.password"
              required
            />
          </div>
          <div>
            <label for="phone">{{ $txt("Téléphone :") }}</label>
            <input type="text" id="phone" v-model="form.phone" required />
          </div>

          <div>
            <label for="address">{{ $txt("Adresse") }} :</label>
            <input type="text" id="address" v-model="form.address" required />
          </div>
          <div>
            <label for="city">{{ $txt("Ville") }} :</label>
            <input type="text" id="city" v-model="form.city" required />
          </div>
          <div>
            <label for="postal_code">{{ $txt("Code Postal") }} :</label>
            <input
              type="text"
              id="postal_code"
              v-model="form.postal_code"
              required
            />
          </div>

          <div>
            <label>{{ $txt("Compétences") }} :</label>
            <div v-for="(skill, index) in form.skills" :key="index">
              <input
                type="checkbox"
                :id="skill"
                :value="skill"
                v-model="form.selectedSkills"
              />
              <label :for="skill">{{$txt(skill) }}</label>


            </div>
            <input
              type="text"
              v-model="newSkill"
              placeholder="Ajouter une nouvelle compétence"
            />
            <br />
            <button type="button" @click="addNewSkill">
              {{ $txt("Ajouter") }}
            </button>
          </div>
          <div>
            <label>{{ $txt("Disponibilités") }} :</label>
            <div v-for="(day, index) in days" :key="index">
              <label>
                <input
                  type="checkbox"
                  v-model="form.availabilities[day].selected"
                />
                {{ $txt(day) }}
              </label>
              <div v-if="form.availabilities[day].selected">
                <label>{{ $txt("Heure de début :") }}</label>
                <input type="time" v-model="form.availabilities[day].start" />
                <label>{{ $txt("Heure de fin :") }}</label>
                <input type="time" v-model="form.availabilities[day].end" />
              </div>
            </div>
          </div>
          <button type="submit">{{ $txt("S'inscrire") }}</button>
        </form>
      </section>
    </section>
  </div>
</template>

              <script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "RegisterView",
  components: {
    Navbar,
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        password: "",
        phone: "",
        city: "",
        postal_code: "",
        address: "",
        skills: ["Carpenter", "Driver", "Cook", "Plumber"],
        selectedSkills: [],
        availabilities: {
          Monday: { selected: false, start: "", end: "" },
          Tuesday: { selected: false, start: "", end: "" },
          Wednesday: { selected: false, start: "", end: "" },
          Thursday: { selected: false, start: "", end: "" },
          Friday: { selected: false, start: "", end: "" },
          Saturday: { selected: false, start: "", end: "" },
          Sunday: { selected: false, start: "", end: "" },
        },
      },
      errorMessage: "",
      successMessage: "",
      newSkill: "",
      days: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
    };
  },
  methods: {
    async submitForm() {
      if (this.form.selectedSkills.length === 0) {
        this.errorMessage = "Veuillez sélectionner au moins une compétence.";
        return;
      }

      if (this.newSkill.trim() !== "") {
        this.form.skills.push(this.newSkill.trim());
        this.form.selectedSkills.push(this.newSkill.trim());
        this.newSkill = "";
      }

      for (let day of this.days) {
        if (this.form.availabilities[day].selected) {
          const start = this.form.availabilities[day].start;
          const end = this.form.availabilities[day].end;
          if (start >= end) {
            this.errorMessage = `L'heure de début doit être antérieure à l'heure de fin pour ${day}.`;
            return;
          }
        }
      }

      const availability = [];
      for (let day of this.days) {
        if (this.form.availabilities[day].selected) {
          availability.push({
            day,
            start: this.form.availabilities[day].start,
            end: this.form.availabilities[day].end,
          });
        }
      }

      const formData = new FormData();
      formData.append("name", this.form.name);
      formData.append("email", this.form.email);
      formData.append("password", this.form.password);
      formData.append("phone", this.form.phone);
      formData.append("city", this.form.city);
      formData.append("postal_code", this.form.postal_code);
      formData.append("address", this.form.address);
      formData.append("skills", JSON.stringify(this.form.selectedSkills));
      formData.append("availability", JSON.stringify(availability));

      const apiUrl = "http://127.0.0.1:8000/api/v1/volunteers/register";
      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          body: formData,
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message || "Erreur lors de l'inscription");
        }

        console.log("Inscription réussie :", data);
        this.successMessage = "Inscription réussie !";
        this.resetForm();
      } catch (error) {
        console.error("Erreur lors de l'inscription :", error);
        this.errorMessage =
          error.message || "Erreur lors de l'inscription. Veuillez réessayer.";
        window.scrollTo(0, 0);
      }
    },
    addNewSkill() {
      if (
        this.newSkill.trim() !== "" &&
        !this.form.skills.includes(this.newSkill.trim())
      ) {
        this.form.skills.push(this.newSkill.trim());
        this.form.selectedSkills.push(this.newSkill.trim());
        this.newSkill = "";
      }
    },
    resetForm() {
      this.form = {
        name: "",
        email: "",
        password: "",
        phone: "",
        city: "",
        postal_code: "",
        address: "",
        skills: ["Carpenter", "Driver", "Cook", "Plumber"],
        selectedSkills: [],
        availabilities: {
          Monday: { selected: false, start: "", end: "" },
          Tuesday: { selected: false, start: "", end: "" },
          Wednesday: { selected: false, start: "", end: "" },
          Thursday: { selected: false, start: "", end: "" },
          Friday: { selected: false, start: "", end: "" },
          Saturday: { selected: false, start: "", end: "" },
          Sunday: { selected: false, start: "", end: "" },
        },
      };
      this.errorMessage = "";
      this.newSkill = "";
    },
  },
};
</script>

                      <style scoped>
.register {
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.intro {
  margin-bottom: 40px;
}
.intro h1 {
  color: #3C8A66;
  font-size: 2.5em;
  margin-bottom: 10px;
}
.intro h2 {
  color: #2c3e50;
  font-size: 1.5em;
  margin-bottom: 20px;
}
.content {
  width: 100%;
  max-width: 400px;
  text-align: left;
  margin-top: 20px;
}
section {
  margin-bottom: 40px;
}
h3 {
  color: #3C8A66;
  font-size: 1.8em;
  margin-bottom: 15px;
}
form div {
  margin-bottom: 15px;
}
label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}
input[type="text"],
input[type="email"],
input[type="password"],
input[type="time"] {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
}
input[type="checkbox"] {
  margin-right: 5px;
}
button {
  background-color: #3C8A66;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
}
button:hover {
  background-color: #c0392b;
}
.error {
  color: red;
  margin-top: 20px;
}
.success {
  color: green;
  margin-top: 20px;
}
</style>
