import axios from "axios";
import { BACKEND } from "../variables";
const GET_VOLUNTEERS_URL = BACKEND + "/volunteers";
const GET_AVAILABLE_VOLUNTEERS_URL =
  BACKEND + "/volunteers/get-available-volunteers";
const ADD_VOLUNTEER_URL = BACKEND + "/volunteers/add";
const UPDATE_VOLUNTEER_URL = BACKEND + "/volunteers/update";
const DELETE_VOLUNTEER_URL = BACKEND + "/volunteers/delete";
const GET_MY_ACCOUNT_URL = BACKEND + "/volunteers/getMyAccount";
const CHANGE_MY_PASSWORD_URL = BACKEND + "/volunteers/changePassword";
const UPDATE_MY_ACCOUNT_URL = BACKEND + "/volunteers/updateMyAccount";
const volunteers = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ["GET_VOLUNTEERS"]: (state, payload) => {
      return axios({
        url: GET_VOLUNTEERS_URL + `?page=${payload.data.page}`,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["GET_AVAILABLE_VOLUNTEERS"]: (state, payload) => {
      return axios({
        url: GET_AVAILABLE_VOLUNTEERS_URL,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["ADD_VOLUNTEER"](state, payload) {
      return axios({
        method: "POST",
        url: ADD_VOLUNTEER_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["UPDATE_VOLUNTEER"](state, payload) {
      return axios({
        method: "POST",
        url: UPDATE_VOLUNTEER_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["DELETE_VOLUNTEER"](state, payload) {
      return axios({
        method: "POST",
        url: DELETE_VOLUNTEER_URL,
        data: payload.data,
        headers: {
          Authorization: payload.token,
        },
      });
    },
    ["GET_MY_ACCOUNT"](state, payload) {
      return axios({
        url: GET_MY_ACCOUNT_URL,
        method: "POST",
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["CHANGE_MY_PASSWORD"](state, payload) {
      return axios({
        url: CHANGE_MY_PASSWORD_URL,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
    ["UPDATE_MY_ACCOUNT"](state, payload) {
      return axios({
        url: UPDATE_MY_ACCOUNT_URL,
        method: "POST",
        data: payload.data,
        headers: {
          Authorization: `${payload.token}`,
        },
      });
    },
  },
  modules: {},
};
export default volunteers;
